import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	tempEmail: "",
	share: {
		show: false,
		endPoint: "",
		id: "",
	},
	postReload: false,
	pollObj: {
		enablePoll: false,
		pollKeys: [],
		enableCustomPoll: false,
		customPollPopupShow: false,
	},
};

const tempSlice = createSlice({
	name: "tempSlice",
	initialState,
	reducers: {
		setTempEmail: (state, { payload }) => {
			state.tempEmail = payload;
		},

		setShareData: (state, { payload }) => {
			state.share = payload;
		},

		setPostReload: (state, { payload }) => {
			state.postReload = payload;
		},

		setPollEnable: (state, { payload }) => {
			state.pollObj.enablePoll = payload;
		},

		setCustomPollShow: (state, { payload: { enableCustomPoll, customPollPopupShow } }) => {
			state.pollObj.enableCustomPoll = enableCustomPoll;
			state.pollObj.customPollPopupShow = customPollPopupShow;
		},

		setCustomPollKey: (state, { payload }) => {
			state.pollObj.pollKeys = payload;
		},
	},
});

export const { setTempEmail, setShareData, setPostReload, setPollEnable, setCustomPollShow, setCustomPollKey } =
	tempSlice.actions;
export default tempSlice.reducer;
