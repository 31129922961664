import { createSlice } from "@reduxjs/toolkit";
import sound from "../../assets/sound/mixkit-clear-announce-tones-2861.wav";
const audio = new Audio(sound);
audio.muted = true;

const initialState = {
	notifications: [],
	notificationsCount: 0,
	reload: 0,
	notificationSound: false,
};

const notificationSlice = createSlice({
	name: "notificationSlice",
	initialState,
	reducers: {
		fetchNotificationHistory: (state, { payload }) => {
			state.notifications = [...payload];
			state.notifications.forEach((item) => {
				if (item.isSeen === false) state.notificationsCount += 1;
			});
		},

		fetchNotifications: (state, { payload }) => {
			const array = [...state.notifications];
			array.unshift(payload);
			state.notifications = array;
			state.notificationsCount = state.notificationsCount + 1;

			if (state.notificationSound) {
				audio.muted = false;
				audio.play();
			}
		},

		setReload: (state) => {
			state.reload = Math.random();
			state.notificationsCount = 0;
		},

		setNotificationSound: (state, { payload }) => {
			state.notificationSound = payload;
		},
	},
	extraReducers: (builder) => {
		// builder.addCase(fetch.pending, (state, { payload }) => {
		// });
		// builder.addCase(fetch.fulfilled, (state, { payload }) => {
		// });
	},
});

export const { fetchNotificationHistory, fetchNotifications, setReload, setNotificationSound } = notificationSlice.actions;
export default notificationSlice.reducer;
