import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { socket } from "../../App";
import axios from "../../components/Hooks/axios";

const initialState = {
	userId: "",
	user: null,
	token: "",
};

const authSlice = createSlice({
	name: "authSlice",
	initialState,
	reducers: {
		fetchDataFromLocalStorage: (state) => {
			// state.userId = localStorage.getItem("auth_userId") || sessionStorage.getItem("auth_userId");
			// state.token = localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token");
			// state.user = JSON.parse(localStorage.getItem("auth_user") || sessionStorage.getItem("auth_user"));

			if (Cookies.get("E_current_userId")) state.userId = JSON.parse(Cookies.get("E_current_userId"));
			if (Cookies.get("E_token")) state.token = JSON.parse(Cookies.get("E_token"));
			if (Cookies.get("E_current_user_obj")) state.user = JSON.parse(Cookies.get("E_current_user_obj"));
		},

		setAuth: (state, { payload }) => {
			state.userId = payload.userId;
			state.token = payload.token;

			if (payload.rememberMe) {
				Cookies.set("E_current_userId", JSON.stringify(payload.userId), { expires: 50 });
				Cookies.set("E_token", JSON.stringify(payload.token), { expires: 50 });
				Cookies.set("rememberMe", "rememberMe");
				// 50×24×60×60
			} else {
				Cookies.set("E_current_userId", JSON.stringify(payload.userId));
				Cookies.set("E_token", JSON.stringify(payload.token));
			}
		},

		setUserObject: (state, { payload }) => {
			state.user = payload;
			// if (localStorage.getItem("auth_userId")) localStorage.setItem("auth_user", JSON.stringify(payload));
			// else if (sessionStorage.getItem("auth_userId")) sessionStorage.setItem("auth_user", JSON.stringify(payload));

			if (Cookies.get("rememberMe") === undefined) Cookies.set("E_current_user_obj", JSON.stringify(payload));
			else Cookies.set("E_current_user_obj", JSON.stringify(payload), { expires: 50 });
		},

		logOut: (state, { payload }) => {
			axios.defaults.headers.Authorization = "";
			socket.emit("leaveLogoutRoom", state.userId);
			state.userId = "";
			state.token = "";
			state.user = null;
			// localStorage.removeItem("auth_userId");
			// sessionStorage.removeItem("auth_userId");
			// localStorage.removeItem("auth_token");
			// sessionStorage.removeItem("auth_token");
			// localStorage.removeItem("auth_user");
			// sessionStorage.removeItem("auth_user");
			Cookies.remove("E_current_user_obj");
			Cookies.remove("E_current_userId");
			Cookies.remove("E_persona_userId");
			Cookies.remove("E_token");
			Cookies.remove("rememberMe");
		},

		switchAccount: (state, { payload }) => {
			state.userId = payload.userId;
			state.user = payload.user;
			state.token = payload.token;

			axios.defaults.headers.Authorization = payload.token;

			if (Cookies.get("rememberMe") === undefined) {
				Cookies.set("E_current_userId", JSON.stringify(payload.userId));
				Cookies.set("E_current_user_obj", JSON.stringify(payload.user));
				Cookies.set("E_token", JSON.stringify(payload.token));
			} else {
				Cookies.set("E_current_userId", JSON.stringify(payload.userId), { expires: 50 });
				Cookies.set("E_current_user_obj", JSON.stringify(payload.user), { expires: 50 });
				Cookies.set("E_token", JSON.stringify(payload.token), { expires: 50 });
			}
		},
	},
});

export const { fetchDataFromLocalStorage, setAuth, setUserObject, logOut, switchAccount } = authSlice.actions;
export default authSlice.reducer;
