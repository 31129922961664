import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isOpen: false,
	path: "",
};

const AdsSlice = createSlice({
	name: "AdsSlice",
	initialState,
	reducers: {
		setIsOpenAds: (state, { payload }) => {
			state.isOpen = payload.isOpen;
			state.path = payload.path;
		},
	},
});

export const { setIsOpenAds } = AdsSlice.actions;

export default AdsSlice.reducer;
