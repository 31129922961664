import React, { useEffect, useRef, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { toast } from "react-toastify";
import Background1 from "../../assets/images/Background1.jpg";
import Header from "../../components/Header/Header";
import ScrollToTop from "../../components/Hooks/ScrollToTop";
import axios from "../../components/Hooks/axios";
import SmallHeader from "../../components/Small_Header/SmallHeader";
import EnablePollContainer from "../Post/EnablePollContainer";
import styles from "../Post/Post.module.scss";

const UploadPost = () => {
	const navigate = useNavigate();
	const data = useLocation();
	const { userId } = useSelector((state) => state.auth);

	const [fileData, setFileData] = useState(data?.state?.fileData);
	const [fileType, setFileType] = useState(data?.state?.fileType);
	const imageRef = useRef(null);
	const [enablePoll, setEnablePoll] = useState(false);
	const [pollResult, setPollResult] = useState(false);
	const [timer, setTimer] = useState("0");
	const [caption, setCaption] = useState(data?.state?.caption === undefined ? "" : data?.state?.caption);
	const [tag, setTag] = useState([]);
	const [btnDisable, setBtnDisable] = useState(false);
	const textRef = useRef();
	const [uploadPercentage, setUploadPercentage] = useState(0);

	const [LikesView, setLikesView] = useState(false);
	const [everyone1, setEveryone1] = useState(true);
	const [people1, setPeople1] = useState(false);
	const [noOne1, setNoOne1] = useState(false);
	const [HideLikeViewsControl, setHideLikeViewsControl] = useState(false);
	const [everyone2, setEveryone2] = useState(true);
	const [people2, setPeople2] = useState(false);
	const [noOne2, setNoOne2] = useState(false);

	const handelUpload = () => {
		if (fileData === null) return toast.warn("Please Select image/video !!");
		setBtnDisable(true);
		const id = toast.loading("Uploading...");

		const formData = new FormData();

		formData.append("userId", userId);
		formData.append("mediaType", fileType);
		formData.append("mediaUrl", fileData);
		formData.append("Enabledpoll", enablePoll);
		formData.append("ShowPollResults", pollResult);
		formData.append("caption", caption);
		formData.append("pollDuration", timer);
		formData.append("privacy", `{"everyone":${everyone1},"followers":${people1},"no_one":${noOne1}}`);
		formData.append("tag_privacy", `{"everyone":${everyone2},"followers":${people2},"no_one":${noOne2}}`);
		formData.append("tags", JSON.stringify(tag));
		formData.append("post_hide", "[]"); //NOTE:
		formData.append("tagPeople", "[]"); //NOTE:

		axios
			.post("/post/", formData, {
				onUploadProgress: (progressEvent) => {
					const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					setUploadPercentage(percentCompleted);
				},
			})
			.then(({ data }) => {
				toast.update(id, { render: data.message, type: "success", isLoading: false, autoClose: 2000 });
				setBtnDisable(false);
				navigate("/Home");
			})
			.catch(({ response: { data } }) => {
				setBtnDisable(false);
				toast.update(id, { render: data.error, type: "error", isLoading: false, autoClose: 2000 });
				console.log(data);
			});
	};

	useEffect(() => {
		if (enablePoll) setTimer("1");
		else {
			setTimer("0");
			setPollResult(false);
		}
	}, [enablePoll]);

	return (
		<div className="App">
			<div className="MainContainer">
				<ScrollToTop />
				<Header />
				<div className={`${styles.Container} ${styles.Post}`}>
					<SmallHeader />
					<div className={styles.EditPostBody}>
						<div className={styles.EditPostBodyLeft}>
							<div className={styles.EditPostBox}>
								<div className={styles.ImageContainer} onClick={() => imageRef?.current?.click()}>
									{fileData?.type === "video/mp4" ? (
										<video src={URL.createObjectURL(fileData)} controls></video>
									) : (
										<img src={fileData ? URL.createObjectURL(fileData) : Background1} alt="" />
									)}
									{fileData === null ? <h2>Upload</h2> : <h2>Change</h2>}
									<input
										type="file"
										ref={imageRef}
										accept="image/*,video/*"
										style={{ display: "none" }}
										onChange={(e) => {
											setFileData(e.target.files[0]);
											if (e.target.files[0].type === "video/mp4") setFileType("video");
											else setFileType("image");
											e.target.value = null;
										}}
									/>
								</div>
							</div>

							<div className={styles.LeftBottom}>
								<div className={styles.EnablePollMainContainer}>
									<EnablePollContainer value={enablePoll} setValue={setEnablePoll} />

									<div className={styles.ShowPollResult}>
										<p>Show Poll Results</p>
										<Switch
											onChange={(e) => {
												if (enablePoll) setPollResult(e);
												else toast.info("Please Enable Poll !");
											}}
											checked={pollResult}
											offColor={"#c4c4c4"}
											onColor={"#909AFF"}
											offHandleColor={"#959595"}
											onHandleColor={"#2E5EE2"}
											uncheckedIcon={false}
											checkedIcon={false}
											activeBoxShadow={"0 0 0px 0px #909AFF"}
											height={30}
											width={64}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className={styles.EditPostBodyRight}>
							<div className={styles.RightInner}>
								{/* <EnablePollContainer /> */}

								{/* 👇 Make this Hidden when EnablePollContainer Enable 👇 */}
								<div className={styles.SetTimerContainer}>
									<p>Set Timer</p>
									<div className={styles.SetTimerInner}>
										<div className={styles.SetTimerInnerLeft}>
											<div
												className={`${styles.dates} ${timer === "1" ? styles.active : ""}`}
												onClick={() => {
													if (enablePoll) setTimer("1");
													else toast.info("Please Enable Poll !");
												}}>
												1D
											</div>
											<div
												className={`${styles.dates} ${timer === "2" ? styles.active : ""}`}
												onClick={() => {
													if (enablePoll) setTimer("2");
													else toast.info("Please Enable Poll !");
												}}>
												2D
											</div>
											<div
												className={`${styles.dates} ${timer === "7" ? styles.active : ""}`}
												onClick={() => {
													if (enablePoll) setTimer("7");
													else toast.info("Please Enable Poll !");
												}}>
												7D
											</div>
										</div>
										<div className={styles.SetTimerInnerRight}>
											<select
												defaultValue={timer}
												onChange={(e) => {
													if (enablePoll) setTimer(e.target.value);
													else toast.info("Please Enable Poll !");
												}}>
												<option value="">Set Custom</option>
												{/* <option value="Permanent">Permanent</option> */}
												{Array(15)
													.fill("")
													.map((d, index) => (
														<option key={index} value={index + 1}>
															{index + 1}D
														</option>
													))}
											</select>
										</div>
									</div>
								</div>
								{/* 👆 Make this Hidden when EnablePollContainer Enable 👆 */}

								<div className={styles.describeContainer}>
									<p>Describe your feeling</p>
									<div className={styles.TextAreaContainer}>
										<textarea
											ref={textRef}
											defaultValue={caption}
											onChange={(e) => {
												if (e.target.value.split(" ").length <= 32) setCaption(e.target.value);
												else {
													toast.info("Limit Excited!!");
													textRef.current.blur();
												}
											}}
											className={styles.textarea1}></textarea>

										<div className={styles.row2}>
											<textarea
												defaultValue={tag}
												onChange={(e) => {
													let value = e.target.value?.split("#").slice(1);
													setTag(value);
												}}
												className={styles.textarea2}></textarea>
											<span>
												{caption?.split(" ").length < 10
													? `0${caption?.split(" ").length}`
													: caption?.split(" ").length}
												/32
											</span>
										</div>
									</div>
								</div>

								<div className={styles.PostContainer}>
									<p>Post Setting</p>
									<div className={styles.PostCont1}>
										<h2>Post</h2>
										<div className={styles.PostCont1Inn}>
											<h5>Likes & View</h5>
											<p
												onClick={() => {
													setLikesView(!LikesView);
													setHideLikeViewsControl(false);
												}}>
												Everyone {">"}
											</p>
										</div>
										<div className={styles.PostCont1Inn}>
											<h5>Hide like & views control</h5>
											<p
												onClick={() => {
													setHideLikeViewsControl(!HideLikeViewsControl);
													setLikesView(false);
												}}>
												0 people {">"}
											</p>
										</div>
									</div>

									{LikesView && (
										<div className={styles.PostCont2}>
											<p>Allow Tag from (Likes & View)</p>
											<div className={styles.PostCont2Inn}>
												<h2>Everyone</h2>
												<Switch
													onChange={(e) => {
														setEveryone1(e);
														setPeople1(false);
														setNoOne1(false);
													}}
													checked={everyone1}
													offColor={"#eee"}
													onColor={"#eee"}
													offHandleColor={"#959595"}
													onHandleColor={"#3A0DBB"}
													uncheckedIcon={false}
													checkedIcon={false}
													activeBoxShadow={"0 0 0px 0px #909AFF"}
													height={14}
													width={33}
												/>
											</div>

											<div className={styles.PostCont2Inn}>
												<h2>People you follow</h2>
												<Switch
													onChange={(e) => {
														setEveryone1(false);
														setPeople1(e);
														setNoOne1(false);
													}}
													checked={people1}
													offColor={"#eee"}
													onColor={"#eee"}
													offHandleColor={"#959595"}
													onHandleColor={"#3A0DBB"}
													uncheckedIcon={false}
													checkedIcon={false}
													activeBoxShadow={"0 0 0px 0px #909AFF"}
													height={14}
													width={33}
												/>
											</div>

											<div className={styles.PostCont2Inn}>
												<h2>No one</h2>
												<Switch
													onChange={(e) => {
														setEveryone1(false);
														setPeople1(false);
														setNoOne1(e);
													}}
													checked={noOne1}
													offColor={"#eee"}
													onColor={"#eee"}
													offHandleColor={"#959595"}
													onHandleColor={"#3A0DBB"}
													uncheckedIcon={false}
													checkedIcon={false}
													activeBoxShadow={"0 0 0px 0px #909AFF"}
													height={14}
													width={33}
												/>
											</div>
										</div>
									)}

									{HideLikeViewsControl && (
										<div className={styles.PostCont2}>
											<p>Allow Tag from (Hide like & views control)</p>
											<div className={styles.PostCont2Inn}>
												<h2>Everyone</h2>
												<Switch
													onChange={(e) => {
														setEveryone2(e);
														setPeople2(false);
														setNoOne2(false);
													}}
													checked={everyone2}
													offColor={"#eee"}
													onColor={"#eee"}
													offHandleColor={"#959595"}
													onHandleColor={"#3A0DBB"}
													uncheckedIcon={false}
													checkedIcon={false}
													activeBoxShadow={"0 0 0px 0px #909AFF"}
													height={14}
													width={33}
												/>
											</div>

											<div className={styles.PostCont2Inn}>
												<h2>People you follow</h2>
												<Switch
													onChange={(e) => {
														setEveryone2(false);
														setPeople2(e);
														setNoOne2(false);
													}}
													checked={people2}
													offColor={"#eee"}
													onColor={"#eee"}
													offHandleColor={"#959595"}
													onHandleColor={"#3A0DBB"}
													uncheckedIcon={false}
													checkedIcon={false}
													activeBoxShadow={"0 0 0px 0px #909AFF"}
													height={14}
													width={33}
												/>
											</div>

											<div className={styles.PostCont2Inn}>
												<h2>No one</h2>
												<Switch
													onChange={(e) => {
														setEveryone2(false);
														setPeople2(false);
														setNoOne2(e);
													}}
													checked={noOne2}
													offColor={"#eee"}
													onColor={"#eee"}
													offHandleColor={"#959595"}
													onHandleColor={"#3A0DBB"}
													uncheckedIcon={false}
													checkedIcon={false}
													activeBoxShadow={"0 0 0px 0px #909AFF"}
													height={14}
													width={33}
												/>
											</div>
										</div>
									)}

									<button className={styles.uploadBtn} disabled={btnDisable} onClick={handelUpload}>
										{btnDisable ? (
											<>
												<div>
													<FallingLines
														color="#fff"
														width="60"
														visible={true}
														ariaLabel="falling-lines-loading"
													/>
													{uploadPercentage}%
												</div>
												<span style={{ width: `${uploadPercentage}%` }}></span>
											</>
										) : (
											"Upload"
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UploadPost;
