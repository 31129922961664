import React, { useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as CaretLeft } from "../../../assets/icons/CaretLeft.svg";
import { ReactComponent as ListIcon } from "../../../assets/icons/LiIcon.svg";
import { ReactComponent as MasterCard } from "../../../assets/icons/MasterCard.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/SuccessIcon.svg";
import Loading from "../../../components/Hooks/Loading";
import axios from "../../../components/Hooks/axios";
import styles from "./AdsApply.module.scss";

const AdsPayment = ({ setActiveSection, activeAddData, setActiveAddData, myAddData, setMyAddData }) => {
	const [iSSuccess, setISSuccess] = useState(false);
	const [paymentOption, setPaymentOption] = useState("");
	const [cardNumber, setCardNumber] = useState("");
	const [expDate, setExpDate] = useState("");
	const [cvv, setCvv] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const handleNumberChange = (event) => {
		const inputValue = event.target.value;
		const maxLength = 16;
		if (inputValue.length <= maxLength) setCardNumber(inputValue);
		if (inputValue.length === maxLength) event.target.blur();
	};

	const handleCvvChange = (event) => {
		const inputValue = event.target.value;
		const maxLength = 3;
		if (inputValue.length <= maxLength) setCvv(inputValue);
		if (inputValue.length === maxLength) event.target.blur();
	};

	const handelPay = () => {
		if (cardNumber === "") return toast.error("Please enter your card number !");
		if (expDate === "") return toast.error("Please enter your card expire date !");
		if (cvv === "") return toast.error("Please enter your card cvv number !");
		if (paymentOption === "") return toast.error("Please select one payment option !");

		setIsLoading(true);

		axios
			.put(`/adsApply/updatePayment/${myAddData?._id}`)
			.then(({ data }) => {
				setISSuccess(true);
				setActiveAddData(null);
				setMyAddData(null);
				setIsLoading(false);
			})
			.catch(({ response }) => {
				console.log("Error => ", response);
			});
	};

	return (
		<div className={styles.AdsPaymentWrapper}>
			<div className={styles.Left}>
				<div className={styles.Box}>
					<h1>{activeAddData?.title}</h1>

					<p>{activeAddData?.description}</p>

					<img src={activeAddData?.img} alt="" />

					<h2 className={styles.Amount}>
						₹{activeAddData?.amount}
						<span>/</span>
						<span>{activeAddData?.type}</span>
					</h2>

					<div className={styles.List}>
						<ul>
							{activeAddData?.points.map((data, index) => (
								<li key={index}>
									<ListIcon /> {data}
								</li>
							))}
						</ul>
					</div>

					<div className={styles.Wrapper2}>
						<button>Get Started</button>
					</div>
				</div>
			</div>

			<div className={styles.Right}>
				<button className={styles.BackBtn} onClick={() => setActiveSection("2")}>
					<CaretLeft />
				</button>

				{iSSuccess ? (
					<div className={styles.Success}>
						<SuccessIcon />
						<h2>Payment Successful</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque volutpat </p>
					</div>
				) : (
					<div className={styles.Wrapper}>
						<h5>Pay for {activeAddData?.title}</h5>
						<h6>Pay</h6>
						<h1>₹{activeAddData?.amount}</h1>
						<h4>Invoice ( Free )</h4>
						<h4>
							Debit / Credit Card <MasterCard />
						</h4>

						<div className={styles.CartDetails}>
							<input type="number" placeholder="Card Number" value={cardNumber} onChange={handleNumberChange} />
							<input
								type="number"
								placeholder="MM/YY"
								value={expDate}
								onChange={(e) => setExpDate(e.target.value)}
							/>
							<input type="number" placeholder="CVV" value={cvv} onChange={handleCvvChange} />
						</div>

						<div className={styles.Option}>
							<h2>Other Payment Option</h2>
							<div className={styles.Radio}>
								<div>
									<input
										type="radio"
										name="payment"
										id="PPe"
										onChange={(e) => {
											if (e.target.checked) setPaymentOption("Phone Pay");
										}}
									/>
									<label htmlFor="PPe">Phone Pe</label>
								</div>

								<div>
									<input
										type="radio"
										name="payment"
										id="upi"
										onChange={(e) => {
											if (e.target.checked) setPaymentOption("UPI");
										}}
									/>
									<label htmlFor="upi">UPI</label>
								</div>

								<div>
									<input
										type="radio"
										name="payment"
										id="GP"
										onChange={(e) => {
											if (e.target.checked) setPaymentOption("G-Pay");
										}}
									/>
									<label htmlFor="GP">GooglePay</label>
								</div>

								<div>
									<input
										type="radio"
										name="payment"
										id="BT"
										onChange={(e) => {
											if (e.target.checked) setPaymentOption("Bank Transfer");
										}}
									/>
									<label htmlFor="BT">Bank Transfer</label>
								</div>
							</div>
						</div>

						<div className={styles.BottomPart}>
							<h6>Total Price</h6>
							<h3>₹{activeAddData?.amount}</h3>

							<button className={styles.PayBtn} onClick={handelPay} disabled={isLoading}>
								{isLoading ? <Loading color={"#fff"} /> : "Pay"}
							</button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default AdsPayment;
