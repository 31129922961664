import React, { useEffect, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../../components/Hooks/axios";
import styles from "./index.module.scss";

const Poll = ({ setPollShow, postData }) => {
	const { userId } = useSelector((state) => state.auth);
	const [pollResult, setPollResult] = useState(false);
	const [pollCalculation, setPollCalculation] = useState(null);
	const [reload, setReload] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	const handelPoll = (vote) => {
		const raw = JSON.stringify({
			vote,
			userId: userId,
		});

		axios
			.post(`/post/${postData?._id}/Poll`, raw, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(({ data }) => {
				setPollResult(true);
				setReload(Math.random());
				if (data.message) toast.success("Poll reaction successfully !");
				if (data.error) toast.error(data.error);
			})
			.catch(({ response: { data } }) => {
				setPollResult(true);
				setReload(Math.random());
			});
	};

	useEffect(() => {
		setIsLoading(true);
		axios
			.get(`/post/${postData._id}/PollResults`)
			.then(({ data }) => {
				console.log(data);
				setIsLoading(false);
				setPollResult(data?.isVoted);
				if (data.error === "Poll results are not visible for this post") setPollCalculation(null);
				else setPollCalculation(data?.pollCalculation);
			})
			.catch(({ response: { data } }) => {
				console.log(data);
			});
	}, [reload]);

	return (
		<div className={styles.PollContainer} onClick={() => setPollShow(false)}>
			<div className={styles.PollSection} onClick={(e) => e.stopPropagation()}>
				{isLoading ? (
					<FallingLines color="#3A0DBB" width="100" visible={true} ariaLabel="falling-lines-loading" />
				) : pollResult ? (
					<>
						<p>Poll Result</p>

						{postData?.ShowPollResults ? (
							<div className={styles.ResultCont}>
								{postData?.customPollEnabled ? (
									<>
										{postData?.customPollData?.map((data, index) => (
											<div key={index} className={styles.Result}>
												<div className={styles.Top}>
													<span>{data}</span>
													<span>{pollCalculation?.[data]}%</span>
												</div>
												<div className={styles.Bottom}>
													<span style={{ width: `${pollCalculation?.[data]}%` }}></span>
												</div>
											</div>
										))}
									</>
								) : (
									<>
										<div className={styles.Result}>
											<div className={styles.Top}>
												<span>Yes</span>
												<span>{pollCalculation?.yesPercentage}%</span>
											</div>
											<div className={styles.Bottom}>
												<span style={{ width: `${pollCalculation?.yesPercentage}%` }}></span>
											</div>
										</div>

										<div className={styles.Result}>
											<div className={styles.Top}>
												<span>No</span>
												<span>{pollCalculation?.noPercentage}%</span>
											</div>
											<div className={styles.Bottom}>
												<span style={{ width: `${pollCalculation?.noPercentage}%` }}></span>
											</div>
										</div>
									</>
								)}
							</div>
						) : (
							<p>No Result Public</p>
						)}
					</>
				) : (
					<>
						<p>Chose your poll</p>

						<div className={styles.ButtonCont}>
							{postData?.customPollEnabled ? (
								postData?.customPollData?.map((data, index) => (
									<button key={index} onClick={() => handelPoll(data)}>
										{index === 0 && "A"}
										{index === 1 && "B"}
										{index === 2 && "C"}
										{index === 3 && "D"}. {data}
									</button>
								))
							) : (
								<>
									<button onClick={() => handelPoll("yes")}>A. Yes</button>
									<button onClick={() => handelPoll("no")}>B. No</button>
								</>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Poll;
