import EmojiPicker from "emoji-picker-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as BlockUserIcon } from "../../assets/icons/BlockUserIcon.svg";
import { ReactComponent as EmojiBtnIcon } from "../../assets/icons/EmojiBtnIcon.svg";
import { ReactComponent as HidePostIcon } from "../../assets/icons/HidePostIcon.svg";
import { ReactComponent as RemoveMention } from "../../assets/icons/RemoveMention.svg";
import { ReactComponent as ReportIcon } from "../../assets/icons/ReportIcon.svg";
import { ReactComponent as SaveIcon } from "../../assets/icons/SaveIcon.svg";
import { ReactComponent as SendIcon } from "../../assets/icons/SendIcon.svg";
import { ReactComponent as UnfollowIcon } from "../../assets/icons/UnfollowIcon.svg";
import HeartFilled from "../../assets/svg/HeartFilled.svg";
import HeartUnfilled from "../../assets/svg/HeartUnfilled.svg";
import { ReactComponent as LoveIcon } from "../../assets/svg/LoveIcon.svg";
import comment from "../../assets/svg/comment.svg";
import share from "../../assets/svg/share.svg";
import { setShareData } from "../../redux/slices/tempSlice";
import Header from "../Header/Header";
import ScrollToTop from "../Hooks/ScrollToTop";
import axios from "../Hooks/axios";
import styles from "./ViewPost.module.scss";

const ViewPost = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const { userId } = useSelector((state) => state.auth);

	const [postData, setPostData] = useState({});
	const [commentsData, setCommentsData] = useState([]);
	const [commentText, setCommentText] = useState("");
	const [commentId, setCommentId] = useState("");
	const [replyMainId, setReplyMainId] = useState("");
	const [replyId, setReplyId] = useState("");
	const [isLiked, setIsLiked] = useState(false);
	const [likeCount, setLikeCount] = useState(0);
	const inputRef = useRef();
	const [threeDotShow, setThreeDotShow] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [zoom, setZoom] = useState(1);

	const [userBtn, setUserBtn] = useState({ in: 0, ml: 0 });
	const [openEmoji, setOpenEmoji] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		fetchComments();
		axios
			.get(`/post/getPost/${id}`)
			.then(({ data }) => {
				setPostData(data);
				// setCommentsData(data?.Comments);
				setLikeCount(data?.LikeCount);
				setIsLiked(data?.isLike);
				setIsLoading(false);
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	const fetchComments = () => {
		axios
			.get(`/post/${id}/getcomments`)
			.then(({ data }) => {
				setCommentsData(data.comments);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const postComment = () => {
		if (commentText === "") return toast.warn("Please write something !");
		const id = toast.loading("Comment Posting...");
		axios
			.post(`/post/${postData?._id}/comment`, {
				userId,
				text: commentText,
			})
			.then(({ data }) => {
				toast.update(id, {
					render: data.message,
					type: "success",
					isLoading: false,
					autoClose: 2000,
				});
				console.log(data);
				setCommentsData(data.post.Comments);
				setCommentText("");
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const postCommentOnComment = () => {
		if (commentText === "") return toast.warn("Please write something !");
		const id = toast.loading("Comment Posting...");
		axios
			.post(`/post/comment-on-comment?postId=${postData?._id}&commentId=${commentId}&userId=${userId}`, {
				text: commentText,
			})
			.then(({ data }) => {
				toast.update(id, {
					render: data.message,
					type: "success",
					isLoading: false,
					autoClose: 2000,
				});
				setCommentText("");
				fetchComments();
				setCommentId("");
				inputRef?.current?.blur();
			})
			.catch(({ response: { data } }) => {
				toast.update(id, {
					render: data.message,
					type: "error",
					isLoading: false,
					autoClose: 2000,
				});
			});
	};

	const postReplyOnReply = () => {
		if (commentText === "") return toast.warn("Please write something !");
		const toastId = toast.loading("Comment Posting...");
		axios
			.post(`/post/comment-on-comment?postId=${id}&commentId=${replyMainId}&userId=${userId}&replyCommentId=${replyId}`, {
				text: commentText,
			})
			.then(({ data }) => {
				toast.update(toastId, {
					render: data.message,
					type: "success",
					isLoading: false,
					autoClose: 2000,
				});
				setCommentText("");
				fetchComments();
				setReplyMainId("");
				setReplyId("");
				inputRef?.current?.blur();
			})
			.catch(({ response: { data } }) => {
				toast.update(id, {
					render: data.message,
					type: "error",
					isLoading: false,
					autoClose: 2000,
				});
			});
	};

	const handelLike = () => {
		axios
			.post(`/post/${postData?._id}/likeANDunlike`, {
				userId,
			})
			.then(({ data }) => {
				setIsLiked(data.totalLikes[0].isLike);
				setLikeCount(data.totalLikes[0].LikeCount);
			})
			.catch(({ response }) => {
				console.log(response);
			});
	};

	const commentLike = (commentId) => {
		const raw = {
			postId: id,
			commentId,
			userId,
		};
		axios
			.post(`/post/like-comment`, raw)
			.then(({ data }) => {
				toast.success(data.message);
				setCommentsData(data.comments);
			})
			.catch(({ response }) => {
				toast.info("You already like this comment !");
			});
	};

	useEffect(() => {
		if (commentId !== "") inputRef?.current?.focus();
		if (commentId === "") inputRef?.current?.blur();
	}, [commentId]);

	useEffect(() => {
		if (replyId !== "") inputRef?.current?.focus();
		if (replyId === "") inputRef?.current?.blur();
	}, [replyId]);

	const ReplySubCommentBox = ({ data }) => {
		const date = moment(data?.timestamp).format("hh:mmA [on] DD-MM-YY");
		return (
			<div className={styles.ReplySubCommentBoxWrapper}>
				<div className={styles.ReplySubCommentBox}>
					<div className={styles.left}>
						<div className={styles.leftImage}>
							<img src={data?.userId?.ProfilePic} alt="user image" />
						</div>

						<div className={styles.leftText}>
							<p>{data?.userId?.username}</p>

							<h4>{data?.text}</h4>

							<div>
								<span>{date}</span>
								<span onClick={() => commentLike(data._id)}>Love Back</span>
							</div>
						</div>
					</div>

					<div className={styles.CBRight}>
						{data.likes.length > 0 && (
							<>
								<p>{data.likes.length}+</p>
								<div className={styles.Round}>
									<LoveIcon />
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		);
	};

	const SubComment = ({ data, mainCommentId }) => {
		const date = moment(data?.timestamp).format("hh:mmA [on] DD-MM-YY");
		return (
			<div className={styles.SubCommentBoxWrapper}>
				<div className={styles.SubCommentBox}>
					<div className={styles.CBLeft}>
						<div className={styles.ImageWrapper}>
							<img src={data?.userId?.ProfilePic} alt="" />
						</div>

						<div className={styles.TextWrapper}>
							<div className={styles.Name}>{data?.userId?.username}</div>
							<div className={styles.Text}>{data?.text}</div>
							<div className={styles.ButtonContainer}>
								<span>{date}</span>
								<button
									style={{
										background: data._id === replyId ? "#3A0DBB" : "",
										color: data._id === replyId ? "#fff" : "",
									}}
									onClick={() => {
										if (data?._id === replyId) {
											setReplyMainId("");
											setReplyId("");
											setCommentId("");
										} else {
											setReplyMainId(mainCommentId);
											setReplyId(data._id);
											setCommentId("");
										}
									}}>
									Reply
								</button>
								<button onClick={() => commentLike(data._id)}>Love Back</button>
							</div>
						</div>
					</div>

					<div className={styles.CBRight}>
						{data.likes.length > 0 && (
							<>
								<p>{data.likes.length}+</p>
								<div className={styles.Round}>
									<LoveIcon />
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		);
	};

	const MainComment = ({ data }) => {
		const date = moment(data?.timestamp).format("hh:mmA [on] DD-MM-YY");
		return (
			<div className={styles.CommentBox}>
				<div className={styles.CBLeft}>
					<div className={styles.ImageWrapper}>
						<img src={data?.userId?.ProfilePic} alt="" />
					</div>

					<div className={styles.TextWrapper}>
						<div className={styles.Name}>{data?.userId?.username}</div>
						<div className={styles.Text}>{data.text}</div>
						<div className={styles.ButtonContainer}>
							<span>{date}</span>
							<button
								style={{
									background: data._id === commentId ? "#3A0DBB" : "",
									color: data._id === commentId ? "#fff" : "",
								}}
								onClick={() => {
									if (data?._id === commentId) {
										setCommentId("");
										setReplyMainId("");
										setReplyId("");
									} else {
										setCommentId(data._id);
										setReplyMainId("");
										setReplyId("");
									}
								}}>
								Reply
							</button>
							<button onClick={() => commentLike(data._id)}>Love Back</button>
						</div>
					</div>
				</div>

				<div className={styles.CBRight}>
					{data.likes.length > 0 && (
						<>
							<p>{data.likes.length}+</p>
							<div className={styles.Round}>
								<LoveIcon />
							</div>
						</>
					)}
				</div>
			</div>
		);
	};

	const [isDragging, setIsDragging] = useState(false);
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

	useEffect(() => {
		const handleMouseMove = (event) => {
			if (!isDragging) return;
			if (zoom <= 1) return;
			const offsetX = event.clientX - dragStart.x;
			const offsetY = event.clientY - dragStart.y;
			setPosition({ x: position.x + offsetX, y: position.y + offsetY });
			setDragStart({ x: event.clientX, y: event.clientY });
		};

		const handleMouseUp = () => {
			setIsDragging(false);
		};

		const handleTouchMove = (event) => {
			if (!isDragging) return;
			if (zoom <= 1) return;
			const offsetX = event.touches[0].clientX - dragStart.x;
			const offsetY = event.touches[0].clientY - dragStart.y;
			setPosition({ x: position.x + offsetX, y: position.y + offsetY });
			setDragStart({ x: event.touches[0].clientX, y: event.touches[0].clientY });
		};

		const handleTouchEnd = () => {
			setIsDragging(false);
		};

		if (isDragging) {
			document.addEventListener("mousemove", handleMouseMove);
			document.addEventListener("mouseup", handleMouseUp);
			document.addEventListener("touchmove", handleTouchMove);
			document.addEventListener("touchend", handleTouchEnd);
		} else {
			document.removeEventListener("mousemove", handleMouseMove);
			document.removeEventListener("mouseup", handleMouseUp);
			document.removeEventListener("touchmove", handleTouchMove);
			document.removeEventListener("touchend", handleTouchEnd);
		}

		return () => {
			document.removeEventListener("mousemove", handleMouseMove);
			document.removeEventListener("mouseup", handleMouseUp);
			document.removeEventListener("touchmove", handleTouchMove);
			document.removeEventListener("touchend", handleTouchEnd);
		};
	}, [isDragging, dragStart, position]);

	const handleMouseDown = (event) => {
		setIsDragging(true);
		setDragStart({ x: event.clientX, y: event.clientY });
	};
	const handleTouchStart = (event) => {
    setIsDragging(true);
    setDragStart({ x: event.touches[0].clientX, y: event.touches[0].clientY });
};

	return (
		<div className="App">
			<div className={`MainContainer ${styles.Container}`}>
				<ScrollToTop />
				<Header />

				<div className={styles.BackgroundImage}>
					{postData?.mediaType === "image" ? (
						<img src={postData?.mediaUrl[userBtn.in]} alt="PostImage" className={styles.BackgroundImage} />
					) : postData?.mediaType === "video" ? (
						<video
							src={postData?.mediaUrl[userBtn.in]}
							className={styles.BackgroundImage}
							autoPlay
							muted
							loop></video>
					) : (
						""
					)}
					<div className={styles.Overlay}></div>
				</div>

				<div className={styles.ViewPost}>
					<div className={styles.Left}>
						<div className={styles.ZoomContainer}>
							<button
								disabled={zoom === 3}
								onClick={() => {
									if (zoom < 3) {
										let temp = zoom;
										temp = temp + 0.5;
										setZoom(temp);
										setPosition({ x: 0, y: 0 });
										setDragStart({ x: 0, y: 0 });
									}
								}}>
								+
							</button>
							<button
								disabled={zoom === 1}
								onClick={() => {
									if (zoom > 1) {
										let temp = zoom;
										temp = temp - 0.5;
										setZoom(temp);
										setPosition({ x: 0, y: 0 });
										setDragStart({ x: 0, y: 0 });
									} else setZoom(1);
								}}>
								-
							</button>
						</div>

						<div className={styles.ImageContainer}>
							{isLoading ? (
								<FallingLines color="#3A0DBB" width="100" visible={true} ariaLabel="falling-lines-loading" />
							) : postData?.mediaType === "image" ? (
								<img
									src={postData?.mediaUrl[userBtn.in]}
									alt=""
									onDragStart={(e) => e.preventDefault()}
									onMouseDown={handleMouseDown}
									onTouchStart={handleTouchStart}
									style={{
										transform: `translate(${position.x}px,${position.y}px) scale(${zoom})`,
										transition: isDragging ? "" : "all 0.3s ease-in-out",
										cursor: isDragging ? "grabbing" : zoom > 1 ? "grab" : "default",
									}}
								/>
							) : postData?.mediaType === "video" ? (
								<video src={postData?.mediaUrl[userBtn.in]} controls></video>
							) : (
								""
							)}
						</div>
					</div>

					<div className={styles.Right}>
						<div className={styles.RightTopContainer}>
							<div className={styles.CommentWrapper}>
								<div className={styles.UserContainer}>
									<div className={styles.ULeft}>
										<div className={styles.ImageContainer}>
											<img src={postData?.userId?.ProfilePic} alt="" />
										</div>

										<div className={styles.TextWrapper}>
											<div className={styles.name}>{postData?.userId?.username}</div>
											<div className={styles.UserName}>@{postData?.userId?.GetstatedName}</div>
										</div>
									</div>

									<div className={styles.URight} onClick={() => setThreeDotShow(true)}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="36"
											height="37"
											viewBox="0 0 36 37"
											fill="none">
											<path
												d="M18.0577 5.30469C16.4211 5.30469 15.082 6.64375 15.082 8.28038C15.082 9.91701 16.4211 11.2561 18.0577 11.2561C19.6944 11.2561 21.0334 9.91701 21.0334 8.28038C21.0334 6.64375 19.6944 5.30469 18.0577 5.30469ZM18.0577 26.1346C16.4211 26.1346 15.082 27.4736 15.082 29.1102C15.082 30.7469 16.4211 32.0859 18.0577 32.0859C19.6944 32.0859 21.0334 30.7469 21.0334 29.1102C21.0334 27.4736 19.6944 26.1346 18.0577 26.1346ZM18.0577 15.7196C16.4211 15.7196 15.082 17.0587 15.082 18.6953C15.082 20.3319 16.4211 21.671 18.0577 21.671C19.6944 21.671 21.0334 20.3319 21.0334 18.6953C21.0334 17.0587 19.6944 15.7196 18.0577 15.7196Z"
												fill="white"
											/>
										</svg>
									</div>
								</div>

								<div className={styles.ActionContainer}>
									<div className={styles.Like} onClick={handelLike}>
										{isLiked ? <img src={HeartFilled} alt="" /> : <img src={HeartUnfilled} alt="" />}
										<p>{likeCount}</p>
									</div>

									<div className={styles.Comment} onClick={() => {}}>
										<img src={comment} alt="" />
										<p>{commentsData?.length}</p>
									</div>

									<div
										className={styles.Share}
										onClick={() =>
											dispatch(setShareData({ show: true, endPoint: "/Post/", id: postData._id }))
										}>
										<img src={share} alt="" />
									</div>
								</div>

								<div className={styles.CommentContainer}>
									<div className={styles.TopBar}>
										<div className={styles.TopLeft}>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="15"
												height="15"
												viewBox="0 0 15 15"
												fill="none">
												<path
													d="M4.12236 6.40062L8.86776 1.65523L7.61683 0.404297L0.735823 7.2853L7.61683 14.1663L8.86776 12.9154L4.12236 8.16997H14.8906V6.40062H4.12236Z"
													fill="white"
												/>
											</svg>
											<p>Comments</p>
										</div>

										<div className={styles.TopRight}>
											<p>Latest</p>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="23"
												height="22"
												viewBox="0 0 23 22"
												fill="none">
												<path
													d="M11.5603 13.9421L7.80664 10.1884L9.05846 8.9375L11.5603 11.4402L14.0622 8.9375L15.314 10.1884L11.5603 13.9421Z"
													fill="white"
												/>
											</svg>
										</div>
									</div>

									<div className={styles.BottomBar}>
										{commentsData?.map((main, index) => {
											return (
												<div key={index} className={styles.CommentContainerWrapper}>
													<MainComment key={index + main?._id} data={main} />

													{main.Comments.map((sub, i) => (
														<div key={i} className={styles.CommentContainerWrapper}>
															<SubComment data={sub} mainCommentId={main._id} />

															{sub?.Comments?.map((rep, ri) => (
																<ReplySubCommentBox key={ri} data={rep} />
															))}
														</div>
													))}
												</div>
											);
										})}
									</div>
								</div>

								<div className={styles.InputWrapper}>
									<div className={styles.InputContainer}>
										<input
											type="text"
											placeholder="Type your Comment"
											value={commentText}
											ref={inputRef}
											onChange={(e) => setCommentText(e.target.value)}
											onKeyDown={(e) => {
												if (e.key === "Enter") {
													if (commentId === "" && replyId === "") postComment();
													if (commentId !== "") postCommentOnComment();
													if (replyId !== "") postReplyOnReply();
													setOpenEmoji(false);
												}
											}}
										/>

										<button className={styles.EmojiBtn} onClick={() => setOpenEmoji(!openEmoji)}>
											<EmojiBtnIcon />
										</button>
									</div>

									<button
										className={styles.SubmitBtn}
										onClick={() => {
											if (commentId === "" && replyId === "") postComment();
											if (commentId !== "") postCommentOnComment();
											if (replyId !== "") postReplyOnReply();
											setOpenEmoji(false);
										}}>
										<SendIcon />
									</button>

									{openEmoji && (
										<div className={styles.EmojiContainer}>
											<EmojiPicker
												height="100%"
												width="100%"
												onEmojiClick={(e) => setCommentText((prevText) => prevText + e.emoji)}
												emojiStyle={"apple"}
											/>
										</div>
									)}
								</div>
							</div>
						</div>

						{postData?.mediaUrl?.length > 1 && (
							<div className={styles.RightBottomContainer}>
								<button
									onClick={() => {
										if (userBtn.in < postData?.mediaUrl?.length - 1)
											setUserBtn({ in: userBtn.in + 1, ml: userBtn.ml - 20 });
									}}>
									{"<"}
								</button>

								<div className={styles.SlideImages}>
									{postData?.mediaUrl?.map((data, index) => {
										return (
											<div
												key={index}
												className={`${styles.ImageWrapper} ${userBtn.in > index ? styles.inActive : ""}`}
												style={{
													marginLeft: index === 0 ? `${userBtn.ml}%` : "",
													zIndex: postData?.mediaUrl?.length - index,
												}}>
												{postData?.mediaType === "image" && <img src={data} alt="" />}
												{postData?.mediaType === "video" && <video src={data} muted />}
											</div>
										);
									})}
								</div>

								<button
									onClick={() => {
										if (userBtn.in > 0) setUserBtn({ in: userBtn.in - 1, ml: userBtn.ml + 20 });
									}}>
									{">"}
								</button>
							</div>
						)}
					</div>

					{threeDotShow && (
						<div className={styles.ThreeDotWrapper} onClick={() => setThreeDotShow(false)}>
							<div className={styles.ButtonWrapper} onClick={(e) => e.stopPropagation()}>
								<button>
									<ReportIcon /> Report
								</button>

								<button>
									<BlockUserIcon /> Block user
								</button>

								<button>
									<RemoveMention />
									Remove mention
								</button>

								<button>
									<HidePostIcon />
									Hide this post
								</button>

								<button>
									<UnfollowIcon />
									Un-follow
								</button>

								<button>
									<SaveIcon />
									Save
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ViewPost;
