import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import { toast } from "react-toastify";
import axios from "../../../components/Hooks/axios";
import { setUserObject } from "../../../redux/slices/authSlice";
import styles from "./BusinessLogo.module.scss";

const BusinessLogo = ({ businessLogoData }) => {
	const dispatch = useDispatch();

	const inputRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [logos, setLogos] = useState([]);

	useEffect(() => {
		if (businessLogoData === null) return;
		setLogos(businessLogoData.logos);
	}, [businessLogoData]);

	const handelLogoUpload = (e) => {
		setLoading(true);
		var formData = new FormData();
		formData.append("logos", e.target.files[0]);

		axios
			.post(`/auth/addCompanyLogo`, formData)
			.then(({ data }) => {
				setLoading(false);
				toast.success("Logo Added Successfully !!");
				dispatch(setUserObject(data.data));
			})
			.catch(({ response }) => {
				console.log("Error => ", response);
				setLoading(false);
			});
	};

	const handelSwitch = (e) => {
		axios
			.put(`auth/updateCompanyLogoStatus?status=${e}`)
			.then(({ data }) => {
				toast.success(data.message);
				dispatch(setUserObject(data.data));
			})
			.catch(({ response }) => {
				console.log("Error => ", response);
			});
	};

	const handelRemove = (data) => {
		 const id = toast.loading("Please wait...");
		axios
			.put(`auth/removeCompanyLogo`, {
				url: data,
			})
			.then(({ data }) => {
				toast.update(id, {
					render: data.message,
					type: "success",
					isLoading: false,
					autoClose: 2000,
				});
				dispatch(setUserObject(data.data));
			})
			.catch(({ response }) => {
				console.log("Error => ", response);
			});
	};

	return (
		<div className={styles.BusinessLogo}>
			<div className={styles.Left}>
				{logos.length === 0 ? (
					<h2>No Company Logo, Please add logo</h2>
				) : (
					logos.map((data, index) => (
						<div key={index} className={styles.Logo}>
							<button onClick={() => handelRemove(data)}>X</button>
							<img src={data} alt="" />
						</div>
					))
				)}
			</div>

			<div className={styles.Right}>
				<div className={styles.Up}>
					<div>
						<h3>Enable Logos</h3>
						<h4>Enable logos</h4>
					</div>

					<Switch
						onChange={(e) => handelSwitch(e)}
						checked={businessLogoData?.active || false}
						offColor={"#D1D5FF"}
						onColor={"#909AFF"}
						offHandleColor={"#A69F9F"}
						onHandleColor={"#2E5EE2"}
						uncheckedIcon={false}
						checkedIcon={false}
						activeBoxShadow={"0 0 0px 0px #909AFF"}
						height={24}
						width={48}
					/>
				</div>

				<button onClick={() => inputRef.current.click()} disabled={loading}>
					{loading ? (
						"Loading..."
					) : (
						<>
							<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
								<path
									d="M7.83335 11.229V5.79567L6.10002 7.529L5.16669 6.56234L8.50002 3.229L11.8334 6.56234L10.9 7.529L9.16669 5.79567V11.229H7.83335ZM4.50002 13.8957C4.13335 13.8957 3.81958 13.7652 3.55869 13.5043C3.2978 13.2434 3.16713 12.9294 3.16669 12.5623V10.5623H4.50002V12.5623H12.5V10.5623H13.8334V12.5623C13.8334 12.929 13.7029 13.243 13.442 13.5043C13.1811 13.7657 12.8671 13.8961 12.5 13.8957H4.50002Z"
									fill="#1B47C1"
								/>
							</svg>
							Upload Logo
						</>
					)}
				</button>

				<input type="file" style={{ display: "none" }} ref={inputRef} onChange={handelLogoUpload} />
			</div>
		</div>
	);
};

export default BusinessLogo;
