import React, { useEffect, useRef, useState } from "react";
import styles from "./Testing.module.scss";

const longText =
	"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Libero consequuntur, dolor, ratione ipsum necessitatibus minus ducimus facere ipsam, explicabo aliquid saepe alias doloremque quas minima laboriosam cupiditate exercitationem veritatis nisi accusantium itaque! Provident voluptatibus adipisci earum quae cumque fugiat at modi, unde rerum recusandae aliquam facilis est et doloribus exercitationem deleniti. Doloribus soluta officiis esse distinctio sapiente ducimus a autem nemo qui facere quia, pariatur dolores porro incidunt minus debitis aspernatur ea commodi itaque corrupti maiores aliquam perferendis. Vitae, soluta perspiciatis voluptatum cum autem eum voluptates perferendis quia ea magni eos enim tenetur officiis veritatis corporis expedita culpa hic ipsam illo, porro commodi minus dolore consequatur nobis! Excepturi blanditiis ducimus rem similique laudantium minus commodi eaque deserunt impedit ut cupiditate assumenda animi aperiam ipsam dolor dignissimos expedita saepe ullam at facilis magni, vot impedit tenetur. Fuga id dolor ullam deserunt dolores in quo assumenda culpa quae dolorum, ducimus maxime. Debitis, maxime tenetur sequi architecto numquam incidunt expedita repudiandae laborum laudantium corporis dolores molestiae quibusdam deleniti nisi porro mollitia ducimus doloremque cum! Obcaecati, velit fugit magni cumque rem vitae quidem dolorum dolores totam ad nam ipsam nobis optio nostrum ullam quibusdam suscipit, natus distinctio doloremque aut repellat voluptatum corrupti, exercitationem tempore! Qui earum rem soluta animi placeat vero quibusdam labore consequatur repellendus voluptatum architecto, aliquid esse blanditiis maiores amet debitis aperiam aliquam quo, nisi alias cum neque itaque totam ex! Obcaecati culpa sint veritatis libero maiores totam facilis, necessitatibus quo delectus, qui, doloremque suscipit!";

const Testing = () => {
	const containerRef = useRef(null);
	const [divContents, setDivContents] = useState([]);
	const [active, setActive] = useState(false);

	useEffect(() => {
		const len = longText.length;
		const arr=[]
		let count = 0;
		for (let i = 0; i < Math.ceil(len / 300); i++) {
			arr.push(longText.substring(count, count + 300));
			count += 300;
		}
		setDivContents(arr);
	}, [longText]);

	return (
		<div ref={containerRef} style={{ height: "100vh", overflowY: "scroll" }} className={styles.Testing}>
			<div className={styles.Wrap}>
				<div className={styles.Top} onClick={() => setActive(!active)}></div>
				{divContents.map((content, index) => (
					<div key={index} className={`${styles.Box} ${active ? styles.ac : styles.cl}`}>
						{content}
					</div>
				))}
				<div className={styles.Bottom}></div>
			</div>
		</div>
	);
};

export default Testing;
