import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { socket } from "../../App";
import { ReactComponent as EmojiBtnIcon } from "../../assets/icons/EmojiBtnIcon.svg";
import { ReactComponent as SendIcon } from "../../assets/icons/SendIcon.svg";
import LoveIcon from "../../assets/svg/LoveIcon.svg";
import backArrow from "../../assets/svg/backArrow.svg";
import downArrow from "../../assets/svg/downArrow.svg";
import axios from "../../components/Hooks/axios";
import styles from "./CommentContainer.module.scss";
import EmojiPicker from "emoji-picker-react";

const CommentContainer = ({ setShow, commentsData, setCommentsData, postId, data }) => {
	const { userId, user } = useSelector((state) => state.auth);

	const [commentText, setCommentText] = useState("");
	const [commentId, setCommentId] = useState("");
	const [replyMainId, setReplyMainId] = useState("");
	const [replyId, setReplyId] = useState("");
	const inputRef = useRef();
	const [openEmoji, setOpenEmoji] = useState(false);

	const fetchComments = () => {
		axios
			.get(`/post/${postId}/getcomments`)
			.then(({ data }) => {
				setCommentsData(data.comments);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(() => {
		fetchComments();
	}, []);

	const postComment = () => {
		let sd = {
			notification_from: userId,
			notification_to: data.userId._id,
			notification: {},
			title: "Comment",
			message: `${user.username} comment on your post`,
		};

		if (commentText === "") return toast.warn("Please write something !");
		const id = toast.loading("Comment Posting...");
		axios
			.post(`/post/${postId}/comment`, {
				userId,
				text: commentText,
			})
			.then(({ data }) => {
				toast.update(id, {
					render: data.message,
					type: "success",
					isLoading: false,
					autoClose: 2000,
				});
				setCommentsData(data.post.Comments);
				setCommentText("");
				socket.emit("notification", sd);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const postCommentOnComment = () => {
		if (commentText === "") return toast.warn("Please write something !");
		const id = toast.loading("Comment Posting...");
		axios
			.post(`/post/comment-on-comment?postId=${postId}&commentId=${commentId}&userId=${userId}`, {
				text: commentText,
			})
			.then(({ data }) => {
				toast.update(id, {
					render: data.message,
					type: "success",
					isLoading: false,
					autoClose: 2000,
				});
				setCommentText("");
				fetchComments();
				setCommentId("");
				inputRef?.current?.blur();
			})
			.catch(({ response: { data } }) => {
				toast.update(id, {
					render: data.message,
					type: "error",
					isLoading: false,
					autoClose: 2000,
				});
			});
	};

	const postReplyOnReply = () => {
		if (commentText === "") return toast.warn("Please write something !");
		const id = toast.loading("Comment Posting...");
		axios
			.post(
				`/post/comment-on-comment?postId=${postId}&commentId=${replyMainId}&userId=${userId}&replyCommentId=${replyId}`,
				{
					text: commentText,
				}
			)
			.then(({ data }) => {
				toast.update(id, {
					render: data.message,
					type: "success",
					isLoading: false,
					autoClose: 2000,
				});
				setCommentText("");
				fetchComments();
				setReplyMainId("");
				setReplyId("");
				inputRef?.current?.blur();
			})
			.catch(({ response: { data } }) => {
				toast.update(id, {
					render: data.message,
					type: "error",
					isLoading: false,
					autoClose: 2000,
				});
			});
	};

	const commentLike = (commentId) => {
		const raw = {
			postId,
			commentId,
			userId,
		};
		axios
			.post(`/post/like-comment`, raw)
			.then(({ data }) => {
				toast.success(data.message);
				setCommentsData(data.comments);
			})
			.catch(({ response }) => {
				toast.info("You already like this comment !");
			});
	};

	const ReplySubCommentBox = ({ data }) => {
		const date = moment(data?.timestamp).format("hh:mmA [on] DD-MM-YY");

		return (
			<div className={styles.ReplySubCommentBox}>
				<div className={styles.left}>
					<div className={styles.leftImage}>
						<img src={data?.userId?.ProfilePic} alt="user image" />
					</div>

					<div className={styles.leftText}>
						<p>{data?.userId?.username}</p>

						<h4>{data?.text}</h4>

						<div>
							<span>{date}</span>
							<span onClick={() => commentLike(data._id)}>{data.userId._id === userId ? "Love Back" : "Love"}</span>
						</div>
					</div>
				</div>

				<div className={styles.right}>
					<div className={styles.right}>
						{data.likes.length > 0 && (
							<>
								<p>{data.likes.length}+</p>
								<img src={LoveIcon} alt="" />
							</>
						)}

						{/* <img src={downArrow} alt="" /> */}
					</div>
				</div>
			</div>
		);
	};

	const SubCommentBox = ({ data, mainCommentId }) => {
		const date = moment(data?.timestamp).format("hh:mmA [on] DD-MM-YY");
		return (
			<div className={styles.SubCommentBox}>
				<div className={styles.left}>
					<div className={styles.leftImage}>
						<img src={data?.userId?.ProfilePic} alt="user image" />
					</div>

					<div className={styles.leftText}>
						<p>{data?.userId?.username}</p>

						<h4>{data?.text}</h4>

						<div>
							<span>{date}</span>
							<span
								style={{
									background: data._id === replyId ? "#3A0DBB" : "",
									color: data._id === replyId ? "#fff" : "",
								}}
								onClick={() => {
									if (data?._id === replyId) {
										setReplyMainId("");
										setReplyId("");
										setCommentId("");
									} else {
										setReplyMainId(mainCommentId);
										setReplyId(data._id);
										setCommentId("");
									}
								}}>
								Reply
							</span>

							<span onClick={() => commentLike(data._id)}>{data.userId._id === userId ? "Love Back" : "Love"}</span>
						</div>
					</div>
				</div>

				<div className={styles.right}>
					{data.likes.length > 0 && (
						<>
							<p>{data.likes.length}+</p>
							<img src={LoveIcon} alt="" />
						</>
					)}

					{/* <img src={downArrow} alt="" /> */}
				</div>
			</div>
		);
	};

	const CommentBox = ({ data }) => {
		return (
			<div className={styles.CommentBox}>
				<div className={styles.left}>
					<div className={styles.leftImage}>
						<img src={data?.userId?.ProfilePic} alt="user image" />
					</div>
					<div className={styles.leftText}>
						<p>{data?.userId?.username}</p>
						<h4>{data?.text}</h4>
						<div>
							<span>{data.likes.length}+ Loves</span>
							<span
								style={{
									background: data._id === commentId ? "#3A0DBB" : "",
									color: data._id === commentId ? "#fff" : "",
								}}
								onClick={() => {
									if (data?._id === commentId) {
										setCommentId("");
										setReplyMainId("");
										setReplyId("");
									} else {
										setCommentId(data._id);
										setReplyMainId("");
										setReplyId("");
									}
								}}>
								Reply
							</span>
							<span onClick={() => commentLike(data._id)}>{data.userId._id === userId ? "Love Back" : "Love"}</span>
						</div>
					</div>
				</div>

				<div className={styles.right}>
					{data.likes.length > 0 && (
						<>
							<p>{data.likes.length}+</p>
							<img src={LoveIcon} alt="" />
						</>
					)}
				</div>
			</div>
		);
	};

	useEffect(() => {
		if (commentId !== "") inputRef?.current?.focus();
		if (commentId === "") inputRef?.current?.blur();
	}, [commentId]);

	useEffect(() => {
		if (replyId !== "") inputRef?.current?.focus();
		if (replyId === "") inputRef?.current?.blur();
	}, [replyId]);

	return (
		<div className={styles.CommentMainContainer}>
			{/* <div className={styles.shadowContainer} /> */}
			<div className={styles.CommentInnerContainer}>
				<div className={styles.backBtnContainer}>
					<div className={styles.backArrow}>
						<img src={backArrow} alt="backArrow" onClick={() => setShow(false)} />
						<h3>Comments</h3>
					</div>
					<div className={styles.downArrow}>
						<h3>Latest</h3>
						<img src={downArrow} alt="downArrow" />
					</div>
				</div>

				<div className={styles.CommentBoxContainer}>
					{commentsData.map((main, mi) => (
						<div key={mi} className={styles.MainCommentBox}>
							<CommentBox data={main} />

							{main?.Comments?.map((sub, si) => (
								<div key={si} className={styles.MainCommentBox}>
									<SubCommentBox data={sub} mainCommentId={main._id} />

									{sub?.Comments?.map((rep, ri) => (
										<ReplySubCommentBox key={ri} data={rep} />
									))}
								</div>
							))}
						</div>
					))}
				</div>

				<div className={styles.InputContainer}>
					<div className={styles.InputContainerInner}>
						<input
							type="text"
							value={commentText}
							ref={inputRef}
							onChange={(e) => setCommentText(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									if (commentId === "" && replyId === "") postComment();
									if (commentId !== "") postCommentOnComment();
									if (replyId !== "") postReplyOnReply();
									setOpenEmoji(false);
								}
							}}
						/>
						<button className={styles.EmojiBtn} onClick={() => setOpenEmoji(!openEmoji)}>
							<EmojiBtnIcon />
						</button>
					</div>

					<button
						className={styles.SubmitBtn}
						onClick={() => {
							if (commentId === "" && replyId === "") postComment();
							if (commentId !== "") postCommentOnComment();
							if (replyId !== "") postReplyOnReply();
							setOpenEmoji(false);
						}}>
						<SendIcon />
					</button>

					{openEmoji && (
						<div className={styles.EmojiContainer}>
							<EmojiPicker
								height="100%"
								width="100%"
								onEmojiClick={(e) => setCommentText((prevText) => prevText + e.emoji)}
								emojiStyle={"apple"}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CommentContainer;
