import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import Chart from "react-apexcharts";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../../assets/icons/ArrowRight.svg";
import { ReactComponent as CaretLeft } from "../../../assets/icons/CaretLeft.svg";
import { ReactComponent as ChartIcon } from "../../../assets/icons/Chart.svg";
import { ReactComponent as Curser } from "../../../assets/icons/Curser.svg";
import { ReactComponent as People } from "../../../assets/icons/People.svg";
import { ReactComponent as Report } from "../../../assets/icons/Report.svg";
import { ReactComponent as StarIcon } from "../../../assets/icons/StarIcon.svg";
import Header from "../../../components/Header/Header";
import ScrollToTop from "../../../components/Hooks/ScrollToTop";
import styles from "./Ads.module.scss";
import AdsDetailPopup from "./AdsDetailPopup/AdsDetailPopup";
import ClickReviewPopup from "./ClickReviewPopup/ClickReviewPopup";
import LiveReviewPopup from "./LiveReviewPopup/LiveReviewPopup";

const Ads = () => {
	const navigate = useNavigate();
	const [isClickReviewPopup, setIsClickReviewPopup] = useState(false);
	const [isLiveReviewPopup, setIsLiveReviewPopup] = useState(false);
	const [isAdsDetailPopup, setIsAdsDetailPopup] = useState(false);

	return (
		<div className="App">
			<div className="MainContainer">
				<ScrollToTop />
				<Header />
				{isClickReviewPopup && <ClickReviewPopup {...{ setIsClickReviewPopup }} />}
				{isLiveReviewPopup && <LiveReviewPopup {...{ setIsLiveReviewPopup }} />}
				{isAdsDetailPopup && <AdsDetailPopup {...{ setIsAdsDetailPopup }} />}

				<div className={`${styles.Container} ${styles.ADS}`}>
					<div className={styles.Row1}>
						<div className={styles.Left}>
							<h1>Running ads</h1>
							<p>Professional dashboard of Running Ads </p>
						</div>

						<div className={styles.Right} onClick={() => navigate(-1)}>
							<CaretLeft />
						</div>
					</div>

					<div className={styles.Row2}>
						<div className={styles.Col}>
							<div className={styles.Left} style={{ background: "rgba(27, 71, 193, 0.15)" }}>
								<Curser />
							</div>

							<div className={styles.Right}>
								<p>This Month</p>
								<h2>60,000</h2>
								<p>Total Clicks</p>
							</div>
						</div>

						<div className={styles.Col}>
							<div className={styles.Left} style={{ background: "rgba(127, 74, 230, 0.20)" }}>
								<People />
							</div>

							<div className={styles.Right}>
								<p>This Month</p>
								<h2>60,000</h2>
								<p>Total Visitors</p>
							</div>
						</div>

						<div className={styles.Col}>
							<div className={styles.Left} style={{ background: "rgba(50, 212, 105, 0.20)" }}>
								<ChartIcon />
							</div>

							<div className={styles.Right}>
								<p>This Month</p>
								<h2>60,000</h2>
								<p>Total Revenue</p>
							</div>
						</div>

						<div className={styles.Col}>
							<div className={styles.Left} style={{ background: "rgba(255, 128, 128, 0.17)" }}>
								<Report />
							</div>

							<div className={styles.Right}>
								<p>This Month</p>
								<h2>60,000</h2>
								<p>Total Report</p>
							</div>
						</div>
					</div>

					<div className={styles.Row3}>
						<div className={styles.Col1}>
							<div className={styles.Top}>
								<h2>Visitors Reviews </h2>

								<div className={styles.Right}>
									<input type="month" />

									<select>
										<option value="">Basic Ad</option>
										<option value="">Standard Ad</option>
										<option value="">Premium Ad</option>
									</select>
								</div>
							</div>

							<div className={styles.Bottom}>
								<Chart
									options={{
										yaxis: {
											labels: {
												formatter: function (value) {
													return `${Math.floor(value / 3600)}h ${Math.floor((value % 3600) / 60)}m`;
												},
												style: {
													colors: "#CBD5E0",
												},
											},
										},
										xaxis: {
											categories: [1, 2, 3, 4],
											tickPlacement: "between",
											labels: {
												formatter: function (value) {
													const year = Math.floor(value / 100);
													const month = value % 100;
													const dateObject = new Date(year, month - 1, 1);
													const formattedDate = dateObject.toLocaleString("en-US", {
														month: "short",
														year: "2-digit",
													});
													return formattedDate;
												},
												style: {
													colors: "#CBD5E0",
												},
											},
										},
										chart: {
											toolbar: {
												show: false,
											},
										},
										legend: {
											show: false,
											labels: {
												colors: "#fff",
											},
										},
										stroke: {
											show: true,
											curve: "smooth",
											lineCap: "butt",
											colors: undefined,
											width: 3,
											dashArray: 0,
										},
										dataLabels: {
											enabled: false,
										},
										grid: {
											borderColor: "#56577A",
											strokeDashArray: 4,
										},
									}}
									series={[
										{
											type: "line",
											name: "Month",
											color: "#0075FF",
											data: [10, 2, 50],
										},
										{
											type: "line",
											name: "Revenue",
											color: "#EE2CFF",
											data: [150, 50, 100, 50],
										},
									]}
									width="100%"
									height="100%"
								/>
							</div>
						</div>

						<div className={styles.Col2}>
							<div className={styles.Top}>
								<h2>Clicks Reviews </h2>

								<div className={styles.Right}>
									<input type="month" />

									<select>
										<option value="">Basic Ad</option>
										<option value="">Standard Ad</option>
										<option value="">Premium Ad</option>
									</select>
								</div>
							</div>

							<div className={styles.Mid}>
								<div className={styles.Left}>
									<Chart
										options={{
											yaxis: {
												labels: {
													formatter: function (value) {
														return `${Math.floor(value / 3600)}h ${Math.floor((value % 3600) / 60)}m`;
													},
													style: {
														colors: "#CBD5E0",
													},
												},
											},
											xaxis: {
												categories: [1, 2, 3],
												tickPlacement: "between",
												labels: {
													formatter: function (value) {
														const year = Math.floor(value / 100);
														const month = value % 100;
														const dateObject = new Date(year, month - 1, 1);
														const formattedDate = dateObject.toLocaleString("en-US", {
															month: "short",
															year: "2-digit",
														});
														return formattedDate;
													},
													style: {
														colors: "#CBD5E0",
													},
												},
											},
											chart: {
												toolbar: {
													show: false,
												},
											},
											legend: {
												show: false,
												labels: {
													colors: "#fff",
												},
											},
											stroke: {
												show: true,
												curve: "smooth",
												lineCap: "butt",
												colors: undefined,
												width: 3,
												dashArray: 0,
											},
											dataLabels: {
												enabled: false,
											},
											grid: {
												borderColor: "#56577A",
												strokeDashArray: 4,
											},
										}}
										series={[1, 2, 3]}
										type="donut"
										width="100%"
										height="100%"
									/>
								</div>

								<div className={styles.Right}>
									<div className={styles.TextWrapper}>
										<span style={{ background: "rgba(27, 71, 193, 1)" }} />

										<div className={styles.Text}>
											<p>Total Visitors</p>
											<h5>320 users</h5>
										</div>
									</div>

									<div className={styles.TextWrapper}>
										<span style={{ background: "rgba(127, 74, 230, 1)" }} />

										<div className={styles.Text}>
											<p>Total Visitors</p>
											<h5>320 users</h5>
										</div>
									</div>

									<div className={styles.TextWrapper}>
										<span style={{ background: "rgba(255, 128, 128, 1)" }} />

										<div className={styles.Text}>
											<p>Total Visitors</p>
											<h5>320 users</h5>
										</div>
									</div>
								</div>
							</div>

							<div className={styles.Bottom}>
								<button onClick={() => setIsClickReviewPopup(true)}>
									View More <ArrowRight />
								</button>
							</div>
						</div>
					</div>

					<div className={styles.Row4}>
						<div className={styles.Col1}>
							<div className={styles.Top}>
								<h2>Weekly ads Report</h2>

								<input type="month" />

								<select>
									<option value="">Week: 1st</option>
									<option value="">Week: 2nd</option>
									<option value="">Week: 3rd</option>
									<option value="">Week: 4th</option>
								</select>

								<select>
									<option value="">Basic Ad</option>
									<option value="">Standard Ad</option>
									<option value="">Premium Ad</option>
								</select>
							</div>

							<div className={styles.Bottom}>
								<Chart
									options={{
										yaxis: {
											labels: {
												formatter: function (value) {
													return `${Math.floor(value / 3600)}h ${Math.floor((value % 3600) / 60)}m`;
												},
												style: {
													colors: "#CBD5E0",
												},
											},
										},
										xaxis: {
											categories: [1, 2, 3],
											tickPlacement: "between",
											labels: {
												formatter: function (value) {
													const year = Math.floor(value / 100);
													const month = value % 100;
													const dateObject = new Date(year, month - 1, 1);
													const formattedDate = dateObject.toLocaleString("en-US", {
														month: "short",
														year: "2-digit",
													});
													return formattedDate;
												},
												style: {
													colors: "#CBD5E0",
												},
											},
										},
										chart: {
											toolbar: {
												show: false,
											},
											stacked: true,
										},
										legend: {
											show: true,
											labels: {
												colors: "#fff",
											},
										},
										stroke: {
											show: true,
											curve: "smooth",
											lineCap: "butt",
											colors: undefined,
											width: 3,
											dashArray: 0,
										},
										dataLabels: {
											enabled: false,
										},
										grid: {
											borderColor: "#56577A",
											strokeDashArray: 4,
										},
									}}
									series={[
										{
											name: "Month",
											color: "#0075FF",
											data: [1, 2, 3],
										},
										{
											name: "Month",
											color: "#0fF",
											data: [3, 2.5, 1],
										},
									]}
									type="bar"
									width="100%"
									height="100%"
								/>
							</div>
						</div>

						<div className={styles.Col2} onClick={() => setIsLiveReviewPopup(true)}>
							<div className={styles.Top}>
								<h2>Today live Reviews</h2>

								<div className={styles.Right}>
									<select>
										<option value="">Basic Ad</option>
										<option value="">Standard Ad</option>
										<option value="">Premium Ad</option>
									</select>
								</div>
							</div>

							<div className={styles.Mid}>
								<Chart
									options={{
										yaxis: {
											labels: {
												formatter: function (value) {
													return `${Math.floor(value / 3600)}h ${Math.floor((value % 3600) / 60)}m`;
												},
												style: {
													colors: "#CBD5E0",
												},
											},
										},
										xaxis: {
											categories: [1, 2, 3],
											tickPlacement: "between",
											labels: {
												formatter: function (value) {
													const year = Math.floor(value / 100);
													const month = value % 100;
													const dateObject = new Date(year, month - 1, 1);
													const formattedDate = dateObject.toLocaleString("en-US", {
														month: "short",
														year: "2-digit",
													});
													return formattedDate;
												},
												style: {
													colors: "#CBD5E0",
												},
											},
										},
										chart: {
											toolbar: {
												show: false,
											},
										},
										legend: {
											show: true,
											labels: {
												colors: "#fff",
											},
										},
										stroke: {
											show: true,
											curve: "smooth",
											lineCap: "butt",
											colors: undefined,
											width: 3,
											dashArray: 0,
										},
										dataLabels: {
											enabled: false,
										},
										grid: {
											borderColor: "#56577A",
											strokeDashArray: 4,
										},
									}}
									series={[
										{
											type: "line",
											name: "Month",
											color: "#0075FF",
											data: [1, 2, 3],
										},
										{
											type: "line",
											name: "Revenue",
											color: "#EE2CFF",
											data: [1, 2, 3],
										},
									]}
									width="100%"
									height="100%"
								/>
							</div>
						</div>

						<div className={styles.Col3}>
							<div className={styles.Top}>
								<h1>Reviews</h1>

								<Link to="/UsersReviews">
									View All <ArrowRight />
								</Link>
							</div>

							<AliceCarousel mouseTracking disableButtonsControls>
								{Array(5)
									.fill("")
									.map((data, index) => (
										<div key={index} className={styles.Box} onDragStart={(e) => e.preventDefault()}>
											<h2>So Comfortable to wear and Nice Quality</h2>
											<p>
												Wore these with my training tee and pods to a graduation bbq and the young bucks
												was all over it. Love the look and feel.
											</p>

											<div className={styles.StarWrapper}>
												<StarIcon />
												<StarIcon />
												<StarIcon />
												<StarIcon />
												<StarIcon />
											</div>

											<hr />

											<div className={styles.Profile}>
												<img src="https://picsum.photos/500/500" alt="" />

												<div>
													<h4>Rohan</h4>
													<h5>@Rohan</h5>
												</div>
											</div>
										</div>
									))}
							</AliceCarousel>
						</div>
					</div>

					<div className={styles.Row5}>
						<div className={styles.Top}>
							<h1>Your Ads</h1>
							<Link to="/YourAds">
								View All <ArrowRight />
							</Link>
						</div>

						<div className={styles.Bottom}>
							<div className={styles.Col1} onClick={() => setIsAdsDetailPopup(true)}>
								<div className={styles.Box}>
									<h1>Basic Ad</h1>

									<p>For companies that need to manage work happening across multiple teams.</p>

									<img src="https://picsum.photos/1500/500" alt="" />

									<h2 className={styles.Amount}>
										$300<span>/</span>
										<span>month</span>
									</h2>

									<div className={styles.Wrapper}>
										<h6>17 days Left</h6>
										<button>Expire on 12 Nov 2023</button>
									</div>
								</div>
							</div>

							<div className={styles.Col2} onClick={() => setIsAdsDetailPopup(true)}>
								<div className={styles.Box}>
									<h1>Basic Ad</h1>

									<p>For companies that need to manage work happening across multiple teams.</p>

									<img src="https://picsum.photos/1500/500" alt="" />

									<h2 className={styles.Amount}>
										$300<span>/</span>
										<span>month</span>
									</h2>

									<div className={styles.Wrapper2}>
										<h6>Expired </h6>
										<button>Re-apply</button>
									</div>
								</div>
							</div>

							<div className={styles.Col3} onClick={() => setIsAdsDetailPopup(true)}>
								<div className={styles.Box}>
									<h1>Basic Ad</h1>

									<p>For companies that need to manage work happening across multiple teams.</p>

									<img src="https://picsum.photos/1500/500" alt="" />

									<h2 className={styles.Amount}>
										$300<span>/</span>
										<span>month</span>
									</h2>

									<div className={styles.Wrapper}>
										<h6>17 days Left</h6>
										<button>Expire on 12 Nov 2023</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Ads;
