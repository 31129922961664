import useUserLoginValidate from "../../../components/Hooks/useUserLoginValidate";
import LoadingBg from "../Loading Bg/LoadingBg";
import classes from "./LoadingScreen.module.css";
import Floating from "./SignUp-SignIn/Floating";

const LoadingScreen = () => {
	useUserLoginValidate();

	return (
		<div className={classes.container}>
			<LoadingBg className={classes.bg} />
			<div className={classes["hover-animation"]}>
				<Floating />
			</div>
		</div>
	);
};

export default LoadingScreen;
