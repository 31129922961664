import React, { useEffect, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import search from "../../assets/svg/search.svg";
import Header from "../../components/Header/Header";
import ScrollToTop from "../../components/Hooks/ScrollToTop";
import axios from "../../components/Hooks/axios";
import styles from "./FollowList.module.css";

const FollowList = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [SearchText1, setSearchText1] = useState("");
	const [SearchText2, setSearchText2] = useState("");
	const [SearchShow1, setSearchShow1] = useState(false);
	const [SearchShow2, setSearchShow2] = useState(false);
	const [followersData, setFollowersData] = useState([]);
	const [followingData, setFollowingData] = useState([]);
	const [reload, setReload] = useState(0);

	useEffect(() => {
		setIsLoading(true);
		axios
			.get("/auth/follower-following")
			.then(({ data }) => {
				if (data.success) {
					setFollowersData(data?.data?.followersData);
					setFollowingData(data?.data?.followingData);
					setIsLoading(false);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}, [reload]);

	const handelUnFollow = (id, name) => {
		const raw = JSON.stringify({
			following: id,
		});
		axios
			.post(`/auth/follow`, raw, {
				headers: { "Content-Type": "application/json" },
			})
			.then(({ data }) => {
				toast.success(`You unfollow ${name}`);
				setReload(Math.random());
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const handelRemoveUser = (id, name) => {
		toast.info(`Remove API Pending`);
	}

	return (
		<div className="App">
			<div className="MainContainer">
				<ScrollToTop />
				<Header />
				<div className={`${styles.Container} ${styles.FollowList}`}>
					<div className={styles.LeftFollowListContainer}>
						<div className={styles.SearchContainer}>
							<h4>Followers</h4>
							<div className={`${styles.search} ${SearchShow2 ? styles.ActiveSearch : ""}`}>
								<input
									type="search"
									placeholder="Search"
									value={SearchText2}
									onChange={(e) => setSearchText2(e.target.value)}
								/>
								<img src={search} alt="search" onClick={() => setSearchShow2(!SearchShow2)} />
							</div>
						</div>

						<div className={styles.ListContainer}>
							{isLoading ? (
								<div className={styles.loadingContainer}>
									<FallingLines color="#3A0DBB" width="100" visible={true} ariaLabel="falling-lines-loading" />
								</div>
							) : (
								followersData.map((data, index) => (
									<div key={index} className={styles.FollowBox}>
										<div className={styles.FollowLeft}>
											<img src={data?.ProfilePic} alt="ProfilePic" />
											<div>
												<h5>{data?.username}</h5>
												<p>@{data?.GetstatedName}</p>
											</div>
										</div>
										<button type="button" onClick={() => handelRemoveUser(data._id, data.username)}>
											Remove
										</button>
									</div>
								))
							)}
						</div>
					</div>

					<div className={styles.RightFollowListContainer}>
						<div className={styles.SearchContainer}>
							<h4>Following</h4>
							<div className={`${styles.search} ${SearchShow1 ? styles.ActiveSearch : ""}`}>
								<input
									type="search"
									placeholder="Search"
									value={SearchText1}
									onChange={(e) => setSearchText1(e.target.value)}
								/>
								<img src={search} alt="search" onClick={() => setSearchShow1(!SearchShow1)} />
							</div>
						</div>

						<div className={styles.ListContainer}>
							{isLoading ? (
								<div className={styles.loadingContainer}>
									<FallingLines color="#3A0DBB" width="100" visible={true} ariaLabel="falling-lines-loading" />
								</div>
							) : (
								followingData.map((data, index) => (
									<div key={index} className={styles.FollowBox}>
										<div className={styles.FollowLeft}>
											<img src={data?.ProfilePic} alt="ProfilePic" />
											<div>
												<h5>{data?.username}</h5>
												<p>@{data?.GetstatedName}</p>
											</div>
										</div>
										<button type="button" onClick={() => handelUnFollow(data._id, data.username)}>
											UnFollow
										</button>
									</div>
								))
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FollowList;
