import React from "react";

const RightArrow = () => {
	return (
		<svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.64523 7.58597L0.410156 2.3509L1.90559 0.855469L8.63609 7.58597L1.90559 14.3165L0.410156 12.821L5.64523 7.58597Z"
				fill="black"
			/>
		</svg>
	);
};

export default RightArrow;
