import React from "react";
import Chart from "react-apexcharts";
import styles from "./ClickReviewPopup.module.scss";

const ClickReviewPopup = ({ setIsClickReviewPopup }) => {
	return (
		<div className={styles.ClickReviewWrapper} onClick={() => setIsClickReviewPopup(false)}>
			<div className={styles.ClickReview} onClick={(e) => e.stopPropagation()}>
				<div className={styles.Row1}>
					<div className={styles.Top}>
						<h2>Clicks Reviews </h2>

						<div className={styles.Right}>
							<input type="month" />

							<select>
								<option value="">Basic Ad</option>
								<option value="">Standard Ad</option>
								<option value="">Premium Ad</option>
							</select>
						</div>
					</div>

					<div className={styles.Mid}>
						<div className={styles.Left}>
							<Chart
								options={{
									yaxis: {
										labels: {
											formatter: function (value) {
												return `${Math.floor(value / 3600)}h ${Math.floor((value % 3600) / 60)}m`;
											},
											style: {
												colors: "#CBD5E0",
											},
										},
									},
									xaxis: {
										categories: [1, 2, 3],
										tickPlacement: "between",
										labels: {
											formatter: function (value) {
												const year = Math.floor(value / 100);
												const month = value % 100;
												const dateObject = new Date(year, month - 1, 1);
												const formattedDate = dateObject.toLocaleString("en-US", {
													month: "short",
													year: "2-digit",
												});
												return formattedDate;
											},
											style: {
												colors: "#CBD5E0",
											},
										},
									},
									chart: {
										toolbar: {
											show: false,
										},
									},
									legend: {
										show: false,
										labels: {
											colors: "#fff",
										},
									},
									stroke: {
										show: true,
										curve: "smooth",
										lineCap: "butt",
										colors: undefined,
										width: 3,
										dashArray: 0,
									},
									dataLabels: {
										enabled: false,
									},
									grid: {
										borderColor: "#56577A",
										strokeDashArray: 4,
									},
								}}
								series={[1, 2, 3]}
								type="donut"
								width="100%"
								height="100%"
							/>
						</div>

						<div className={styles.Right}>
							<div className={styles.TextWrapper}>
								<span style={{ background: "rgba(27, 71, 193, 1)" }} />

								<div className={styles.Text}>
									<p>Total Visitors</p>
									<h5>320 users</h5>
								</div>
							</div>

							<div className={styles.TextWrapper}>
								<span style={{ background: "rgba(127, 74, 230, 1)" }} />

								<div className={styles.Text}>
									<p>Total Visitors</p>
									<h5>320 users</h5>
								</div>
							</div>

							<div className={styles.TextWrapper}>
								<span style={{ background: "rgba(255, 128, 128, 1)" }} />

								<div className={styles.Text}>
									<p>Total Visitors</p>
									<h5>320 users</h5>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className={styles.Row2}>
					<button className={styles.Active}>Clicked Users</button>

					<button>Viewers</button>

					<button>Report</button>
				</div>

				<div className={styles.Row3}>
					{Array(10)
						.fill("")
						.map((data, index) => (
							<div key={index} className={styles.Box}>
								<div className={styles.Wrapper}>
									<img src="https://picsum.photos/500/500" alt="" />

									<div className={styles.Text}>
										<h3>Rohan Mondal</h3>
										<p>@Rohan_Mondal</p>
									</div>
								</div>

								<button>View Profile</button>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export default ClickReviewPopup;
