import React, { useEffect } from "react";

const Page404 = () => {
	useEffect(() => {
		document.title = "404 - Page Not Found";

		// Set custom meta tags
		const metaErrorPage = document.createElement("meta");
		metaErrorPage.name = "errorpage";
		metaErrorPage.content = "true";
		document.head.appendChild(metaErrorPage);

		const metaErrorType = document.createElement("meta");
		metaErrorType.name = "errortype";
		metaErrorType.content = "404 - Not Found";
		document.head.appendChild(metaErrorType);

		const metaPrerenderStatusCode = document.createElement("meta");
		metaPrerenderStatusCode.name = "prerender-status-code";
		metaPrerenderStatusCode.content = "404";
		document.head.appendChild(metaPrerenderStatusCode);

		const metaStatusCode = document.createElement("meta");
		metaStatusCode.name = "status-code";
		metaStatusCode.content = "404";
		document.head.appendChild(metaStatusCode);

		// Simulate setting the status code to 404 on the server
		if (window) {
			window.statusCode = 404;
		}

		// Cleanup on unmount
		return () => {
			document.head.removeChild(metaErrorPage);
			document.head.removeChild(metaErrorType);
			document.head.removeChild(metaPrerenderStatusCode);
			document.head.removeChild(metaStatusCode);
		};
	}, []);

	return (
		<div className="Page404">
			<h2>404</h2>
			<h1>Oops! Page not found.</h1>
			<h3>
				It seems like you're lost. Let's get you <a href="/">back home</a>.
			</h3>
			<style>{`
				* {
					padding: 0;
					margin: 0;
					box-sizing: border-box;
					font-family: "Poppins", sans-serif;
					scroll-behavior: smooth;
				}
				* ::selection {
					background: #020aff;
					color: #ffffff;
				}
				.Page404 {
					width: 100%;
					height: 100vh;
					overflow: hidden;
					display: flex;
					flex-direction: column;
					background: #000000;
					position: relative;
					scrollbar-width: none;
					align-items: center;
					justify-content: center;
					text-align: center;
				}
				.Page404::-webkit-scrollbar {
					display: none;
				}
				.Page404 h2 {
					font-size: 10rem;
					color: #333;
					margin-bottom: 2rem;
				}
				.Page404 h1, h3 {
					font-size: 2rem;
					color: #666;
					margin-bottom: 3rem;
				}
				.Page404 a {
					color: #020aff;
					text-decoration: none;
					font-weight: bold;
				}
				.Page404 a:hover {
					text-decoration: underline;
				}
			`}</style>
		</div>
	);
};

export default Page404;
