import React, { useEffect, useRef, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as EmojiBtnIcon } from "../../assets/icons/EmojiBtnIcon.svg";
import HeartFilled from "../../assets/svg/HeartFilled.svg";
import HeartUnfilled from "../../assets/svg/HeartUnfilled.svg";
import comment from "../../assets/svg/comment.svg";
import share from "../../assets/svg/share.svg";
import { setShareData } from "../../redux/slices/tempSlice";
import Header from "../Header/Header";
import ScrollToTop from "../Hooks/ScrollToTop";
import axios from "../Hooks/axios";
import styles from "./ViewStory.module.scss";
import EmojiPicker from "emoji-picker-react";

const ViewStory = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const { userId } = useSelector((state) => state.auth);
	const { state } = useLocation();

	// console.log(state);

	const [commentsData, setCommentsData] = useState([]);
	const [commentId, setCommentId] = useState("");
	const [commentText, setCommentText] = useState("");
	const [isLiked, setIsLiked] = useState(false);
	const [likeCount, setLikeCount] = useState(0);
	const inputRef = useRef();
	const [threeDotShow, setThreeDotShow] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	//Story
	const [storyUserData, setStoryUserData] = useState(null);
	const [storyData, setStoryData] = useState([]);
	const [activeIndex, setActiveIndex] = useState(0);
	const [isClicked, setIsClicked] = useState(true);
	const [last, setLast] = useState(false);

	// User Related
	const [activeUserId, setActiveUserId] = useState(id);
	const [userBtn, setUserBtn] = useState({ in: -1, ml: 0 });
	const [allStoryUser, setAllStoryUser] = useState([]);

	const [openEmoji, setOpenEmoji] = useState(false);

	useEffect(() => {
		if (userBtn.in === -1) setActiveUserId(id);
	}, [userBtn.in]);

	useEffect(() => {
		axios
			.get("/story/stories")
			.then(({ data }) => {
				const filterData = data.filter((item) => {
					if (item.userId._id !== id) return true;
				});
				setAllStoryUser(filterData);
			})
			.catch(({ response }) => {
				console.log(response);
			});
	}, []);

	useEffect(() => {
		setLast(false);
		setActiveIndex(0);
		setIsClicked(true);

		setIsLoading(true);
		axios
			.get(`/story/mystory/${activeUserId}`)
			.then(({ data }) => {
				setStoryUserData(data.data?.userId);
				setStoryData(data.data?.stories);
				setIsLoading(false);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [activeUserId]);

	useEffect(() => {
		setIsLoading(true);
		if (storyData.length > 0) {
			setCommentsData(storyData[activeIndex]?.Comments);
			setLikeCount(storyData[activeIndex]?.LikeCount);
			setIsLiked(storyData[activeIndex]?.isLike);
		}
		setIsLoading(false);
	}, [activeIndex]);

	useEffect(() => {
		if (storyData.length === 0) return;

		if (isClicked) {
			const si = setInterval(() => {
				if (activeIndex < storyData.length - 1) {
					let index = activeIndex;
					index += 1;
					setActiveIndex(index);
					setLast(false);
				}
			}, 5000);

			// setTimeout(() => {
			// 	clearInterval(si);
			// }, 5000 * storyData.length);

			return () => {
				clearInterval(si);
			};
		}
	}, [storyData, activeIndex, isClicked]);

	const fetchComments = () => {
		axios
			.get(`/story/get-comments/${storyData[activeIndex]?._id}`)
			.then(({ data }) => {
				setCommentsData(data.comments);
				setIsClicked(true);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const postComment = () => {
		if (commentText === "") return toast.warn("Please write something !");
		const id = toast.loading("Comment Posting...");
		axios
			.post(`story/comment/${storyData[activeIndex]?._id}`, {
				userId,
				text: commentText,
			})
			.then(({ data }) => {
				toast.update(id, {
					render: data.message,
					type: "success",
					isLoading: false,
					autoClose: 2000,
				});
				setCommentsData(data.stories.Comments);
				setCommentText("");
				setIsClicked(true);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const postCommentOnComment = () => {
		if (commentText === "") return toast.warn("Please write something !");
		const id = toast.loading("Comment Posting...");
		axios
			.post(`story/comment?storyId=${storyData[activeIndex]?._id}&&commentId=${commentId}&userId=${userId}`, {
				text: commentText,
			})
			.then(({ data }) => {
				toast.update(id, {
					render: data.message,
					type: "success",
					isLoading: false,
					autoClose: 2000,
				});
				setCommentText("");
				fetchComments();
				setCommentId("");
				inputRef?.current?.blur();
			})
			.catch(({ response: { data } }) => {
				toast.update(id, {
					render: data.message,
					type: "error",
					isLoading: false,
					autoClose: 2000,
				});
			});
	};

	const handelLike = () => {
		axios
			.post(`story/${storyData[activeIndex]?._id}/like`, {
				userId,
			})
			.then(({ data }) => {
				if (data.message === "story liked successfully") setIsLiked(true);
				if (data.message === "story unliked successfully") setIsLiked(false);
				setLikeCount(data.totalLikes);
			})
			.catch(({ response }) => {
				console.log(response);
			});
	};

	useEffect(() => {
		if (commentId !== "") inputRef?.current?.focus();
		if (commentId === "") inputRef?.current?.blur();
	}, [commentId]);

	let clickTimer;

	const handelMouseDown = () => {
		clickTimer = setTimeout(() => {
			setIsClicked(false);
		}, 800);
	};

	const handleMouseUp = () => {
		clearTimeout(clickTimer);
		setIsClicked(true);
	};

	return (
		<div className="App">
			<div className={`MainContainer ${styles.Container}`}>
				<ScrollToTop />
				<Header />

				<div className={styles.BackgroundImage}>
					{storyData[activeIndex]?.mediaType === "image" ? (
						<img src={storyData[activeIndex]?.mediaUrl} alt="PostImage" className={styles.BackgroundImage} />
					) : storyData[activeIndex]?.mediaType === "video" ? (
						<video className={styles.BackgroundImage} autoPlay muted loop></video>
					) : (
						""
					)}
					<div className={styles.Overlay}></div>
				</div>

				<div className={styles.ViewPost}>
					<div className={styles.Left}>
						<div className={styles.StoryIndicatorBar}>
							{storyData.map((_, index) => {
								if (activeIndex === storyData.length - 1)
									setTimeout(() => {
										setLast(true);
									}, 5000);
								return (
									<div className={styles.Bars} key={index} onClick={() => setActiveIndex(index)}>
										{index < activeIndex && <span className={styles.on}></span>}
										{index === activeIndex && !isClicked && (
											<span className={!isClicked ? styles.pause : ""}></span>
										)}
										{index === activeIndex && <span className={isClicked ? styles.active : ""}></span>}
										{index === activeIndex && activeIndex === storyData.length - 1 && last && (
											<span className={styles.on}></span>
										)}
									</div>
								);
							})}
						</div>

						<div className={styles.ImageContainer}>
							{isLoading ? (
								<FallingLines color="#3A0DBB" width="100" visible={true} ariaLabel="falling-lines-loading" />
							) : storyData[activeIndex]?.mediaType === "image" ? (
								<img src={storyData[activeIndex]?.mediaUrl} alt="" />
							) : storyData[activeIndex]?.mediaType === "video" ? (
								<video src={storyData[activeIndex]?.mediaUrl} controls autoPlay></video>
							) : (
								""
							)}
						</div>

						<div className={styles.OverFlowButton}>
							<button
								onClick={() => {
									if (activeIndex > 0) setActiveIndex(activeIndex - 1);
									setIsClicked(true);
								}}></button>
							<button onClick={() => setIsClicked(!isClicked)}></button>
							<button
								onClick={() => {
									if (activeIndex < storyData.length - 1) setActiveIndex(activeIndex + 1);
									setIsClicked(true);
								}}></button>
						</div>
					</div>

					<div className={styles.Right}>
						<div className={styles.RightTopContainer}>
							<div className={styles.CommentWrapper}>
								<div className={styles.UserContainer}>
									<div className={styles.ULeft}>
										<div className={styles.ImageContainer}>
											<img src={storyUserData?.ProfilePic} alt="" />
										</div>

										<div className={styles.TextWrapper}>
											<div className={styles.name}>{storyUserData?.username}</div>
											<div className={styles.UserName}>@{storyUserData?.GetstatedName}</div>
										</div>
									</div>

									<div className={styles.URight} onClick={() => setThreeDotShow(true)}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="36"
											height="37"
											viewBox="0 0 36 37"
											fill="none">
											<path
												d="M18.0577 5.30469C16.4211 5.30469 15.082 6.64375 15.082 8.28038C15.082 9.91701 16.4211 11.2561 18.0577 11.2561C19.6944 11.2561 21.0334 9.91701 21.0334 8.28038C21.0334 6.64375 19.6944 5.30469 18.0577 5.30469ZM18.0577 26.1346C16.4211 26.1346 15.082 27.4736 15.082 29.1102C15.082 30.7469 16.4211 32.0859 18.0577 32.0859C19.6944 32.0859 21.0334 30.7469 21.0334 29.1102C21.0334 27.4736 19.6944 26.1346 18.0577 26.1346ZM18.0577 15.7196C16.4211 15.7196 15.082 17.0587 15.082 18.6953C15.082 20.3319 16.4211 21.671 18.0577 21.671C19.6944 21.671 21.0334 20.3319 21.0334 18.6953C21.0334 17.0587 19.6944 15.7196 18.0577 15.7196Z"
												fill="white"
											/>
										</svg>
									</div>
								</div>

								<div className={styles.ActionContainer}>
									<div className={styles.Like} onClick={handelLike}>
										{isLiked ? <img src={HeartFilled} alt="" /> : <img src={HeartUnfilled} alt="" />}
										<p>{likeCount}</p>
									</div>

									<div className={styles.Comment} onClick={() => {}}>
										<img src={comment} alt="" />
										<p>{commentsData?.length}</p>
									</div>

									{/* <RWebShare
										data={{
											text: "Emagz Story",
											url: "/Story/" + storyUserData?._id,
											title: "Emagz",
										}}
										onClick={() => console.log("shared successfully!")}> */}
									<div
										className={styles.Share}
										onClick={() =>
											dispatch(setShareData({ show: true, endPoint: "/Story/", id: storyUserData._id }))
										}>
										<img src={share} alt="" />
									</div>
									{/* </RWebShare> */}
								</div>

								<div className={styles.CommentContainer}>
									<div className={styles.TopBar}>
										<div className={styles.TopLeft}>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="15"
												height="15"
												viewBox="0 0 15 15"
												fill="none">
												<path
													d="M4.12236 6.40062L8.86776 1.65523L7.61683 0.404297L0.735823 7.2853L7.61683 14.1663L8.86776 12.9154L4.12236 8.16997H14.8906V6.40062H4.12236Z"
													fill="white"
												/>
											</svg>
											<p>Comments</p>
										</div>
										<div className={styles.TopRight}>
											<p>Latest</p>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="23"
												height="22"
												viewBox="0 0 23 22"
												fill="none">
												<path
													d="M11.5603 13.9421L7.80664 10.1884L9.05846 8.9375L11.5603 11.4402L14.0622 8.9375L15.314 10.1884L11.5603 13.9421Z"
													fill="white"
												/>
											</svg>
										</div>
									</div>

									<div className={styles.BottomBar}>
										{isLoading ? (
											<FallingLines
												color="#3A0DBB"
												width="50"
												visible={true}
												ariaLabel="falling-lines-loading"
											/>
										) : (
											[...commentsData].reverse()?.map((data, index) => {
												return (
													<div key={index} className={styles.CommentContainerWrapper}>
														<div className={styles.CommentBox}>
															<div className={styles.CBLeft}>
																<div className={styles.ImageWrapper}>
																	<img src={data?.userId?.ProfilePic} alt="" />
																</div>

																<div className={styles.TextWrapper}>
																	<div className={styles.Name}>{data?.userId?.username}</div>
																	<div className={styles.Text}>{data.text}</div>
																	<div className={styles.ButtonContainer}>
																		<button>3+ love</button>
																		<button
																			style={{
																				background:
																					data._id === commentId ? "#3A0DBB" : "",
																				color: data._id === commentId ? "#fff" : "",
																			}}
																			onClick={() => {
																				if (data?._id === commentId) {
																					setCommentId("");
																				} else {
																					setCommentId(data._id);
																				}
																			}}>
																			Reply
																		</button>
																		<button>Love Back</button>
																	</div>
																</div>
															</div>

															<div className={styles.CBRight}>
																<p>3+</p>
																<div className={styles.Round}>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="8"
																		height="8"
																		viewBox="0 0 8 8"
																		fill="none">
																		<g filter="url(#filter0_di_1770_8368)">
																			<path
																				fillRule="evenodd"
																				clipRule="evenodd"
																				d="M1.065 3.76323C1.12893 3.85229 1.20338 3.93362 1.2866 4.0055L3.65697 6.59705L5.88441 3.67472H5.86151C5.98602 3.46952 6.0575 3.23005 6.0575 2.97429C6.0575 2.214 5.4259 1.59766 4.64678 1.59766C4.13112 1.59766 3.68008 1.86765 3.434 2.27068C3.18792 1.86768 2.7369 1.59771 2.22126 1.59771C1.44214 1.59771 0.810547 2.21405 0.810547 2.97435C0.810547 3.23009 0.882006 3.46953 1.0065 3.67472H0.984043L1.065 3.76323Z"
																				fill="url(#paint0_radial_1770_8368)"
																			/>
																		</g>
																		<path
																			d="M3.48963 5.99525C2.82965 5.31052 1.71057 3.78887 1.50968 3.47082C1.21267 3.00058 1.55918 2.01061 2.49965 2.23335C1.97168 2.43959 1.43051 3.48072 3.48963 5.99525Z"
																			fill="white"
																		/>
																		<path
																			d="M3.48963 5.99525C2.82965 5.31052 1.71057 3.78887 1.50968 3.47082C1.21267 3.00058 1.55918 2.01061 2.49965 2.23335C1.97168 2.43959 1.43051 3.48072 3.48963 5.99525Z"
																			stroke="white"
																			strokeWidth="0.0494987"
																		/>
																		<defs>
																			<filter
																				id="filter0_di_1770_8368"
																				x="0.0185681"
																				y="0.805677"
																				width="7.22604"
																				height="6.97995"
																				filterUnits="userSpaceOnUse"
																				colorInterpolationFilters="sRGB">
																				<feFlood
																					floodOpacity="0"
																					result="BackgroundImageFix"
																				/>
																				<feColorMatrix
																					in="SourceAlpha"
																					type="matrix"
																					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
																					result="hardAlpha"
																				/>
																				<feOffset dx="0.197995" dy="0.197995" />
																				<feGaussianBlur stdDeviation="0.494987" />
																				<feComposite in2="hardAlpha" operator="out" />
																				<feColorMatrix
																					type="matrix"
																					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
																				/>
																				<feBlend
																					mode="normal"
																					in2="BackgroundImageFix"
																					result="effect1_dropShadow_1770_8368"
																				/>
																				<feBlend
																					mode="normal"
																					in="SourceGraphic"
																					in2="effect1_dropShadow_1770_8368"
																					result="shape"
																				/>
																				<feColorMatrix
																					in="SourceAlpha"
																					type="matrix"
																					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
																					result="hardAlpha"
																				/>
																				<feOffset dx="-0.197995" dy="-0.247493" />
																				<feGaussianBlur stdDeviation="0.247493" />
																				<feComposite
																					in2="hardAlpha"
																					operator="arithmetic"
																					k2="-1"
																					k3="1"
																				/>
																				<feColorMatrix
																					type="matrix"
																					values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
																				/>
																				<feBlend
																					mode="normal"
																					in2="shape"
																					result="effect2_innerShadow_1770_8368"
																				/>
																			</filter>
																			<radialGradient
																				id="paint0_radial_1770_8368"
																				cx="0"
																				cy="0"
																				r="1"
																				gradientUnits="userSpaceOnUse"
																				gradientTransform="translate(2.06981 2.84751) rotate(54.019) scale(4.51002 4.5828)">
																				<stop stopColor="#5200FF" />
																				<stop offset="1" stopColor="#9610E8" />
																			</radialGradient>
																		</defs>
																	</svg>
																</div>
															</div>
														</div>

														{data.Comments.map((d, i) => (
															<div key={i} className={styles.SubCommentBoxWrapper}>
																<div className={styles.SubCommentBox}>
																	<div className={styles.CBLeft}>
																		<div className={styles.ImageWrapper}>
																			<img src={d?.userId?.ProfilePic} alt="" />
																		</div>

																		<div className={styles.TextWrapper}>
																			<div className={styles.Name}>
																				{d?.userId?.username}
																			</div>
																			<div className={styles.Text}>{d.text}</div>
																			<div className={styles.ButtonContainer}>
																				<button>3+ love</button>
																				<button>Love Back</button>
																			</div>
																		</div>
																	</div>

																	<div className={styles.CBRight}>
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			width="16"
																			height="16"
																			viewBox="0 0 16 16"
																			fill="none">
																			<path
																				d="M8.10518 10.2683L5.41797 7.58107L6.31413 6.68555L8.10518 8.47723L9.89623 6.68555L10.7924 7.58107L8.10518 10.2683Z"
																				fill="white"
																			/>
																		</svg>
																	</div>
																</div>
															</div>
														))}
													</div>
												);
											})
										)}
									</div>
								</div>

								<div className={styles.InputWrapper}>
									<div className={styles.InputContainer}>
										<input
											type="text"
											placeholder="Type your Comment"
											value={commentText}
											ref={inputRef}
											onClick={() => setIsClicked(false)}
											onChange={(e) => setCommentText(e.target.value)}
											onKeyDown={(e) => {
												if (e.key === "Enter") {
													if (commentId === "") postComment();
													else postCommentOnComment();
													setOpenEmoji(false);
												}
											}}
										/>

										<button className={styles.EmojiBtn} onClick={() => setOpenEmoji(!openEmoji)}>
											<EmojiBtnIcon />
										</button>
									</div>

									<button
										className={styles.SubmitBtn}
										onClick={() => {
											if (commentId === "") postComment();
											else postCommentOnComment();
											setOpenEmoji(false);
										}}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="39"
											height="35"
											viewBox="0 0 39 35"
											fill="none">
											<path
												d="M11.269 16.6507C11.4855 16.973 11.8483 17.1663 12.2365 17.1663L33.3589 17.1663C34.6212 17.1663 34.9828 15.4396 33.8266 14.9331L3.89698 1.82363C2.82872 1.35572 1.81153 2.57312 2.46189 3.54119L11.269 16.6507Z"
												fill="white"
											/>
											<path
												d="M11.269 18.4235C11.4855 18.1013 11.8483 17.908 12.2365 17.908L33.3589 17.908C34.6212 17.908 34.9828 19.6346 33.8266 20.1411L3.89698 33.2506C2.82872 33.7185 1.81153 32.5011 2.46189 31.533L11.269 18.4235Z"
												fill="white"
											/>
										</svg>
									</button>

									{openEmoji && (
										<div className={styles.EmojiContainer}>
											<EmojiPicker
												height="100%"
												width="100%"
												onEmojiClick={(e) => setCommentText((prevText) => prevText + e.emoji)}
												emojiStyle={"apple"}
											/>
										</div>
									)}
								</div>
							</div>
						</div>

						<div className={styles.RightBottomContainer}>
							<button
								onClick={() => {
									if (userBtn.in > -1) {
										setUserBtn({ in: userBtn.in - 1, ml: userBtn.ml + 20 });
										setActiveUserId(allStoryUser[userBtn.in - 1]?.userId?._id);
									}
									setIsClicked(false);
								}}>
								{"<"}
							</button>

							<div className={styles.SlideImages}>
								{allStoryUser.map((data, index) => {
									return (
										<div
											key={index}
											className={`${styles.ImageWrapper} ${userBtn.in + 1 > index ? styles.inActive : ""}`}
											style={{
												marginLeft: index === 0 ? `${userBtn.ml}%` : "",
												zIndex: storyData.length - index,
											}}>
											{data.stories[0].mediaType === "image" && (
												<img src={data.stories[data.stories.length - 1].mediaUrl} alt="" />
											)}
											{data.stories[0].mediaType === "video" && (
												<video src={data.stories[data.stories.length - 1].mediaUrl} muted />
											)}
										</div>
									);
								})}
							</div>

							<button
								onClick={() => {
									if (userBtn.in < allStoryUser.length - 1) {
										setUserBtn({ in: userBtn.in + 1, ml: userBtn.ml - 20 });
										setActiveUserId(allStoryUser[userBtn.in + 1]?.userId?._id);
									}
									setIsClicked(false);
								}}>
								{">"}
							</button>
						</div>
					</div>

					{threeDotShow && (
						<div className={styles.ThreeDotWrapper} onClick={() => setThreeDotShow(false)}>
							<div className={styles.ButtonWrapper} onClick={(e) => e.stopPropagation()}></div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ViewStory;
// slice(userBtn.s, userBtn.e).
