import React, { useState } from "react";
import styles from "./Support.module.scss";
import axios from "../../components/Hooks/axios";
import { toast } from "react-toastify";

const TitlePopup = ({ setTitlePopup, setReload }) => {
	const [titleText, setTitleText] = useState("");

    const handelTitleSubmit = () => {
        axios
			.post("/ticket/create", { ticketTitle: titleText })
            .then(({ data }) => {
                if (data.status) {
                    setReload(Math.random());
                    setTitlePopup(false);
                    setTitleText("");
                    toast.success(data.message);
                }
			})
			.catch((e) => console.log(e));

	};

	return (
		<div className={styles.TitlePopupWrapper} onClick={() => setTitlePopup(false)}>
			<div className={styles.TitlePopup} onClick={(e) => e.stopPropagation()}>
				<input type="text" value={titleText} onChange={(e) => setTitleText(e.target.value)} />
				<button onClick={handelTitleSubmit}>Submit</button>
			</div>
		</div>
	);
};

export default TitlePopup;
