import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { socket } from "../../App";
import styles from "./Support.module.scss";

const ChatPopup = ({ setChatPopup, activeTicket }) => {
	const { userId } = useSelector((s) => s.auth);
	const [text, setText] = useState("");
	const [chat, setChat] = useState([]);
	// console.log(activeTicket)

	useEffect(() => {
		socket.emit(`joinRoom`, activeTicket?._id);
		socket.on(`chatHistory`, (payload) => {
			setChat(payload);
		});
		return () => socket.emit("leaveRoom", activeTicket?._id);
	}, [socket, activeTicket]);

	useEffect(() => {
		socket.on(`support_receive_message_${activeTicket?._id}`, (payload) => {
			setChat((pre) => [...pre, payload]);
		});
		socket.on(`support_message_${activeTicket?._id}`, (payload) => {
			setChat((pre) => [...pre, payload]);
		});
	}, [socket, activeTicket]);

	const onSend = () => {
		if (text === "") return;
		let data = {
			room: activeTicket?._id,
			sender: userId,
			message: text,
		};
		socket.emit("support_chatMessage", data);
		setText("");
	};

	return (
		<div className={styles.PopupWrapper}>
			<div className={styles.Top}>
				<div>
					<button onClick={() => setChatPopup(false)}>{"<"}</button>
					<h1>Support</h1>
				</div>
				<p>Message Setting</p>
			</div>

			<div className={styles.Bottom}>
				<div className={styles.ChatWrapper}>
					<div className={styles.Box}>
						<div className={styles.BoxLeft}>
							<h6>Ticket No #{activeTicket?.ticketNumber}</h6>
							<h3>{activeTicket?.ticketTitle}</h3>
							<span>{activeTicket?.createdAt}</span>
						</div>
						<div className={styles.BoxRight}>{activeTicket?.ticketStaus ? "Active" : "Close"}</div>
					</div>

					<div className={styles.MessageWrapper}>
						<div className={styles.MessageContentWrapper}>
							{chat.map((data, index) => {
								if (userId === data.sender)
									return (
										<div key={index} className={styles.MeWrapper}>
											<div className={styles.Me}>{data.text}</div>
										</div>
									);
								else
									return (
										<div key={index} className={styles.YouWrapper}>
											<div className={styles.You}>{data.text}</div>
										</div>
									);
							})}
						</div>

						{activeTicket?.ticketStaus && (
							<div className={styles.InputWrapper}>
								<input
									type="text"
									value={text}
									onChange={(e) => setText(e.target.value)}
									onKeyDown={(e) => {
										if (e.key === "Enter") onSend();
									}}
								/>

								<button onClick={onSend}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="30"
										height="30"
										viewBox="0 0 30 30"
										fill="none">
										<g clipPath="url(#clip0_31_181)">
											<path
												d="M16.097 9.64514V24.3855H13.675V9.64514L7.17916 16.141L5.4668 14.4286L14.886 5.0094L24.3053 14.4286L22.5929 16.141L16.097 9.64514Z"
												fill="white"
											/>
										</g>
										<defs>
											<clipPath id="clip0_31_181">
												<rect
													width="29.0642"
													height="29.0642"
													fill="white"
													transform="translate(0.353516 0.166016)"
												/>
											</clipPath>
										</defs>
									</svg>
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChatPopup;
