import { GoogleOAuthProvider } from "@react-oauth/google";
import ls from "localstorage-slim";
import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import App from "./App";
import "./assets/css/index.scss";
import store from "./redux/store";

ls.config.ttl = 50 * 24 * 60 * 60;

ReactGA.initialize("G-HYZ1Z4244P");

ReactDOM.createRoot(document.getElementById("root")).render(
	// <React.StrictMode>
	<GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
		<Provider store={store}>
			<App />
		</Provider>
	</GoogleOAuthProvider>
	// </React.StrictMode>
);
