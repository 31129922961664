import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../../../../components/Hooks/axios";
import Modal from "../../../../components/Modal/Modal";
import { setUserObject } from "../../../../redux/slices/authSlice";
import classes from "./Update.module.scss";

const UpdateInfo = ({ setInfoUpdateShow, activeInfo }) => {
	const { userId } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const [btnDisable, setBtnDisable] = useState(false);
	const [content, setContent] = useState(activeInfo?.content);

	const handelUpdate = () => {
		if (content === "") return toast.warn("Please fill the filed !");
		setBtnDisable(true);

		const data = {
			[activeInfo.keyName]: content,
		};

		axios
			.put(`/auth/update-user-details?userId=${userId}`, data)
			.then(({ data }) => {
				toast.success(data.message);
				dispatch(setUserObject(data.user));
				setBtnDisable(false);
				setInfoUpdateShow(false);
			})
			.catch((response) => {
				console.log(response);
				setBtnDisable(false);
			});
	};

	const handelUpdateWhyChoose = () => {
		axios
			.put(`/auth/updateWhyChoseUs`, {
				whyChoseUs: content,
			})
			.then(({ data }) => {
				toast.success(data.message);
				setBtnDisable(false);
				setInfoUpdateShow(false);
				dispatch(setUserObject(data.data));
			})
			.catch(({ response }) => {
				setBtnDisable(false);
				console.log("Error => ", response);
			});
	};

	return (
		<Modal onClose={setInfoUpdateShow}>
			<div className={classes.overlay} onClick={(e) => e.stopPropagation()}>
				<div className={classes.form}>
					<h2>Update {activeInfo.heading}</h2>

					<p>{activeInfo.desc}</p>

					<label htmlFor="name">{activeInfo.heading}</label>

					{activeInfo.choose ? (
						<textarea defaultValue={content} value={content} onChange={(e) => setContent(e.target.value)}></textarea>
					) : (
						<input
							type="name"
							placeholder={`Enter your ${activeInfo.heading}`}
							value={content}
							onChange={(e) => setContent(e.target.value)}
						/>
					)}

					<div className={classes.actions}>
						<button
							className={classes.btn}
							disabled={btnDisable}
							onClick={() => {
								if (activeInfo.choose) handelUpdateWhyChoose();
								else handelUpdate();
							}}>
							Update
						</button>

						<button className={classes.link} onClick={() => setInfoUpdateShow(false)}>
							Back
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default UpdateInfo;
