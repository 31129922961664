import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CaretLeft } from "../../../assets/icons/CaretLeft.svg";
import { ReactComponent as ListIcon } from "../../../assets/icons/LiIcon.svg";
import Loading from "../../../components/Hooks/Loading";
import axios from "../../../components/Hooks/axios";
import { setIsOpenAds } from "../../../redux/slices/AdsSlice";
import styles from "./AdsApply.module.scss";

const AdsOffer = ({ setActiveSection, setActiveAddData }) => {
	const dispatch = useDispatch();
	const { path } = useSelector((s) => s.ads);

	const [activeAdd, setActiveAdd] = useState("month");
	const [adsData, setAdsData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		axios
			.get(`/ads/get-all?type=${activeAdd}&path=${path}`)
			.then(({ data }) => {
				setAdsData(data.data);
				setLoading(false);
			})
			.catch(({ response }) => {
				console.log("Error => ", response);
			});
	}, [activeAdd]);

	return (
		<div className={styles.AdsOfferWrapper}>
			<div className={styles.Top}>
				<h1>
					We offer great <span>price</span> plan for the ads
				</h1>

				<button onClick={() => dispatch(setIsOpenAds(false))}>
					<CaretLeft />
				</button>
			</div>

			<div className={styles.Mid}>
				<button onClick={() => setActiveAdd("week")} className={activeAdd === "week" ? styles.Active : ""}>
					Weekly
				</button>
				<button onClick={() => setActiveAdd("month")} className={activeAdd === "month" ? styles.Active : ""}>
					Monthly
				</button>
			</div>

			<div className={styles.Bottom}>
				{loading ? <Loading /> : adsData.length === 0 && <h1 style={{ textAlign: "center" }}>No Ads Available</h1>}
				<AliceCarousel
					mouseTracking
					disableButtonsControls
					disableDotsControls
					responsive={{
						0: {
							items: 1,
						},
						500: {
							items: 2,
						},
						800: {
							items: 3,
						},
					}}>
					{adsData.map((data, index) => {
						return (
							<div className={styles.Box} key={index} onDragStart={(e) => e.preventDefault()}>
								<h1>{data?.title}</h1>

								<p>{data?.description}</p>

								<img src={data?.img} alt="" />

								<h2 className={styles.Amount}>
									₹{data?.amount}
									<span>/</span>
									<span>{activeAdd}</span>
								</h2>

								<div className={styles.List}>
									<ul>
										{data?.points.map((data, index) => (
											<li key={index}>
												<ListIcon /> {data}
											</li>
										))}
									</ul>
								</div>

								<div className={styles.Wrapper2}>
									<button
										onClick={() => {
											setActiveAddData(data);
											setActiveSection("2");
										}}>
										Get Started
									</button>
								</div>
							</div>
						);
					})}
				</AliceCarousel>
			</div>
		</div>
	);
};

export default AdsOffer;
