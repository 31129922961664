import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { socket } from "../../App";
import Rectangle1 from "../../assets/images/Rectangle 502.png";
import Rectangle2 from "../../assets/images/Rectangle 503.png";
import HeartFilled from "../../assets/svg/HeartFilled.svg";
import HeartUnfilled from "../../assets/svg/HeartUnfilled.svg";
import VectorGraph from "../../assets/svg/VectorGraph.svg";
import comment from "../../assets/svg/comment.svg";
import share from "../../assets/svg/share.svg";
import axios from "../../components/Hooks/axios";
import { setCookies } from "../../components/Hooks/setCookies";
import CommentContainer from "./CommentContainer";
import Poll from "./Poll";
import styles from "./index.module.scss";
import { setPostReload, setShareData } from "../../redux/slices/tempSlice";

const PostVideoContainer = ({ data }) => {
	const { userId, user, token } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [postData, setPostData] = useState(data);
	const [showComment, setShowComment] = useState(false);
	const [rightClick, setRightClick] = useState(false);
	const [likeCount, setLikeCount] = useState(postData?.LikeCount);
	const [isLiked, setIsLiked] = useState(postData?.isLike);
	const [pollShow, setPollShow] = useState(false);

	const [commentsData, setCommentsData] = useState(data?.Comments);

	const handelLike = () => {
		let sd = {
			notification_from: userId,
			notification_to: data.userId._id,
			notification: {},
			title: "Like",
			message: `${user.username} like your post`,
		};

		axios
			.post(`/post/${data?._id}/likeANDunlike`, {
				userId,
			})
			.then(({ data }) => {
				setIsLiked(data.totalLikes[0].isLike);
				setLikeCount(data.totalLikes[0].LikeCount);

				if (data.totalLikes[0].isLike) socket.emit("notification", sd);
			})
			.catch(({ response }) => {
				console.log(response);
			});
	};

	const handelDelete = () => {
		axios
			.delete(`/post/delete?postId=${data._id}`)
			.then(({ data }) => {
				toast.success(data.message);
				console.log(data);
				dispatch(setPostReload(true));
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const handelDownload = (name) => {
		// axios({
		// 	url: postData?.mediaUrl,
		// 	method: "GET",
		// 	responseType: "blob",
		// }).then((response) => {
		// 	const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = postData?.mediaUrl;
			link.setAttribute("download", `${name}.jpg`);
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);
			setRightClick(false)
		// });
	};

	return (
		<div
			className={styles.imageContainer}
			onContextMenu={(e) => {
				e.preventDefault();
				setRightClick(true);
			}}>
			<video src={postData?.mediaUrl} className={styles.MediaImage} controls></video>

			<div className={styles.postHeader}>
				<div
					className={styles.rightPostHeader}
					onClick={() => {
						setCookies({
							// url: `${process.env.REACT_APP_TEMPLATE_BASE_URL}/${postData?.userId?.personalTemplate}`,
							url: `${process.env.REACT_APP_TEMPLATE_BASE_URL}`,
							token,
							personaUserId: postData?.userId._id,
							currentUserId: userId,
						});
					}}>
					<div className={styles.imagePostBox}>
						<img src={postData?.userId?.ProfilePic} alt="Profile Image" />
					</div>

					<div className={styles.textPostBox}>
						<h3>{postData?.userId?.username}</h3>
						<p>@{postData?.userId?.GetstatedName}</p>
					</div>
				</div>

				<div className={styles.leftPostHeader}>
					{postData?.Enabledpoll && <img src={VectorGraph} alt="VectorGraph" onClick={() => setPollShow(true)} />}
				</div>
			</div>

			<div className={styles.postFooter} style={{ width: "60%" }}>
				<div className={styles.commentContainer}>
					<p className={styles.comment}>{postData?.caption}</p>

					<div className={styles.actionContainer}>
						<div className={styles.h} onClick={handelLike}>
							{isLiked ? <img src={HeartFilled} alt="" /> : <img src={HeartUnfilled} alt="" />}
							<p>{likeCount}</p>
						</div>

						<div className={styles.c} onClick={() => setShowComment(true)}>
							<img src={comment} alt="" />
							<p>{commentsData?.length}</p>
						</div>

						<div
							className={styles.s}
							onClick={() => dispatch(setShareData({ show: true, endPoint: "/Post/", id: postData._id }))}>
							<img src={share} alt="" />
						</div>
					</div>
				</div>

				{postData?.mediaUrl.length > 1 && (
					<div className={styles.multi}>
						<img src={Rectangle1} alt="Rectangle1" />
						<img src={Rectangle2} alt="Rectangle2" className={styles.img2} />
						{postData?.mediaUrl.length} +
					</div>
				)}
			</div>

			{showComment && (
				<CommentContainer
					setShow={setShowComment}
					commentsData={commentsData}
					setCommentsData={setCommentsData}
					postId={postData._id}
					data={data}
				/>
			)}

			{pollShow && <Poll setPollShow={setPollShow} postData={postData} />}

			{rightClick && (
				<div
					className={styles.Popup}
					onContextMenu={(e) => {
						e.preventDefault();
						setRightClick(false);
					}}
					onClick={() => setRightClick(false)}>
					<div className={styles.btnContainer} onClick={(e) => e.stopPropagation()}>
						<button onClick={() => navigate(`/Post/${data._id}`)}>View Post</button>
						{userId === data.userId._id && <button onClick={handelDelete}>Delete Post</button>}
						{userId === data.userId._id && <button onClick={() => navigate("/PersonalInsights")}>View Stats</button>}
						<button onClick={() => handelDownload(data?.userId?.username)}>Download</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default PostVideoContainer;
