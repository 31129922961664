import React, { useEffect, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import RightArrow from "../../assets/svg/RightArrow";
import SearchIcon from "../../assets/svg/search.svg";
import { fetchMessageReq } from "../../redux/slices/chatSlice";
import styles from "./Chat.module.scss";

const ChatSetting = ({ setMessageRequest, setSettingPopup }) => {
	const dispatch = useDispatch();
	const [Everyone, setEveryone] = useState(true);
	const [PeopleFollow, setPeopleFollow] = useState(false);
	const [Followers, setFollowers] = useState(false);
	const [Search, setSearch] = useState("");
	const [allowMessageBox, setAllowMessageBox] = useState(false);
	const [searchBox, setSearchBox] = useState(false);

	const { messageReqData, isLoading } = useSelector((state) => state.chat);

	useEffect(() => {
		dispatch(fetchMessageReq());
	}, []);

	const handelSwitchAction = (a, type) => {
		let e = Everyone;
		let p = PeopleFollow;
		let f = Followers;
		if (type === "Everyone") {
			if (Everyone) return;
			e = a;
			p = false;
			f = false;
		}
		if (type === "PeopleFollow") {
			if (PeopleFollow) return;
			e = false;
			p = a;
			f = false;
		}
		if (type === "Followers") {
			if (Followers) return;
			e = false;
			p = false;
			f = a;
		}
		setEveryone(e);
		setPeopleFollow(p);
		setFollowers(f);
	};

	return (
		<div className={styles.ChatSettingContainer}>
			<h1 className={styles.MSettingText}>Message Settings</h1>
			<button
				className={styles.MRBtn}
				type="button"
				onClick={() => {
					setMessageRequest(true);
					setSettingPopup(false);
				}}>
				<div>
					<h2>Message Request</h2>
					<p>See who all sent you a request</p>
				</div>
				<span>
					{isLoading ? (
						<FallingLines color="#fff" width="50" visible={true} ariaLabel="falling-lines-loading" />
					) : (
						messageReqData?.length
					)}
				</span>
			</button>

			<h2 className={styles.ChatSettingText}>Chat Setting</h2>

			<div className={styles.ChatSettingTextContainer}>
				<h3 className={styles.ControlText}>Control</h3>
				<div className={styles.InnerControlText}>
					<h4>Allow Message from</h4>
					<div onClick={() => setAllowMessageBox(!allowMessageBox)}>
						<p>
							{Everyone && "Everyone"}
							{PeopleFollow && "People You Follow"}
							{Followers && "Your Followers"}
						</p>
						<RightArrow />
					</div>
				</div>
				<div className={styles.InnerControlText}>
					<span>
						<h4>Block Message from</h4>
						<h6>Any message from people you block will not be visible to anyone but them</h6>
					</span>
					<div onClick={() => setSearchBox(!searchBox)}>
						<p>0 people</p>
						<RightArrow />
					</div>
				</div>
			</div>

			{allowMessageBox && (
				<div className={styles.AllowMessageFrom}>
					<h5>Allow Message request from</h5>
					<div className={styles.AllowCommentsBox}>
						<div>
							<p>Everyone</p>
						</div>
						<Switch
							onChange={(e) => handelSwitchAction(e, "Everyone")}
							checked={Everyone}
							offColor={"#ddd"}
							onColor={"#fff"}
							offHandleColor={"#959595"}
							onHandleColor={"#3A0DBB"}
							uncheckedIcon={false}
							checkedIcon={false}
							activeBoxShadow={"0 0 0px 0px #909AFF"}
							height={19}
							width={46}
						/>
					</div>
					<div className={styles.AllowCommentsBox}>
						<div>
							<p>People you follow</p>
							<span>53 people</span>
						</div>
						<Switch
							onChange={(e) => handelSwitchAction(e, "PeopleFollow")}
							checked={PeopleFollow}
							offColor={"#ddd"}
							onColor={"#fff"}
							offHandleColor={"#959595"}
							onHandleColor={"#3A0DBB"}
							uncheckedIcon={false}
							checkedIcon={false}
							activeBoxShadow={"0 0 0px 0px #909AFF"}
							height={19}
							width={46}
						/>
					</div>
					<div className={styles.AllowCommentsBox}>
						<div>
							<p>Your followers</p>
							<span>500 people</span>
						</div>
						<Switch
							onChange={(e) => handelSwitchAction(e, "Followers")}
							checked={Followers}
							offColor={"#ddd"}
							onColor={"#fff"}
							offHandleColor={"#959595"}
							onHandleColor={"#3A0DBB"}
							uncheckedIcon={false}
							checkedIcon={false}
							activeBoxShadow={"0 0 0px 0px #909AFF"}
							height={19}
							width={46}
						/>
					</div>
				</div>
			)}

			{searchBox && (
				<div className={styles.BlockMessageFromCont}>
					<h4>Block Message from</h4>
					<div>
						<img src={SearchIcon} alt="SearchIcon" />
						<input type="search" placeholder="Search" value={Search} onChange={(e) => setSearch(e.target.value)} />
					</div>
				</div>
			)}
		</div>
	);
};

export default ChatSetting;
