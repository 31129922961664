import React, { useEffect, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../components/Hooks/axios";
import PostSuggestion from "../../components/Suggestion/PostSuggestion";
import { setPostReload } from "../../redux/slices/tempSlice";
import PostImageContainer from "./PostImageContainer";
import PostVideoContainer from "./PostVideoContainer";
import styles from "./index.module.scss";

const Post = ({ offset, setOffset }) => {
	const dispatch = useDispatch();
	const [postData, setPostData] = useState([]);
	const [postLoading, setPostLoading] = useState(false);
	const [noMorePost, setNoMorePost] = useState(false);
	const { postReload } = useSelector((state) => state.temp);

	useEffect(() => {
		if (postReload) {
			setOffset(0);
			setPostData([]);
			dispatch(setPostReload(false));
		}
		
		setPostLoading(true);
		axios
		.get(`/post/following/?skip=${offset}&limit=20`)
		.then(({ data }) => {
			console.log("Post Data => ", data);
			setPostData((pre) => [...pre, ...data?.AllPost]);
			setPostLoading(false);
				if (data?.AllPost?.length === 0) setNoMorePost(true);
			})
			.catch((e) => {
				console.log(e);
				// setPostLoading(false);
			});
	}, [offset, postReload]);

	let suggestionIndex = 1;
	const post = (data, index) => {
		if (index === suggestionIndex) {
			if (index === 2) suggestionIndex += 8;
			else suggestionIndex += 10;
			return (
				<div key={index}>
					<PostSuggestion />
					{data?.mediaType === "image" && <PostImageContainer key={index} data={data} />}
					{data?.mediaType === "video" && <PostVideoContainer key={index} data={data} />}
				</div>
			);
		}
		if (data?.mediaType === "image") return <PostImageContainer key={index} data={data} />;
		if (data?.mediaType === "video") return <PostVideoContainer key={index} data={data} />;
	};

	return (
		<>
			{postData?.map(post)}
			<div className={styles.postContainer}>
				{postLoading && <FallingLines color="#3A0DBB" width="100" visible={true} ariaLabel="falling-lines-loading" />}
				{noMorePost && !postLoading && <h3 className={styles.NoMorePostText}>No More Post</h3>}
			</div>
		</>
	);
};

export default Post;
