import axios from "axios";
import Cookies from "js-cookie";

const Instance = axios.create({
	baseURL: `${process.env.REACT_APP_BASE_API_URL}/api`,
	// baseURL: `http://localhost:3000/api`,
	headers: {
		// authorization: localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token"),
		authorization: Cookies.get("E_token") && JSON.parse(Cookies.get("E_token")),
	},
});

export default Instance;

// Do this after login
// axios.defaults.headers.Authorization = token