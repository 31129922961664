import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ForgetLogo from "../../../assets/svg/Foget password.svg";
import LoadingBg from "../Loading Bg/LoadingBg";
import classes from "./ForgetPassword.module.css";
import axios from "../../../components/Hooks/axios";
import { useDispatch } from "react-redux";
import { setTempEmail } from "../../../redux/slices/tempSlice";

const ForgetPassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [emailNumber, setEmailNumber] = useState("");
	const [btnDisable, setBtnDisable] = useState(false);

	const changePasswordHandler = () => {
		if (emailNumber === "") return toast.warn("Please enter Email or Number");
		setBtnDisable(true);
		axios
			.post("/auth/otp", {
				email: emailNumber,
			})
			.then(({ data }) => {
				toast.success(data.message)
				toast.info(data.otp, {
					autoClose: 10000,
				});
				dispatch(setTempEmail(emailNumber));
				navigate("/verification-code");
				setBtnDisable(false);
			})
			.catch(({ response }) => {
				toast.error(response.data.error);
				setBtnDisable(false);
			});
	};

	return (
		<div className={classes["main-container"]}>
			<LoadingBg />
			<div className={classes["sub-container"]}>
				<div className={classes.header}>
					<div className={classes["text-container"]}>
						<p className={classes.heading}>Forget Password</p>
						<p className={classes.text}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus est tortor gravida nibh id at.
						</p>
					</div>
					<div className={classes.image}>
						<img src={ForgetLogo} alt="" />
					</div>
				</div>
				<div className={classes.form}>
					<div className={classes.input}>
						<label htmlFor="email" className={classes.label}>
							E-mail / Phone no
						</label>
						<input
							type="text"
							className={classes["input-box"]}
							value={emailNumber}
							onChange={(e) => setEmailNumber(e.target.value)}
						/>
					</div>
					<div className={classes.actions}>
						<button type="submit" className={classes.btn} disabled={btnDisable} onClick={changePasswordHandler}>
							Submit
						</button>
					</div>
					<p className={classes.link} onClick={()=>navigate("/signin")}>Back to Sign In</p>
				</div>
			</div>
		</div>
	);
};

export default ForgetPassword;
