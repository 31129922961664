import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowRight from "../../../assets/icons/ArrowRight.svg";
import { switchAccount } from "../../../redux/slices/authSlice";
import styles from "./SwitchAccount.module.scss";

const SwitchAccount = ({ setAddAccountPopup }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { users } = useSelector((s) => s.account);
	const { userId } = useSelector((s) => s.auth);

	const handelOnchange = (data) => {
		dispatch(switchAccount({ userId: data.userId, user: data.user, token: data.token }));
		setAddAccountPopup(false);
	};

	return (
		<div className={styles.SwitchAccount} onClick={() => setAddAccountPopup(false)}>
			<div className={styles.Wrapper} onClick={(e) => e.stopPropagation()}>
				<div className={styles.Row1}>
					<h1>Accounts</h1>

					<button onClick={() => setAddAccountPopup(false)}>
						<img src={ArrowRight} alt="" />
					</button>
				</div>

				<div className={styles.Row2}>
					{users?.map((data, index) => {
						return (
							<div key={index} className={styles.BoxWrapper}>
								<div className={styles.Box}>
									<div className={styles.Left}>
										<img src={data.user.ProfilePic} alt="Profile" />

										<div className={styles.TextWrapper}>
											<h2>{data.user.username}</h2>
											<h4></h4>
										</div>
									</div>

									<div className={styles.Right}>
										<input
											type="radio"
											name="account"
											defaultChecked={data.userId === userId}
											onChange={() => handelOnchange(data)}
										/>
									</div>
								</div>
							</div>
						);
					})}
				</div>

				<button onClick={() => navigate("/signin")}>Add New Account</button>
			</div>
		</div>
	);
};

export default SwitchAccount;
