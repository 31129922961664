import React, { useState } from "react";
import styles from "./AdsApply.module.scss";
import AdsForm from "./AdsForm";
import AdsOffer from "./AdsOffer";
import AdsPayment from "./AdsPayment";

const AdsApply = () => {
	const [activeSection, setActiveSection] = useState("1");
	const [activeAddData, setActiveAddData] = useState(null)
	const [myAddData, setMyAddData] = useState(null)

	return (
		<div className={styles.AdsApplyWrapper}>
			{activeSection === "1" && <AdsOffer {...{ setActiveSection, setActiveAddData }} />}
			{activeSection === "2" && <AdsForm {...{ setActiveSection, activeAddData, setMyAddData }} />}
			{activeSection === "3" && (
				<AdsPayment {...{ setActiveSection, activeAddData, setActiveAddData, myAddData, setMyAddData }} />
			)}
		</div>
	);
};

export default AdsApply;
