import { useGoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import facebook from "../../../assets/images/Facebook.png";
import google from "../../../assets/images/Google.png";
import signUpLogo from "../../../assets/svg/sign-up-logo.svg";
import { EmailValidate } from "../../../components/Hooks/EmailValidate";
import axios from "../../../components/Hooks/axios";
import { setAuth } from "../../../redux/slices/authSlice";
import { setTempEmail } from "../../../redux/slices/tempSlice";
import LoadingBg from "../Loading Bg/LoadingBg";
import classes from "./SignUp.module.scss";

const SignUp = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [gender, setGender] = useState("");
	const [DOB, setDOB] = useState("");
	const [password, setPassword] = useState("");
	const [conPassWord, setConPassWord] = useState("");
	const [btnDisabled, setBtnDisabled] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const [passIcon1, setPassIcon1] = useState(false);
	const [passIcon2, setPassIcon2] = useState(false);

	const passwordValidate = () => {
		const number = /\d/;
		const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
		const uppercaseRegex = /[A-Z]/;

		if (password.length < 8) {
			toast.error("Password length must be 8 or more!!");
			return true;
		}

		if (!number.test(password)) {
			toast.error("Please add a number!!");
			return true;
		}

		if (!specialCharacterRegex.test(password)) {
			toast.error("Please add a special character!!");
			return true;
		}

		if (!uppercaseRegex.test(password)) {
			toast.error("Please add a uppercase latter!!");
			return true;
		}
		return false;
	};
	const validText = `1. Length must be 8 or more \n2. Include a number\n3. Include a special character\n4. Include a uppercase latter`;

	const handelSubmit = () => {
		if (name === "" || email === "" || DOB === "" || gender === "" || password === "" || conPassWord === "")
			return toast.warn("Please fill all the fields");
		if (EmailValidate(email)) return toast.error("Invalid Email !");
		if (passwordValidate()) return;
		if (password !== conPassWord) return toast.error("Password and Confirm Password not match");

		setBtnDisabled(true);

		const data = JSON.stringify({
			username: name,
			email: email,
			dob: DOB,
			gender,
			password: password,
		});

		axios
			.post("/auth/register", data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(({ data }) => {
				dispatch(setAuth({ userId: data.userId, token: data.token, rememberMe }));
				dispatch(setAuth(data));
				setTimeout(() => {
					toast.success(data.message);
					setBtnDisabled(false);
					toast.info(data.otp, {
						autoClose: 10000,
					});
					toast.success("SignUp Success");
					dispatch(setTempEmail(email));
					navigate("/verification-code", { state: { signUp: true }, replace: true });
				}, 300);
			})
			.catch(({ response: { data } }) => {
				toast.error(data.error);
				console.log(data);
				setBtnDisabled(false);
			});
	};
	// https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}

	const login = useGoogleLogin({
		onSuccess: (tokenResponse) => {
			axios
				.get(`https://www.googleapis.com/oauth2/v3/userinfo`, {
					headers: {
						Authorization: `Bearer ${tokenResponse.access_token}`,
					},
				})
				.then(({ data }) => {
					axios
						.post("/auth/googleRegister", data)
						.then(({ data }) => {
							if (data.status) {
								// Cookies.set("E_current_userId", JSON.stringify(data.data._id));
								// Cookies.set("E_token", JSON.stringify(data.token));
								dispatch(setAuth({ userId: data.data._id, token: data.token }));
								setBtnDisabled(false);
								navigate("/interest", { replace: true });
							}
						})
						.catch((e) => console.log(e));
				})
				.catch((e) => console.log(e));
		},
		onError: (tokenResponse) => console.log(tokenResponse),
	});

	return (
		<div className={classes["main-container"]}>
			<LoadingBg />
			<div className={classes["sub-container"]}>
				<div className={classes.header}>
					<div className={classes["text-container"]}>
						<p className={classes.heading}>Sign Up</p>
						<p className={classes.text}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus est tortor gravida nibh id at.
						</p>
					</div>
					<div className={classes.image}>
						<img src={signUpLogo} alt="" />
					</div>
				</div>

				<div className={classes.form}>
					<div className={classes["input-container"]}>
						<Link to="/signin" className={classes.ArrowBtn}>
							{">"}
						</Link>

						<div className={classes.input}>
							<label className={classes.label}>Name</label>
							<input
								type="text"
								className={classes["input-box"]}
								value={name}
								placeholder="Enter Your Name"
								onChange={(e) => setName(e.target.value)}
							/>
						</div>

						<div className={classes.input}>
							<label className={classes.label}>E-mail or Phone no</label>
							<input
								type="email"
								className={classes["input-box"]}
								placeholder="Your Email Id or Phone Number"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>

						<div className={classes["contact-info"]}>
							<div className={`${classes.input} ${classes.gender}`}>
								<label className={classes.label}>Gender</label>
								<select
									style={{ borderRight: "1.5rem solid #eeeeee" }}
									className={classes["input-box"]}
									defaultValue={gender}
									onChange={(e) => setGender(e.target.value)}>
									<option value="" disabled>
										Select a Gender
									</option>
									<option value="male">Male</option>
									<option value="female">Female</option>
								</select>
							</div>

							<div className={`${classes.input} ${classes.dob}`}>
								<label className={classes.label}>Date of birth</label>
								<input
									type="date"
									className={classes["input-box"]}
									value={DOB}
									onChange={(e) => {
										const date = new Date();
										if (
											e.target.value >
											`${date.getFullYear()}-${
												date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
											}-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`
										)
											toast.error("Please Select a Proper Date!!");
										else {
											if (
												!(
													e.target.value <
													`${date.getFullYear() - 13}-${
														date.getMonth() + 1 < 10
															? "0" + (date.getMonth() + 1)
															: date.getMonth() + 1
													}-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`
												)
											)
												toast.error("Age must be 13 years old!!");
											else setDOB(e.target.value);
										}
									}}
								/>
							</div>
						</div>

						<div className={classes.passwordWrapper}>
							<div className={classes.input}>
								<label className={classes.label}>New password</label>

								<div className={classes.inputPass} title={validText}>
									<input
										type={passIcon1 ? "text" : "password"}
										className={classes["input-box"]}
										placeholder="Enter Your Password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
									<span onClick={() => setPassIcon1(!passIcon1)}>
										{passIcon1 ? <FaEye size={"2.5rem"} /> : <FaEyeSlash size={"2.5rem"} />}
									</span>
								</div>
							</div>

							<div className={classes.input}>
								<label className={classes.label}>Re-type password</label>
								<div className={classes.inputPass} title={validText}>
									<input
										type={passIcon2 ? "text" : "password"}
										className={classes["input-box"]}
										placeholder="Confirm Password"
										value={conPassWord}
										onChange={(e) => setConPassWord(e.target.value)}
									/>
									<span onClick={() => setPassIcon2(!passIcon2)}>
										{passIcon2 ? <FaEye size={"2.5rem"} /> : <FaEyeSlash size={"2.5rem"} />}
									</span>
								</div>
							</div>
						</div>

						<div className={classes["forget-remember"]}>
							<section className={classes.remember}>
								<input
									type="radio"
									className={classes["radio-button"]}
									checked={rememberMe}
									onChange={(e) => setRememberMe(e.target.checked)}
									id="rememberMeSignUp"
								/>
								<label htmlFor="rememberMeSignUp" className={classes["remember-me"]}>
									Remember me
								</label>
							</section>
							<div className={classes.actions}>
								<button type="submit" className={classes.btn} onClick={handelSubmit} disabled={btnDisabled}>
									Sign Up
								</button>
							</div>
						</div>
					</div>

					<div className={classes.design}>
						<div className={classes.line}></div>
						<p className={classes.continue}>Continue With</p>
						<div className={classes.line}></div>
					</div>

					<div className={classes.footer}>
						<button onClick={() => login()}>
							<img src={google} alt="" />
						</button>
						<button>
							<img src={facebook} alt="" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignUp;
