import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";

const StoryItem = ({ data }) => {
	const navigate = useNavigate();

	return (
		<div className={`${styles["slide-wrapper"]}`} onDragStart={(e) => e.preventDefault()}>
			<div
				className={styles["slider-item"]}
				onDragStart={(e) => e.preventDefault()}
				onDragEnd={(e) => e.preventDefault()}
				onClick={() => navigate(`/Story/${data.userId._id}`, { state: { own: false } })}>
				<div className={styles.image}>
					{data?.stories[0]?.mediaType === "image" && <img src={data?.stories[0]?.mediaUrl} />}
				</div>
				<span className={styles.title}>@{data?.userId?.username}</span>
			</div>
		</div>
	);
};

export default StoryItem;
