import React, { useEffect, useRef, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { socket } from "../../App";
import ClipIcon from "../../assets/svg/ClipIcon.svg";
import SendIcon from "../../assets/svg/SendIcon.svg";
import SmileyIcon from "../../assets/svg/SmileyIcon.svg";
import ThreeDotIcon from "../../assets/svg/ThreeDotIcon.svg";
import styles from "./Chat.module.scss";
import EmojiPicker from "emoji-picker-react";

// export const socket = io(`${process.env.REACT_APP_BASE_API_URL}/socket_connection`);

const ActiveChat = ({ ActiveChatId }) => {
	const { userId, user } = useSelector((state) => state.auth);
	const [Popup, setPopup] = useState(false);
	const [InputText, setInputText] = useState("");
	const [FileSelected, setFileSelected] = useState(null);
	const fileRef = useRef();
	const chatContainerRef = useRef();
	const [chat, setChat] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [onlineStatus, setOnlineStatus] = useState(false);
	const [openEmoji, setOpenEmoji] = useState(false)

	useEffect(() => {
		const domNode = chatContainerRef.current;
		domNode.scrollTop = domNode.scrollHeight;
	}, [chat]);

	useEffect(() => {
		setIsLoading(true);
		socket.emit("joinRoom", ActiveChatId.data._id);
		socket.on("chatHistory", (payload) => {
			setIsLoading(false);
			setChat(payload);
		});
		return () => socket.emit("leaveRoom", ActiveChatId.data._id);
	}, [ActiveChatId]);

	useEffect(() => {
		socket.on(`message_${ActiveChatId.data._id}`, (payload) => {
			setChat((pre) => [...pre, payload]);
		});
		socket.on(`receive_message_${ActiveChatId.data._id}`, (payload) => {
			setChat((pre) => [...pre, payload]);
		});
	}, [socket, ActiveChatId]);

	useEffect(() => {
		if (userId === "") return;
		if (user.is_active === true) socket.emit("joinLoginRoom", { userId });
		else socket.emit("joinLoginRoom", { userId, activeStatus: false });

		let data = undefined;
		socket.on("userOnline", (payload) => {
			data = payload.find((item) => item.userId === ActiveChatId.userData._id);
			if (data?.userId) setOnlineStatus(true);
			else setOnlineStatus(false);
		});
	}, [socket, userId, ActiveChatId]);

	const handelSentBtn = () => {
		// console.log(FileSelected);
		// setFileSelected(null);
		if (InputText.trim() === "") return;
		let data = {
			room: ActiveChatId.data._id,
			sender: userId,
			message: InputText,
		};
		socket.emit("chatMessage", data);
		setInputText("");
	};

	let messageDate2 = "";
	let todayCheck2 = false;

	return (
		<div className={styles.ActiveChatContainer}>
			<div className={styles.ActiveChatProfileContainer}>
				<div className={styles.ActiveChatProfileLeft}>
					<img src={ActiveChatId?.userData?.ProfilePic} alt="ProfileImage" />
					<div>
						<h1>{ActiveChatId?.userData?.username}</h1>
						{onlineStatus ? <h6>Online</h6> : <h5>Offline</h5>}
					</div>
				</div>
				<img
					src={ThreeDotIcon}
					alt="ThreeDotIcon"
					style={{ cursor: "pointer", padding: ".5rem 1rem", width: "2.5rem" }}
					onClick={() => setPopup(!Popup)}
				/>
			</div>

			<div
				className={`${styles.ThreeDotPopupContainer} ${Popup && styles.ActiveThreeDotPopup}`}
				onClick={() => setPopup(false)}>
				<div className={styles.ThreeDotButtonContainer} onClick={(e) => e.stopPropagation()}>
					<button type="button" onClick={() => setPopup(false)}>
						Report User
					</button>
					<button type="button" onClick={() => setPopup(false)}>
						Block User
					</button>
				</div>
			</div>

			{/* Chat Container Start */}
			<div className={styles.ActiveChatSMSContainer} ref={chatContainerRef}>
				{isLoading ? (
					<div className={styles.loadingContainer}>
						<FallingLines color="#3A0DBB" width="100" visible={true} ariaLabel="falling-lines-loading" />
					</div>
				) : (
					chat.map((data, index) => {
						const date = new Date(data.updatedAt);
						const time = `${date.toLocaleTimeString().split(":")[0]}:${date.toLocaleTimeString().split(":")[1]} ${
							date.toLocaleTimeString().split(" ")[1]
						}`;
						const messageDate = date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear();
						let today = new Date();
						const yesterday = today.getDate() - 1 + "-" + today.getMonth() + "-" + today.getFullYear();
						today = today.getDate() + "-" + today.getMonth() + "-" + today.getFullYear();
						const todayCheck = messageDate === today;
						const toadyPrint = todayCheck2 ? "" : "Today";
						todayCheck2 = todayCheck;
						const messageDateCheck = messageDate == messageDate2;
						messageDate2 = messageDate;

						return (
							<div key={index}>
								<h4>
									{todayCheck
										? toadyPrint
										: messageDateCheck
										? ""
										: messageDate === yesterday
										? "Yesterday"
										: messageDate}
								</h4>
								{data.sender === userId ? (
									<div className={styles.ChatBoxMy}>
										<div className={styles.MyChatBox}>
											{data.text}
											<span>{time}</span>
										</div>
									</div>
								) : (
									<div className={styles.ChatBoxYou}>
										<div className={styles.YourChatBox}>
											{data.text}
											<span>{time}</span>
										</div>
									</div>
								)}
							</div>
						);
					})
				)}
			</div>
			{/* Chat Container End */}

			<div className={styles.ActiveChatInputContainer}>
				<img src={SmileyIcon} alt="emoji" onClick={() => setOpenEmoji(!openEmoji)} />

				{openEmoji && (
					<div className={styles.EmojiContainer}>
						<EmojiPicker
							height="100%"
							width="100%"
							onEmojiClick={(e) => setInputText((prevText) => prevText + e.emoji)}
							emojiStyle={"apple"}
						/>
					</div>
				)}

				<img src={ClipIcon} alt="clip" onClick={() => fileRef.current.click()} />

				<input
					type="file"
					ref={fileRef}
					style={{ display: "none" }}
					onChange={(e) => {
						let file = e.target.files[0];
						setFileSelected(file);
						e.target.value = null;
					}}
				/>

				<input
					type="text"
					placeholder="Type something "
					value={InputText}
					onChange={(e) => setInputText(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === "Enter") handelSentBtn();
					}}
				/>

				<button type="button" className={styles.SendButton} onClick={handelSentBtn}>
					<img src={SendIcon} alt="SendIcon" />
				</button>
			</div>
		</div>
	);
};

export default ActiveChat;
