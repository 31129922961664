import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../components/Modal/Modal";
import classes from "../Update Name/UpdateName.module.css";
import axios from "../../../../components/Hooks/axios";
import { toast } from "react-toastify";
import { setUserObject } from "../../../../redux/slices/authSlice";

const UpdateDob = ({ onClose, dob }) => {
	const { userId } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const [btnDisable, setBtnDisable] = useState(false);
	const [content, setContent] = useState(dob);

	const handelUpdateName = () => {
		if (content === "") return toast.warn("Please fill the filed !");
		setBtnDisable(true);

		const data = JSON.stringify({
			dob: content,
		});

		axios
			.put(`/auth/update-user-details?userId=${userId}`, data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(({ data }) => {
				toast.success(data.message);
				dispatch(setUserObject(data.user));
				setBtnDisable(false);
				onClose(false);
			})
			.catch((response) => {
				console.log(response);
				setBtnDisable(false);
			});
	};

	return (
		<Modal onClose={onClose}>
			<div className={classes.overlay} onClick={(e) => e.stopPropagation()}>
				<div className={classes.form}>
					<h2>Update Date of Birth</h2>
					<p>Please enter your date of birth which you want to update and use</p>
					<label htmlFor="date">Date of birth</label>
					<input type="date" placeholder="12 june 56" value={content} onChange={(e) =>{
						const date = new Date();
						if (
							e.target.value >
							`${date.getFullYear()}-${
								date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
							}-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`
						)
							toast.error("Please Select a Proper Date!!");
						else {
							if (
								!(
									e.target.value <
									`${date.getFullYear() - 13}-${
										date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
									}-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`
								)
							)
								toast.error("Age must be 13 years old!!");
							else setContent(e.target.value);
						}
					}} />
					<div className={classes.actions}>
						<button type="submit" className={classes.btn} disabled={btnDisable} onClick={handelUpdateName}>
							Update
						</button>
						<button className={classes.link} onClick={() => onClose(false)}>
							Back
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default UpdateDob;
