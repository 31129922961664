import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../../../../components/Hooks/axios";
import Modal from "../../../../components/Modal/Modal";
import { setUserObject } from "../../../../redux/slices/authSlice";
import classes from "./UpdateAddress.module.css";

const UpdateAddress = ({ onClose, address }) => {
	const { userId } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const [btnDisable, setBtnDisable] = useState(false);
	const [addressLine1, setAddressLine1] = useState(address?.addressLine1);
	const [addressLine2, setAddressLine2] = useState(address?.addressLine2);
	const [city, setCity] = useState(address?.city);
	const [state, setState] = useState(address?.state);
	const [district, setDistrict] = useState(address?.district);
	const [pinCode, setPinCode] = useState(address?.pinCode);

	const handelUpdateName = () => {
		if (addressLine1 === "" || addressLine2 === "" || city === "" || state === "" || district === "" || pinCode === "")
			return toast.error("Please fill the filed !");
		if (addressLine1.length < 3) return toast.error("Please add a proper address");
		if (city.length < 3) return toast.error("Please add a proper city");
		if (state.length < 3) return toast.error("Please add a proper state");
		if (district.length < 3) return toast.error("Please add a proper district");
		if (isNaN(pinCode)) return toast.error("Please add a proper pin code");
		if (pinCode.length > 6 || pinCode.length < 6) return toast.error("Please add a proper pin code");

		setBtnDisable(true);

		const data = JSON.stringify({
			addressLine1,
			addressLine2,
			city,
			state,
			district,
			pinCode,
		});

		axios
			.put(`/auth/update-user-details?userId=${userId}`, data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(({ data }) => {
				toast.success(data.message);
				dispatch(setUserObject(data.user));
				setBtnDisable(false);
				onClose(false);
			})
			.catch((response) => {
				console.log(response);
				setBtnDisable(false);
			});
	};

	return (
		<Modal onClose={onClose}>
			<div className={classes.overlay} onClick={(e) => e.stopPropagation()}>
				<div className={classes.form}>
					<div className={classes.text}>
						<h2>Update Address</h2>
						<p>Please fill the form to update your address</p>
					</div>
					<div className={classes["address-section"]}>
						<div className={classes.address}>
							<label htmlFor="address">Address line 1</label>
							<input
								type="address"
								placeholder="Enter Address"
								value={addressLine1}
								onChange={(e) => setAddressLine1(e.target.value)}
							/>
						</div>
						<div className={classes.address}>
							<label htmlFor="address">Address line 2</label>
							<input
								type="address"
								placeholder="Enter Address"
								value={addressLine2}
								onChange={(e) => setAddressLine2(e.target.value)}
							/>
						</div>
					</div>
					<div className={classes["other-info"]}>
						<div className={classes.info}>
							<label htmlFor="city">City</label>
							<input type="text" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
						</div>
						<div className={classes.info}>
							<label htmlFor="state">State</label>
							<input type="text" placeholder="State" value={state} onChange={(e) => setState(e.target.value)} />
						</div>
						<div className={classes.info}>
							<label htmlFor="district">District</label>
							<input
								type="text"
								placeholder="District"
								value={district}
								onChange={(e) => setDistrict(e.target.value)}
							/>
						</div>
						<div className={classes.info}>
							<label htmlFor="pincode">Pin Code</label>
							<input
								type="number"
								placeholder="Pin Code"
								value={pinCode}
								onChange={(e) => setPinCode(e.target.value)}
							/>
						</div>
					</div>
					<div className={classes.actions}>
						<button type="submit" className={classes.btn} disabled={btnDisable} onClick={handelUpdateName}>
							Update
						</button>
						<button className={classes.link} onClick={() => onClose(false)}>
							Back
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default UpdateAddress;
