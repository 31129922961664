import React from "react";
import StoryUploadButtons from "./StoryUploadButtons";
import styles from "./StoryWrapper.module.scss";

const StoryWrapper = ({ setStoryPopup, setOwnRel }) => {
	return (
		<div className={styles.StoryWrapper}>
			<StoryUploadButtons setStoryPopup={setStoryPopup} setOwnRel={setOwnRel} />
		</div>
	);
};

export default StoryWrapper;
