import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import { Link } from "react-router-dom";
import Loading from "../Hooks/Loading";
import axios from "../Hooks/axios";
import styles from "./Suggestion.module.scss";
import UserBox from "./UserBox";

const PostSuggestion = () => {
	const [userData, setUserData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(true);
		axios
			.get(`/auth/randomUser`)
			.then(({ data }) => {
				setIsLoading(false);
				setUserData(data);
			})
			.catch(({ response }) => {
				console.log("Error => ", response);
			});
	}, []);

	return (
		<div className={styles.Suggestion}>
			<div className={styles.SuggestionText}>
				<h1>Suggestion For You</h1>

				<Link>See all</Link>
			</div>

			<div className={styles.OuterWrapper}>
				{isLoading ? (
					<Loading />
				) : (
					<AliceCarousel
						mouseTracking
						disableButtonsControls
						disableDotsControls
						responsive={{
							0: {
								items: 1,
							},
							500: {
								items: 2,
							},
							800: {
								items: 3,
							},
						}}>
						{userData.map((data, index) => (
							<UserBox key={index} data={data} />
						))}
					</AliceCarousel>
				)}
			</div>
		</div>
	);
};

export default PostSuggestion;
