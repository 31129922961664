import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import chatSlice from "./slices/chatSlice";
import notificationSlice from "./slices/notificationSlice";
import tempSlice from "./slices/tempSlice";
import accountSlice from "./slices/accountSlice";
import AdsSlice from "./slices/AdsSlice";

const store = configureStore({
	reducer: {
		auth: authSlice,
		temp: tempSlice,
		chat: chatSlice,
		notification: notificationSlice,
		account: accountSlice,
		ads: AdsSlice,
		// [chatAPI.reducerPath]: chatAPI.reducer,
		// [authApi.reducerPath]: authApi.reducer,
	},
	// middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([chatAPI.middleware]),
});

export default store;
