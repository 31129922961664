import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import RightArrow from "../../assets/svg/RightArrow";
import styles from "./Privacy.module.scss";
import axios from "../../components/Hooks/axios";
import { toast } from "react-toastify";
import { setUserObject } from "../../redux/slices/authSlice";

const Post = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const [showBox, setShowBox] = useState(false);
	const [Everyone, setEveryone] = useState(true);
	const [PeopleFollow, setPeopleFollow] = useState(false);
	const [NoOne, setNoOne] = useState(false);
	const [disableSwitch, setDisableSwitch] = useState(false);

	useEffect(() => {
		if (user === null) return;
		setEveryone(user?.post_privacy?.everyone);
		setPeopleFollow(user?.post_privacy?.followers);
		setNoOne(user?.post_privacy?.no_one);
	}, [user]);

	const handelSwitchBtn = (e, content) => {
		setDisableSwitch(true);
		let ev = Everyone;
		let pf = PeopleFollow;
		let no = NoOne;

		if (content === "Everyone") {
			if (Everyone) return setDisableSwitch(false);
			ev = e;
			pf = false;
			no = false;
		}
		if (content === "PeopleFollow") {
			if (PeopleFollow) return setDisableSwitch(false);
			ev = false;
			pf = e;
			no = false;
		}
		if (content === "NoOne") {
			if (NoOne) return setDisableSwitch(false);
			ev = false;
			pf = false;
			no = e;
		}

		const id = toast.loading("Please Wait..");

		setEveryone(ev);
		setPeopleFollow(pf);
		setNoOne(no);

		const raw = JSON.stringify({
			post_privacy: {
				everyone: ev,
				followers: pf,
				no_one: no,
			},
		});

		axios
			.post("/auth/change-post-privacy", raw, {
				headers: {
					"content-type": "application/json",
				},
			})
			.then(({ data }) => {
				setDisableSwitch(false);
				toast.update(id, {
					render: "Your Post Privacy Successfully Updated",
					type: "success",
					isLoading: false,
					autoClose: 2000,
				});
				dispatch(setUserObject(data.data));
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<div className={styles.PostContainer}>
			<div className={styles.PostContainerInner}>
				<h1>Post</h1>
				<div className={styles.InnerDiv}>
					<h5>Likes & View</h5>
					<span onClick={() => setShowBox(!showBox)}>
						<p>
							{Everyone && "Everyone"}
							{PeopleFollow && "People You Follow"}
							{NoOne && "No One"}
						</p>
						<RightArrow />
					</span>
				</div>
				<div className={styles.InnerDiv}>
					<div>
						<h5>Likes & View</h5>
						<h6>Manage your likes and view on your post</h6>
					</div>
					<span>
						<p>0 people</p>
						<RightArrow />
					</span>
				</div>
			</div>

			{showBox && (
				<div className={styles.AllowTagFrom}>
					<h5>Allow comments from</h5>
					<div className={styles.AllowCommentsBox}>
						<div>
							<p>Everyone</p>
						</div>
						<Switch
							onChange={(e) => handelSwitchBtn(e, "Everyone")}
							checked={Everyone}
							disabled={disableSwitch}
							offColor={"#EEEEEE"}
							onColor={"#EEEEEE"}
							offHandleColor={"#959595"}
							onHandleColor={"#3A0DBB"}
							uncheckedIcon={false}
							checkedIcon={false}
							activeBoxShadow={"0 0 0px 0px #909AFF"}
							height={19}
							width={46}
						/>
					</div>
					<div className={styles.AllowCommentsBox}>
						<div>
							<p>People you follow</p>
							<span>53 people</span>
						</div>
						<Switch
							onChange={(e) => handelSwitchBtn(e, "PeopleFollow")}
							checked={PeopleFollow}
							disabled={disableSwitch}
							offColor={"#EEEEEE"}
							onColor={"#EEEEEE"}
							offHandleColor={"#959595"}
							onHandleColor={"#3A0DBB"}
							uncheckedIcon={false}
							checkedIcon={false}
							activeBoxShadow={"0 0 0px 0px #909AFF"}
							height={19}
							width={46}
						/>
					</div>
					<div className={styles.AllowCommentsBox}>
						<div>
							<p>No one</p>
						</div>
						<Switch
							onChange={(e) => handelSwitchBtn(e, "NoOne")}
							checked={NoOne}
							disabled={disableSwitch}
							offColor={"#EEEEEE"}
							onColor={"#EEEEEE"}
							offHandleColor={"#959595"}
							onHandleColor={"#3A0DBB"}
							uncheckedIcon={false}
							checkedIcon={false}
							activeBoxShadow={"0 0 0px 0px #909AFF"}
							height={19}
							width={46}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default Post;
