import React from "react";
import styles from "./Static.module.scss";

const PrivacyPolicy = () => {
	return (
		<div className="App">
			<div className={`MainContainer ${styles.Main}`}>
				<div className={styles.Wrapper}>
					<h2>Privacy policy</h2>
					<article>
						<p>
							eMagz (by “MAGZ COSMIC PRIVATE LIMITED”) ("we," "us," or "our") is committed to protecting the privacy
							and personal information of our users. This Privacy Policy explains how we collect, use, disclose, and
							safeguard your information when you use our platform ("eMagz") and any related services offered
							through the platform.
						</p>
						<p>
							Please read this Privacy Policy carefully to understand our practices regarding your information and
							how we will treat it. By accessing or using our platform, you consent to the collection, use,
							disclosure, and storage of your information as described in this Privacy Policy.
						</p>

						<ul>
							<li className={styles.li}>information's We Collect:</li>
							<>
								<p>
									On our platform, you have the freedom to engage in a wide range of activities, including
									messaging, capturing photos and videos, buying or selling items, and much more. We refer to
									these actions collectively as "activity." As part of providing our services, we collect
									various types of information related to your activity across our products. Here are some
									examples:
								</p>

								<p>
									<b>Content Creation:</b> We collect the content you create, such as posts, comments, or audio
									recordings. This enables us to facilitate sharing and interaction among users.
								</p>

								<p>
									<b>Camera and Voice Features:</b> If you use our camera feature or provide access to your
									camera roll, we collect content you capture or upload. We may use this information to enhance
									your experience by applying masks, filters, avatars, or special effects, subject to your
									preferences.
								</p>

								<p>
									<b>Messaging:</b> We collect the messages you send and receive, including their content (where
									permitted by applicable law). However, for end-to-end encrypted messages, we are unable to
									access the content unless it is reported by users for review.
								</p>

								<p>
									<b>Interaction with Content:</b> We gather data on the types of content you view or interact
									with, as well as how you engage with it. This helps us understand your interests, personalize
									your experience, and improve our services.
								</p>

								<p>
									<b>App and Feature Usage:</b> We collect information about the apps and features you use on
									our platform, as well as the actions you take within them. This assists us in delivering
									tailored content, optimizing functionality, and measuring user engagement.
								</p>

								<p>
									<b>Purchases and Transactions:</b> If you make purchases or engage in transactions through our
									platform, we collect relevant details such as payment information (e.g., credit card data),
									billing and delivery information, and the items you buy. This allows us to facilitate smooth
									and secure transactions and provide access to your transaction history.
								</p>

								<p>
									<b>Hashtags, Time, and Frequency:</b> We collect information about the hashtags you use, as
									well as the time, frequency, and duration of your activities on our platform. This helps us
									personalize your experience, deliver relevant content, and understand user behaviour.
								</p>

								<p>
									<b>Sensitive Information:</b> You may choose to provide information about your religious
									views, sexual orientation, political beliefs, health status, racial or ethnic origin,
									philosophical beliefs, or trade union membership. We recognize that these types of information
									may be subject to special protections under the laws of your jurisdiction, and we respect and
									handle them accordingly.
								</p>

								<p>
									<b>Why We Collect This Information:</b> We collect and store this information to personalize
									your experience on our platform, provide relevant advertising, and enable you to access and
									review your payment and transaction history. Additionally, based on your preferences, we may
									use this information to streamline future shopping experiences, making them easier and faster.
								</p>

								<p>
									It's important to note that we treat your privacy and the protection of your information as a
									top priority. We adhere to applicable laws and regulations regarding the collection, use, and
									safeguarding of your personal data. For more details on how we handle your information, please
									refer to our comprehensive Privacy Policy.
								</p>
							</>

							<li className={styles.li}>Connections and Interactions:</li>
							<>
								<p>
									At our platform, we value the connections you build with friends, followers, groups, accounts,
									personas, Pages, and other users and communities. To enhance your experience and provide
									relevant suggestions, we collect information about your connections and interactions.
								</p>

								<p>
									<b>Friends, Followers, and Other Connections:</b> We gather data on the individuals and
									communities you are connected to and interact with. This includes tracking how you engage with
									them across our platform and identifying the ones with whom you interact most frequently.
								</p>

								<p>
									<b>Contacts Information:</b> If you choose to upload or import your contacts from a device,
									such as syncing an address book, we collect information about your contacts, such as their
									names, email addresses, or phone numbers.
								</p>

								<p>
									<b>Data Collection without an Account: </b>Even if you use our eMagz platform without creating
									an account, please be aware that certain information might still be collected.
								</p>

								<p>Information Inferred from Others' Activity:</p>

								<p>
									We also collect information about you based on the activities of others. Additionally, we make
									inferences about you based on the actions of others. For instance:
								</p>

								<p>
									People You May Know: We may suggest friends to you through eMagz's "People You May Know"
									feature if both you and the suggested individual appear on someone's contact list that has
									been uploaded.
								</p>

								<p>
									Group Recommendations: When suggesting groups for you to join, we consider whether your
									friends are already part of those groups.
								</p>

								<p>
									These inferences help us provide personalized recommendations, improve your overall
									experience, and facilitate connections with relevant individuals and communities.
								</p>

								<p>
									We prioritize the privacy and security of your information and handle it in accordance with
									applicable laws and regulations.
								</p>
							</>

							<li className={styles.li}>App, Browser, and Device Information:</li>
							<>
								<p>
									To ensure the optimal functioning of our platform and improve user experience, we collect and
									receive information from various devices and applications. Here are some details regarding the
									device information we collect and receive:
								</p>

								<p>
									<b>Device and Software Details:</b> We gather information about the device and software you
									use, including device type, operating system, and other relevant characteristics that help us
									tailor our services to your specific device.
								</p>

								<p>
									<b>Activity Monitoring:</b> We observe your device's activities, such as whether our app is
									actively being used or if mouse movements are detected. This helps us distinguish between
									human users and bots, ensuring a secure and genuine user experience.
								</p>

								<p>
									Device Identifiers: We utilize identifiers that differentiate your device from others,
									including Family device IDs or similar identifiers that help us recognize multiple devices
									used within a family or household.
								</p>

								<p>
									<b>Device Signals:</b> We receive signals from your device that assist us in optimizing our
									platform's performance and delivering a seamless user experience.
								</p>

								<p>
									<b>Device Settings:</b> If you provide consent through your device settings, we may collect
									information such as GPS location, camera access, photos, and other settings that you have
									shared with us. This enables us to provide location-based services and enhance certain
									features of our platform.
								</p>

								<p>
									<b>Network Information:</b> We gather information about the network you connect your device
									to, including connection type and speed, to ensure smooth connectivity and deliver content
									effectively. Additionally, we may utilize IP addresses to estimate your general location, even
									if location services are disabled on your device.
								</p>

								<p>
									<b>Product Performance:</b> We collect information about how our platform perform on your
									device, allowing us to optimize their functionality and improve user satisfaction.
								</p>

								<p>
									<b>Cookies and Similar Technologies:</b> We leverage cookies and similar technologies to
									collect information about your browsing behavior, preferences, and interactions with our
									platform. This data helps us personalize content, deliver targeted advertisements, and enhance
									your overall experience.
								</p>

								<p>
									We prioritize the security and privacy of your information and handle it in compliance with
									applicable laws and regulations. Information from Partners, Vendors, and Third Parties:
								</p>

								<p>
									In order to enhance your experience and provide you with relevant content, we collect and
									receive information from various partners, vendors, and third parties. Here's an overview of
									the types of information we collect or receive:
								</p>

								<p>
									<b>Device Information:</b> We gather information about your device from our partners,
									including details about the device you're using to access our platform.
								</p>

								<p>
									<b>Website Visits and Cookie Data:</b> We receive information about the websites you visit and
									collect cookie data, such as through social plugins. This enables us to personalize your
									experience and deliver targeted content.
								</p>

								<p>
									<b>App Usage: </b>
									We collect information about the apps you use, helping us understand your preferences and
									improve our services accordingly.
								</p>

								<p>
									<b>Game Interactions:</b> If you play games on our platform, we receive information about your
									game interactions to enhance your gaming experience and provide tailored recommendations.
								</p>

								<p>
									<b>Purchases and Transactions:</b> When you make purchases or engage in transactions outside
									of our platform, our partners share information about those activities with us. This helps us
									personalize your advertising experience and understand your preferences.
								</p>

								<p>
									<b>Demographic Data:</b> We receive demographic information about you, such as your education
									level, to better understand our user base and improve our services.
								</p>

								<p>
									<b>Ad Interactions:</b> We collect data about the ads you see and how you interact with them.
									This helps us optimize our advertising strategies and provide you with more relevant content.
								</p>

								<p>
									<b>Partner Interactions:</b> Our partners, who use or integrate our products for advertising,
									marketing, or support purposes, share information with us. This can include your email
									address, cookies, and advertising device ID, allowing us to connect your activities with your
									account.
								</p>

								<p>
									<b>Tools for Businesses:</b> We offer tools to businesses and organizations that allow them to
									share information about people's interactions on their websites or apps with us. This helps us
									improve ad targeting and measure ad effectiveness. We ensure that our partners have the
									necessary rights to collect, use, and share your information before sharing it with us.
								</p>

								<p>
									<b>Communication and Privacy:</b> Our partners may share their communications with you if they
									request our services for managing their communications. If you have any questions about how a
									specific business processes or shares your information, we recommend reviewing their Privacy
									Policy or contacting them directly.
								</p>

								<p>
									Our partners utilize our business tools, integrations, and other network technologies to share
									information with us. They collect your information when you visit their sites or apps, use
									their services, or through collaborations with other businesses or organizations.
								</p>

								<p>
									We prioritize the privacy and security of your information and ensure that our partners comply
									with relevant data protection regulations.
								</p>
							</>

							<li className={styles.li}>How we display ads and sponsored content:</li>
							<>
								<p>
									As you engage with our platform, you will encounter ads and sponsored or commercial content,
									such as product listings in Shops. Additionally, when you visit other apps, you may come
									across ads shown through eMagz. Our aim is to ensure that everything you see is interesting
									and valuable to you.
								</p>

								<p>
									To determine the most relevant content to show you and others, we utilize the information we
									have about you, which includes:
								</p>

								<ol>
									<li>
										Profile Information: We consider the details provided in your profile to better understand
										your preferences and tailor the content accordingly.
									</li>
									<li>
										Activity on and off our Platform: We take into account your activity both within and
										outside of our products, including information gathered through the use of cookies and
										similar technologies, in accordance with your privacy settings.
									</li>
									<li>
										Content Creation and Interaction: The content you create or engage with across eMagz plays
										a role in shaping the content we show you. This helps us deliver personalized
										recommendations and advertisements.
									</li>
									<li>
										Inferences: Based on the available data, we make inferences about your interests and
										preferences. This enables us to suggest topics and content that we believe may be of
										interest to you.
									</li>
									<li>
										Connections and Their Activity: Information about your friends, followers, and other
										connections, including their activity or interests, is taken into account to provide you
										with content that aligns with your social circle.
									</li>
								</ol>

								<p>
									By leveraging this information, we strive to create a personalized and relevant experience for
									each user. Safeguarding your privacy and adhering to applicable data protection regulations
									are fundamental principles guiding our practices
								</p>

								<p>How we utilize information to enhance our products:</p>

								<p>
									Our continuous goal is to improve our existing products and develop new ones that align with
									your preferences. The information we collect from you plays a crucial role in helping us
									achieve these objectives. We use the information in the following ways:
								</p>

								<ol>
									<li>
										Product Evaluation: The data we gather allows us to assess whether our products are
										functioning correctly. If any issues arise, we can troubleshoot and resolve them promptly.
									</li>
									<li>
										Testing New Products and Features: The information you provide enables us to test new
										products and features, determining their effectiveness and usefulness before introducing
										them to the wider user base.
									</li>
									<li>
										Gathering Feedback: We rely on the information collected to seek feedback on our product
										ideas and proposed features. This input from users helps us refine our concepts and create
										products that better meet your needs.
									</li>
									<li>
										Conducting Surveys and Research: We leverage the information we collect to conduct surveys
										and other research activities. This helps us gain insights into your preferences, what you
										appreciate about our products and brands, and areas where we can improve.
									</li>
								</ol>
							</>

							<li className={styles.li}>Utilization of Location-Related Information:</li>
							<>
								<p>
									If you grant us permission to receive location-related information by enabling the Location
									Services device setting, we make use of that data, including your GPS location and other
									location-related details based on your operating system.
								</p>
								<p>
									Furthermore, we may receive and utilize certain location-related information even if you have
									turned off location services. This can include:
								</p>

								<ul>
									<li>
										<b>IP Addresses:</b> We may use IP addresses to estimate your general location. In certain
										circumstances, if necessary to ensure the safety and security of you and others, we may
										estimate your specific location based on IP addresses.
									</li>
									<li>
										<b>Platform Activity:</b> Your activity on our platform, such as check-ins and events,
										contributes to our understanding of your location preferences.
									</li>
									<li>
										<b>Directly Provided Information:</b> Details you provide directly, such as entering your
										current city on your profile or providing your address in Marketplace, assist us in
										determining your location-related information.
									</li>
								</ul>
							</>

							<li className={styles.li}>
								This location-related information is utilized to fulfill the purposes described in our policies,
								including:
							</li>
							<>
								<ul>
									<li>
										Personalizing and improving our products, including advertisements, for both you and other
										users.
									</li>
									<li>Detecting suspicious activity and enhancing the security of your account.</li>
								</ul>

								<p>
									We prioritize the protection and privacy of your information and employ safeguards to ensure
									compliance with relevant laws and regulations.
								</p>

								<p>
									The sharing of your information on our platform and with integrated partners is primarily
									determined by your choices and the audience settings you select. Here's how it works:
								</p>

								<p>
									People and Accounts You Interact With: When you share and communicate using our products, the
									visibility of your content is dependent on the audience you choose for each specific sharing
									instance. The following aspects can be seen by those you interact with:
								</p>

								<ol>
									<li>
										What You Share: This includes posts, photos, videos, stories, news articles, and any
										additional information you add to your profile. The audience you select determines who can
										see this content.
									</li>
									<li>
										Your Communications: People or businesses you interact with can view the messages you send
										to them. For example, if you send a message to an individual or a business on "eMagz,"
										they will be able to read your message.
									</li>
									<li>
										Some of Your Activity: People and businesses can also see certain aspects of your activity
										on our platform. This includes interactions such as commenting on or reacting to posts,
										engaging with ads or sponsored content through comments or likes, and utilizing features
										like active status or last usage.
									</li>
								</ol>

								<p>
									Content Shared or Reshared About You: Content shared by others that involves you can be
									visible to different audiences based on their sharing preferences. For instance:
								</p>

								<ol>
									<li>
										Who Can See or Reshare Your Content: The audience you choose for your content can view and
										potentially share it with others, both within and outside of your immediate connections on
										our products. If you share a post or send a message to specific friends, they may choose
										to download, screenshot, or reshare it with anyone on or off our products.
									</li>
									<li>
										Visibility of Comments and Reactions: When you comment on a post or react to a photo, your
										comment or reaction can be seen by anyone who can view the post or photo, including
										individuals who are not directly connected to you. Additionally, the person who initially
										shared the post can modify the audience settings at any time, potentially altering who can
										see your interaction.
									</li>
								</ol>
								<p>
									How Information About You Can Be Shared: Users of our products have the ability to share
									information about you based on their own choices. For example, they can:
								</p>

								<ol>
									<li>
										<b>Share Content Involving You:</b>
										Others can share photos, videos, posts, comments, stories, reels, or messages that feature
										you.
									</li>
									<li>
										<b>Mention or Tag You:</b>
										You may be mentioned or tagged in posts, comments, stories, or locations by others.
									</li>
									<li>
										<b>Share Details About You:</b>
										Individuals can share various details about you in posts, stories, or messages.
									</li>
								</ol>
								<p>
									It's essential to review and manage your privacy settings to control the visibility of your
									information and interactions on our platform. For more comprehensive information about privacy
									settings and managing your data, please consult our detailed Privacy Policy.
								</p>
								<p>
									We maintain a strict policy of not selling any of your information to any party, and we will
									continue to uphold this principle. Furthermore, we impose guidelines and requirements on our
									partners and third parties to ensure that they handle the information we provide in accordance
									with specific rules regarding its use and disclosure.
								</p>
								<p>Regarding advertisers and Audience Network publishers:</p>
								<p>
									<b>Advertisers:</b> We offer advertisers reports that provide insights into the number and
									types of individuals who view and engage with their ads. These reports include general
									demographic and interest information about the audience that interacted with an advertiser's
									ad, enabling advertisers to gain a better understanding of their target audience.
									Additionally, we provide advertisers and their vendors with information about:
								</p>
								<ol>
									<li>Ads that received engagement from individuals, if applicable.</li>
									<li>Timing of the engagement with ads.</li>
									<li>
										Platforms on which the ads were displayed However, we do not share information with these
										advertisers and vendors that can be used on its own to directly contact or identify you,
										such as your name or email address, unless you explicitly grant us permission to do so.
									</li>
								</ol>

								<p>
									Audience Network: Through Audience Network, we enable advertisers to place ads on other apps
									and websites beyond the "eMagz" platform. To facilitate the display of ads on these external
									platforms, we share information with publishers who utilize Audience Network and with vendors
									who assist in the implementation of these ads. Specifically, we share:
								</p>

								<ol>
									<li>Data on the number of people who view and engage with ads on publisher apps.</li>
									<li>
										Information that is relevant to a publisher's request to serve an ad on their app or in
										response to it.
									</li>
								</ol>

								<p>
									Similar to our approach with advertisers, we do not share information with these publishers
									and their vendors that can be utilized independently to directly contact or identify you, such
									as your name or email address, unless you have explicitly given us permission to do so.
								</p>

								<p>We value your privacy and are committed to maintaining the security of your information.</p>
								<p>Managing or Deleting Your Information and Exercising Your Rights:</p>
								<p>
									We provide you with a range of tools to manage, download, and delete your information. These
									tools can be accessed through various avenues, including the settings within the platform you
									use. Additionally, you may have additional privacy rights as outlined by applicable laws.
								</p>

								<p>
									To exercise your rights, you can visit our Help Centers, access your settings on the "eMagz"
									platform, and utilize the settings available on your device.
								</p>
							</>

							<li className={styles.li}>Deleting Your Information or Account:</li>
							<>
								<p>
									You have the ability to delete specific information using the provided tools. For instance,
									you can utilize Delete buttons to remove content you have posted on your account. We also
									offer bulk deletion options, such as sending content to the recycle bin on "eMagz." Once
									content is deleted, it becomes invisible to other users.
								</p>

								<p>
									Within the Manage activity section of your activity log on the iPhone, Android, or Emagz app,
									you can manage certain content you share on “eMagz”, such as posts, photos, and videos. From
									here, you can select and choose to:
								</p>

								<ol>
									<li>
										Move to Archive: When you move content to the Archive, it will only be visible to you. If
										you decide to restore the content, it will be visible to the audience it was shared with
										before being moved to the Archive.
									</li>
									<li>
										Move to Recycle Bin: Content moved to the Recycle Bin remains there for 30 days before
										being automatically deleted. During this period, only you can see the content. You have
										the option to restore the content, move it to the Archive, or select content for deletion
										before the 30-day period ends. Restored content will be visible to the original audience
										it was shared with.
									</li>
								</ol>
								<p>
									Please note that not all content in your activity log is eligible for Archive or Recycle Bin
									options. The Manage activity section displays content that can be moved.
								</p>
							</>

							<li className={styles.li}>Permanently Deleting Your Account:</li>
							<>
								<p>
									If you choose to permanently delete your account on "eMagz," we will delete all associated
									information, including your posted content such as photos and status updates, unless retention
									is required as stated in our Terms of Service and Privacy Policy.
								</p>
							</>

							<li className={styles.li}>Data Retention:</li>
							<>
								<p>
									We retain information for as long as it is necessary to provide our products, fulfill legal
									obligations, or protect our interests and the interests of others. The retention period is
									determined on a case-by-case basis, considering factors such as:
								</p>

								<ol>
									<li>Operational requirements to maintain your account.</li>
									<li>The specific features and functioning of the information.</li>
									<li>Legal obligations that mandate retention.</li>
									<li>
										Legitimate purposes such as preventing harm, investigating violations, promoting safety
										and security, and protecting our rights, property, or products.
									</li>
								</ol>
								<p>
									In certain cases and for specific reasons, we may retain information for an extended duration.
									Refer to our detailed policies for further information on instances where your information may
									be preserved.
								</p>
								<p>
									Please note that once your account is permanently deleted, reactivation is not possible, and
									retrieval of information, including posted content, is not feasible.
								</p>
							</>

							<li className={styles.li}>Deletion Timeline:</li>
							<>
								<p>
									If you request the deletion of your account or content, the process may take up to 90 days to
									complete. This includes the account deletion process initiation or content deletion request.
									After information is deleted, an additional 90-day period may be required to remove it from
									backups and disaster recovery systems.
								</p>
								<p>
									Content left in the recycle bin or the Recently Deleted folder on our platform will initiate
									the automatic deletion process after 30 days. Alternatively, you can expedite the deletion
									process by manually removing the content from the recycle bin or Recently Deleted folder.
								</p>

								<p>We value transparency and respect your choices regarding your information.</p>

								<p>
									In the event of significant changes to this Policy, we will provide you with prior
									notification. You will have the opportunity to review the revised Policy before deciding
									whether to continue using our platform.
								</p>
							</>
						</ul>
					</article>
				</div>
			</div>
		</div>
	);
};

export default PrivacyPolicy;
