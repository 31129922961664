import React, { Suspense, lazy, useEffect, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/images/logo.png";
import Loading from "../../components/Hooks/Loading";
import axios from "../../components/Hooks/axios";
import styles from "./ChoseTemplate.module.scss";
const ImageComponent = lazy(() => import("./ImageComponent"));

const ChoseTemplate = () => {
	const navigate = useNavigate();
	const { userId, user, token } = useSelector((state) => state.auth);
	const [activeBtn, setActiveBtn] = useState(user?.accountType === "professional" ? "Business" : "Individual"); //Individual, Business
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [activeTemplate, setActiveTemplate] = useState("");
	const [templateData, setTemplateData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [offset, setOffset] = useState(0);

	const handelYes = () => {
		const data = JSON.stringify({
			userId: userId,
			templateId: activeTemplate?._id,
		});

		axios
			.post("/persona/user-template", data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(({ data }) => {
				toast.success("Template Chose Successfully");
				navigate("/PersonaRedirect", { replace: true });
				// window.open(`${process.env.REACT_APP_TEMPLATE_BASE_URL}/${userId}/${userId}/${token}`, "_self");
			})
			.catch(({ response }) => {
				console.log(response);
			});
	};

	useEffect(() => {
		setIsLoading(true);
		axios
			.get(`/persona/template?type=${activeBtn}&skip=${offset}&limit=100`)
			.then(({ data }) => {
				setIsLoading(false);
				const temp = [];
				for (let item of data) {
					temp.push({
						thumbnail2: item.thumbnail2,
						_id: item._id,
					});
				}
				setTemplateData(temp);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [activeBtn]);

	return (
		<div
			className={styles.ChoseTemplate}
			onScroll={(e) => {
				// if (e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight) setOffset(offset + 6);
			}}>
			<div className={styles.Top}>
				<div className={styles.Left}>
					<img src={logo} alt="" />
					<div>
						<h3>Choose Your Persona</h3>
						<h4>Please choose your Persona </h4>
					</div>
				</div>

				<div className={styles.Right}>
					{user?.accountType === "personal" && (
						<button
							className={activeBtn === "Individual" ? styles.active : ""}
							onClick={() => setActiveBtn("Individual")}>
							Personal
						</button>
					)}
					{user?.accountType === "business" && (
						<button
							className={activeBtn === "Business" ? styles.active : ""}
							onClick={() => setActiveBtn("Business")}>
							Business
						</button>
					)}
				</div>

				<div className={styles.BackBtn} onClick={() => navigate(-1)}>
					{"<"}
				</div>
			</div>

			<Suspense fallback={<Loading />}>
				<div className={styles.Bottom}>
					{templateData.map((data, index) => (
						<ImageComponent key={index} {...{ data, token, setConfirmPopup, setActiveTemplate, activeBtn }} />
					))}
				</div>
			</Suspense>

			<div className={styles.loadingContainer}>
				{isLoading && <FallingLines color="#3A0DBB" width="100" visible={true} ariaLabel="falling-lines-loading" />}
			</div>

			{confirmPopup && (
				<div className={styles.PopupContainer}>
					<div className={styles.Popup}>
						<div className={styles.TopPopup}>
							<h1>Do you want to use this persona ?</h1>
							<h2>You can always change your persona later</h2>
						</div>
						<div className={styles.BottomPopup}>
							<button onClick={handelYes}>Yes</button>
							<button
								onClick={() => {
									setConfirmPopup(false);
									setActiveTemplate("");
								}}>
								No
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ChoseTemplate;
