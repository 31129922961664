import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import ls from "localstorage-slim";
import { socket } from "../../App";

const initialState = {
	users: [],
};

const accountSlice = createSlice({
	name: "accountSlice",
	initialState,
	reducers: {
		fetchUserAccount: (state) => {
			const users = ls.get("AllUsers");
			if (users) state.users = users;
		},

		addAccount: (state, { payload }) => {
			const data = state.users.find((obj) => payload.userId === obj.userId);
			if (data === undefined) {
				state.users.push({ userId: payload.userId, user: payload.user, token: payload.token });
				ls.set("AllUsers", state.users);
			}
		},

		removeUser: (state, { payload }) => {
			socket.emit("leaveLogoutRoom", payload);
			Cookies.remove("E_persona_userId");
			const updatedUser = state.users.filter((item) => item.userId !== payload);
			state.users = updatedUser;
			ls.set("AllUsers", state.users);
		},
	},
});

export const { fetchUserAccount, addAccount, removeUser } = accountSlice.actions;

export default accountSlice.reducer;
