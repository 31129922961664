import React, { useEffect, useRef, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { toast } from "react-toastify";
import CameraIcon from "../../assets/svg/CameraIcon.svg";
import RightArrow from "../../assets/svg/RightArrow";
import Header from "../../components/Header/Header";
import ScrollToTop from "../../components/Hooks/ScrollToTop";
import axios from "../../components/Hooks/axios";
import { logOut, setUserObject, switchAccount } from "../../redux/slices/authSlice";
import { setNotificationSound } from "../../redux/slices/notificationSlice";
import styles from "./Setting.module.css";
import SwitchAccount from "./SwitchAccount/SwitchAccount";
import { removeUser } from "../../redux/slices/accountSlice";

const Setting = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { userId, user } = useSelector((state) => state.auth);
	const { users } = useSelector((state) => state.account);
	const [isLoading, setIsLoading] = useState(false);
	const ImageRef = useRef(null);
	const [ActiveStatus, setActiveStatus] = useState(true);
	const [EnableLocation, setEnableLocation] = useState(false);
	const [PlaySoundNotification, setPlaySoundNotification] = useState(false);
	const [DesktopNotification, setDesktopNotification] = useState(false);
	const [bioText, setBioText] = useState("");
	const [editBio, setEditBio] = useState(false);
	const [addAccountPopup, setAddAccountPopup] = useState(false);

	useEffect(() => {
		if (userId === "") return;

		setActiveStatus(user.is_active);
		setEnableLocation(user.enable_location);
		setPlaySoundNotification(user.notification_sound);
		dispatch(setNotificationSound(user.notification_sound));
		setDesktopNotification(user.desktop_notification);
		setBioText(user?.bio);

		axios
			.get(`/auth/get-user-details/${userId}`)
			.then(({ data }) => {
				dispatch(setUserObject(data));
				setBioText(data.bio);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [userId]);

	const handelActiveSwitch = (e) => {
		const id = toast.loading("Please Wait..");
		axios
			.get(`/auth/make-active-inactive?status=${e}`)
			.then(({ data }) => {
				setActiveStatus(e);
				dispatch(setUserObject(data.data));
				toast.update(id, {
					render: "Your Active Status Change",
					type: "success",
					isLoading: false,
					autoClose: 2000,
				});
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const handelSwitchButtons = (e, key) => {
		const id = toast.loading("Please Wait..");

		const raw = JSON.stringify({
			privacy_type: key,
			value: e,
		});

		axios
			.post(`/auth/update-user-privacy`, raw, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(({ data }) => {
				if (key === "notification_sound") {
					setPlaySoundNotification(e);
					dispatch(setNotificationSound(e));
					toast.update(id, {
						render: "Your Notification Sound Status Change",
						type: "success",
						isLoading: false,
						autoClose: 2000,
					});
				}
				if (key === "desktop_notification") {
					setDesktopNotification(e);
					toast.update(id, {
						render: "Your Desktop Notification Status Change",
						type: "success",
						isLoading: false,
						autoClose: 2000,
					});
				}
				dispatch(setUserObject(data.data));
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const handelProfileImageChange = (e) => {
		let file = e.target.files[0];
		if (file) {
			setIsLoading(true);
			const id = toast.loading("Picture Uploading !");
			const formData = new FormData();
			formData.append("ProfilePic", file);
			axios
				.post(`/auth/upload-profile-picture?userId=${user?._id}`, formData)
				.then(({ data }) => {
					if (data.success) {
						setIsLoading(false);
						toast.update(id, {
							render: "Profile Picture Uploaded Successfully !",
							type: "success",
							isLoading: false,
							autoClose: 2000,
						});
						dispatch(setUserObject(data.data));
					}
				})
				.catch((e) => {
					console.log(e);
				});
		}
		e.target.value = null;
	};

	const handelUpdateBio = () => {
		axios
			.post(`/auth/update-user-bio`, {
				bio: bioText,
			})
			.then(({ data }) => {
				toast.success("Bio Updated Successfully");
				setEditBio(false);
				dispatch(setUserObject({ ...user, bio: bioText }));
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const handelLocation = (btnState) => {
		if (!navigator.geolocation) return toast.warn("This Browser doesn't support location !!");
		if (!navigator.permissions) return toast.warn("This Browser doesn't support permissions API !!");

		navigator.permissions
			.query({ name: "geolocation" })
			.then((result) => {
				if (result.state === "granted") getCurrentPosition();
				else if (result.state === "prompt") requestGeolocationPermission();
				else return toast.error("Geolocation permission denied or unavailable.");
			})
			.catch((e) => {
				console.log(e);
			});

		function requestGeolocationPermission() {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					handelAddLocation(latitude, longitude);
				},
				(error) => {
					console.error(`Error getting geolocation: ${error.message}`);
				}
			);
		}

		const getCurrentPosition = () => {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					handelAddLocation(latitude, longitude);
				},
				(error) => {
					console.error(`Error getting geolocation: ${error.message}`);
				}
			);
		};

		let id;
		const handelAddLocation = (latitude, longitude) => {
			id = toast.loading("Please Wait..");
			axios
				.put(`/auth/update-user-details?userId=${userId}`, {
					latitude,
					longitude,
				})
				.then(({ data }) => {
					dispatch(setUserObject(data.user));
					handelButtonChange();
				})
				.catch((e) => {
					console.log(e);
				});
		};

		const handelButtonChange = () => {
			axios
				.post(`/auth/update-user-privacy`, {
					privacy_type: "enable_location",
					value: btnState,
				})
				.then(({ data }) => {
					setEnableLocation(btnState);
					toast.update(id, {
						render: "Your Location Status Change",
						type: "success",
						isLoading: false,
						autoClose: 2000,
					});
					dispatch(setUserObject(data.data));
				})
				.catch((e) => {
					console.log(e);
				});
		};
	};

	const handelLogout = () => {
		toast.success("Logout SuccessFully!!");
		if (users.length === 1) {
			dispatch(logOut());
			navigate("/", { replace: true });
			dispatch(removeUser(users[0].userId));
		} else {
			const data = users.find((obj) => userId === obj.userId);
			const index = users.findIndex((obj) => userId === obj.userId);
			if (index === 0) {
				dispatch(switchAccount({ userId: users[1].userId, user: users[1].user, token: users[1].token }));
				dispatch(removeUser(data.userId));
			} else {
				dispatch(switchAccount({ userId: users[0].userId, user: users[0].user, token: users[0].token }));
				dispatch(removeUser(data.userId));
			}
		}
		// window.location.reload();
	};

	return (
		<div className="App">
			<div className="MainContainer">
				<ScrollToTop />

				<Header />

				{addAccountPopup && <SwitchAccount {...{ setAddAccountPopup }} />}

				<div className={`${styles.Container} ${styles.Setting}`}>
					<div className={styles.LeftSettingContainer}>
						<h1 className={styles.SettingText}>Setting</h1>

						<h5 className={styles.AccountText}>Account</h5>

						<div className={styles.AccountDiv}>
							<div className={styles.AccountDivTop}>
								<div className={styles.ProfileImageContainer}>
									<div className={styles.ProfileImg}>
										{/* <img src={ImageURL ? ImageURL : ProfileImage1} alt="" /> */}
										{isLoading ? (
											<div className={styles.loadingContainer}>
												<FallingLines
													color="#3A0DBB"
													width="100"
													visible={true}
													ariaLabel="falling-lines-loading"
												/>
											</div>
										) : (
											<img src={user?.ProfilePic} alt="" />
										)}
									</div>
									<button
										className={styles.SelectImageBtn}
										type="button"
										onClick={() => ImageRef.current.click()}>
										<img src={CameraIcon} alt="" />
									</button>
									<input type="file" accept="image/*" ref={ImageRef} onChange={handelProfileImageChange} />
								</div>

								<div className={styles.NameContainer}>
									<h1>{user?.username}</h1>
									<p>@{user?.GetstatedName}</p>
								</div>
							</div>

							<div className={styles.AccountDivBottom}>
								<div className={styles.TopSectionDiv}>
									<h1>Bio</h1>

									<button
										onClick={() => {
											if (editBio) {
												handelUpdateBio();
											} else {
												setEditBio(true);
												setBioText(user?.bio);
											}
										}}>
										{editBio ? "Save" : "Edit Bio"}
									</button>
								</div>

								<textarea
									value={bioText}
									readOnly={!editBio}
									style={{ border: editBio ? "1px solid #3a0dbb" : "none" }}
									onChange={(e) => setBioText(e.target.value)}></textarea>

								<div className={styles.AccountDivBottomBottom}>
									<button type="button" onClick={() => toast.info("Comming Soon...")}>
										Change to business account
									</button>
									<button type="button" onClick={() => navigate("/ChoseTemplate")}>
										Change Persona
									</button>
								</div>
							</div>
						</div>

						<div className={styles.ActiveContainer}>
							<h1 className={styles.ActiveText}>Active Status</h1>
							<p className={styles.ActiveTextP}>Your friends and contacts will see when you’re active </p>
							<div className={styles.SwitchContainer}>
								<Switch
									onChange={(e) => handelActiveSwitch(e)}
									checked={ActiveStatus}
									offColor={"#c4c4c4"}
									onColor={"#EFEFEF"}
									// offHandleColor={""}
									onHandleColor={"#297C45"}
									uncheckedIcon={false}
									checkedIcon={false}
									activeBoxShadow={"0 0 0px 0px #909AFF"}
									height={30}
									width={64}
								/>
								<p className={styles.SwitchText}>Show my friends and contact when i’m active</p>
							</div>
						</div>

						<button type="button" className={styles.LogOutBtn} onClick={handelLogout}>
							Log Out
						</button>

						<Link className={styles.AddAccountText} onClick={() => setAddAccountPopup(true)}>
							Add another account
						</Link>
					</div>

					<div className={styles.RightSettingContainer}>
						<h1 className={styles.PreferenceText}>Preference</h1>

						<div className={styles.PreferenceContainer} onClick={() => navigate("/Privacy")}>
							<div>
								<h3>Privacy</h3>
								<p>Manage your privacy</p>
							</div>
							<RightArrow />
						</div>

						<div className={styles.PreferenceContainer} onClick={() => navigate("/Security")}>
							<div>
								<h3>Security</h3>
								<p>Manage your security aand secure your profile</p>
							</div>
							<RightArrow />
						</div>

						<div className={styles.PreferenceContainerSwitch}>
							<h3 className={styles.h31}>Enable my location</h3>
							<Switch
								onChange={(e) => handelLocation(e)}
								checked={EnableLocation}
								offColor={"#EEEEEE"}
								onColor={"#EEEEEE"}
								offHandleColor={"#959595"}
								onHandleColor={"#297C45"}
								uncheckedIcon={false}
								checkedIcon={false}
								activeBoxShadow={"0 0 0px 0px #909AFF"}
								height={30}
								width={64}
							/>
						</div>

						<div className={styles.PreferenceContainerSwitch}>
							<h3 className={styles.h32}>Play Sound When i get notification</h3>
							<Switch
								onChange={(e) => handelSwitchButtons(e, "notification_sound")}
								checked={PlaySoundNotification}
								offColor={"#EEEEEE"}
								onColor={"#EEEEEE"}
								offHandleColor={"#959595"}
								onHandleColor={"#297C45"}
								uncheckedIcon={false}
								checkedIcon={false}
								activeBoxShadow={"0 0 0px 0px #909AFF"}
								height={30}
								width={64}
							/>
						</div>

						<div className={styles.PreferenceContainerSwitch}>
							<h3 className={styles.h33}>Enable Desktop notification</h3>
							<Switch
								onChange={(e) => handelSwitchButtons(e, "desktop_notification")}
								checked={DesktopNotification}
								offColor={"#EEEEEE"}
								onColor={"#EEEEEE"}
								offHandleColor={"#959595"}
								onHandleColor={"#297C45"}
								uncheckedIcon={false}
								checkedIcon={false}
								activeBoxShadow={"0 0 0px 0px #909AFF"}
								height={30}
								width={64}
							/>
						</div>

						<div className={styles.BlockContainer}>
							<h1>Blocked Users</h1>
							<p>
								Once you blocked someone they will no longer to see things that you post on your feed , tag you ,
								invite you or start a conversation with you. However you can unblock them later if you want{" "}
							</p>
							<Link to="/BlockedUser">Manage blocked users</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Setting;
