import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Bg from "../../../components/BG/Bg";
import axios from "../../../components/Hooks/axios";
import classes from "./ProfessionalPersona.module.css";

const ProfessionalPersona = () => {
	const navigate = useNavigate();
	const { userId } = useSelector((state) => state.auth);

	const [name, setName] = useState("");
	const [professionType, setProfessionType] = useState("");
	const [logo, setLogo] = useState(null);
	const [skipDisable, setSkipDisable] = useState(false);
	const logoRef = useRef();

	const handelSetupAccount = () => {
		if (name === "" || professionType === "" || logo===null) return toast.warn("Please fill all the fields !");
		setSkipDisable(true);

		const formData = new FormData();
		formData.append("_id", userId);
		formData.append("businessLogo", logo);
		formData.append("businessName", name);
		formData.append("businessType", professionType);

		axios
			.post("auth/setup-professional-account", formData)
			.then(({ data }) => {
				toast.success(data.message);
				setSkipDisable(false);
				navigate("/get-started/congratulation", { replace: true });
			})
			.catch(({ response }) => {
				console.log(response);
			});
	};

	return (
		<div className={classes["main-container"]}>
			<Bg />
			<div className={classes.container}>
				<div>
					<h1 className={classes.heading}>
						Set up your <br /> Professional persona
					</h1>
					<p className={classes.text1}>Customize your Persona</p>
					<section className={classes["logo-section"]}>
						<div className={classes["logo-container"]} onClick={() => logoRef.current.click()}>
							{logo ? (
								<img src={URL.createObjectURL(logo)} alt="logo" className={classes.logo} />
							) : (
								<>
									<div>Upload</div>
									<div>Logo</div>
								</>
							)}
							<input
								type="file"
								ref={logoRef}
								onChange={(e) => setLogo(e.target.files[0])}
								style={{ display: "none" }}
							/>
						</div>
					</section>
					<div className={classes.input}>
						<div className={classes["input-field"]}>
							<label className={classes.text2} htmlFor="display-namel">
								Professional Name
							</label>
							<input
								type="text"
								className={classes["input-box"]}
								value={name}
								onChange={(e) => setName(e.target.value)}
								placeholder="Enter name"
							/>
						</div>
						<div className={classes["input-field"]}>
							<label className={classes.text2} htmlFor="display-namel">
								Profession Type
							</label>
							<input
								type="text"
								className={classes["input-box"]}
								value={professionType}
								placeholder="Select your profession"
								onChange={(e) => setProfessionType(e.target.value)}
							/>
						</div>
					</div>
					<div className={classes.actions}>
						<button
							disabled={skipDisable}
							onClick={handelSetupAccount}
							className={`${classes.skipNow} ${classes.activeBtn}`}>
							Continue
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfessionalPersona;
