import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setCustomPollKey, setCustomPollShow } from "../../redux/slices/tempSlice";
import styles from "./CustomPoll.module.scss";

const CustomPoll = () => {
	const dispatch = useDispatch();
	const [button1, setButton1] = useState("");
	const [button2, setButton2] = useState("");
	const [button3, setButton3] = useState("");
	const [button4, setButton4] = useState("");
	const [addButton, setAddButton] = useState(0);

	const handelContinue = () => {
		const customPollData = [];
		if (button1 != "") customPollData.push(button1);
		if (button2 != "") customPollData.push(button2);
		if (button3 != "") customPollData.push(button3);
		if (button4 != "") customPollData.push(button4);
		dispatch(setCustomPollKey(customPollData));
		dispatch(setCustomPollShow({ enableCustomPoll:true, customPollPopupShow:false }));
	};

	const handelCancel = () => {
		dispatch(setCustomPollKey([]));
		dispatch(setCustomPollShow({ enableCustomPoll:false, customPollPopupShow:false }));
		
	};

	return (
		<div className={styles.CustomPollWrapper} onClick={handelCancel}>
			<div className={styles.CustomPoll} onClick={(e) => e.stopPropagation()}>
				<div className={styles.Top}>
					<h1>Custom Poll</h1>
					<p>Min 2 and Max 4 Button </p>
				</div>

				<div className={styles.Middle}>
					<input
						type="text"
						placeholder="Enter button name"
						value={button1}
						onChange={(e) => setButton1(e.target.value)}
					/>
					<input
						type="text"
						placeholder="Enter button name"
						value={button2}
						onChange={(e) => setButton2(e.target.value)}
					/>
					{addButton >= 1 && (
						<input
							type="text"
							placeholder="Enter button name"
							value={button3}
							onChange={(e) => setButton3(e.target.value)}
						/>
					)}
					{addButton >= 2 && (
						<input
							type="text"
							placeholder="Enter button name"
							value={button4}
							onChange={(e) => setButton4(e.target.value)}
						/>
					)}
					{addButton <= 1 && (
						<button
							onClick={() => {
								setAddButton(addButton + 1);
							}}>
							Add Custom Name
						</button>
					)}
				</div>

				<div className={styles.Bottom}>
					<button onClick={handelCancel}>Cancel</button>
					<button onClick={handelContinue}>Continue</button>
				</div>
			</div>
		</div>
	);
};

export default CustomPoll;
