import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CaretLeft } from "../../../assets/icons/CaretLeft.svg";
import { ReactComponent as StarIcon } from "../../../assets/icons/StarIcon.svg";
import Header from "../../../components/Header/Header";
import ScrollToTop from "../../../components/Hooks/ScrollToTop";
import styles from "./UsersReviews.module.scss";

const UsersReviews = () => {
	const navigate = useNavigate();

	return (
		<div className="App">
			<div className="MainContainer">
				<ScrollToTop />
				<Header />

				<div className={`${styles.Container} ${styles.UsersReviews}`}>
					<div className={styles.Row1}>
						<div className={styles.Left}>
							<h1>Running ads</h1>
						</div>

						<div className={styles.Right} onClick={() => navigate(-1)}>
							<CaretLeft />
						</div>
					</div>

					<div className={styles.Row2}>
						{Array(30)
							.fill("")
							.map((data, index) => (
								<div className={styles.Box} key={index}>
									<h2>So Comfortable to wear and Nice Quality</h2>
									<p>
										Wore these with my training tee and pods to a graduation bbq and the young bucks was all
										over it. Love the look and feel.
									</p>

									<div className={styles.StarWrapper}>
										<StarIcon />
										<StarIcon />
										<StarIcon />
										<StarIcon />
										<StarIcon />
                                    </div>
                                    
                                    <hr />

                                    <div className={styles.Profile}>
                                        <img src="https://picsum.photos/500/500" alt="" />

                                        <div>
                                            <h4>Rohan</h4>
                                            <h5>@Rohan</h5>
                                        </div>
                                    </div>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UsersReviews;
