import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { toast } from "react-toastify";
import RightArrow from "../../assets/svg/RightArrow";
import search from "../../assets/svg/search.svg";
import axios from "../../components/Hooks/axios";
import { setUserObject } from "../../redux/slices/authSlice";
import styles from "./Privacy.module.scss";

const Comment = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const [showBox, setShowBox] = useState(false);
	const [Everyone, setEveryone] = useState(true);
	const [PeopleFollow, setPeopleFollow] = useState(false);
	const [YourFollowers, setYourFollowers] = useState(false);
	const [FollowFollowers, setFollowFollowers] = useState(false);
	const [Search, setSearch] = useState("");
	const [blockCommentFromBox, setBlockCommentFromBox] = useState(false);
	const [disableSwitch, setDisableSwitch] = useState(false);

	useEffect(() => {
		if (user === null) return;
		setEveryone(user?.comment_privacy?.everyone);
		setPeopleFollow(user?.comment_privacy?.follow);
		setYourFollowers(user?.comment_privacy?.followers);
		setFollowFollowers(user?.comment_privacy?.follow_and_followers);
	}, [user]);

	const handelSwitchBtn = (e, content) => {
		setDisableSwitch(true);
		let ev = Everyone;
		let pf = PeopleFollow;
		let yf = YourFollowers;
		let ff = FollowFollowers;

		if (content === "Everyone") {
			if (Everyone) return setDisableSwitch(false);
			ev = e;
			pf = false;
			yf = false;
			ff = false;
		}
		if (content === "PeopleFollow") {
			if (PeopleFollow) return setDisableSwitch(false);
			ev = false;
			pf = e;
			yf = false;
			ff = false;
		}
		if (content === "YourFollowers") {
			if (YourFollowers) return setDisableSwitch(false);
			ev = false;
			pf = false;
			yf = e;
			ff = false;
		}
		if (content === "FollowFollowers") {
			if (FollowFollowers) return setDisableSwitch(false);
			ev = false;
			pf = false;
			yf = false;
			ff = e;
		}

		const id = toast.loading("Please Wait..");

		setEveryone(ev);
		setPeopleFollow(pf);
		setYourFollowers(yf);
		setFollowFollowers(ff);

		const raw = JSON.stringify({
			comment_privacy: {
				everyone: ev,
				follow: pf,
				followers: yf,
				follow_and_followers: ff,
			},
		});

		axios
			.post("/auth/change-comment-privacy", raw, {
				headers: {
					"content-type": "application/json",
				},
			})
			.then(({ data }) => {
				setDisableSwitch(false);
				toast.update(id, {
					render: "Your Comment Privacy Successfully Updated",
					type: "success",
					isLoading: false,
					autoClose: 2000,
				});
				dispatch(setUserObject(data.data));
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<div className={styles.CommentContainer}>
			<div className={styles.CommentsBox}>
				<h1>Comments</h1>
				<p>Control</p>
				<div className={styles.CommentsInnerBox}>
					<h3>Allow comments from</h3>
					<div className={styles.Everyone} onClick={() => setShowBox(!showBox)}>
						<h4>
							{Everyone && "Everyone"}
							{PeopleFollow && "People You Follow"}
							{YourFollowers && "Your Followers"}
							{FollowFollowers && "Follow and Followers"}
						</h4>
						<RightArrow />
					</div>
				</div>

				<div className={styles.CommentsInnerBox}>
					<div className={styles.BlockCommentFrom}>
						<h3>Block comment from</h3>
						<p>Any new comment from people you block will not be visible to anyone but them</p>
					</div>
					<div className={styles.Everyone} onClick={() => setBlockCommentFromBox(!blockCommentFromBox)}>
						<h4>0 people</h4>
						<RightArrow />
					</div>
				</div>
			</div>

			{showBox && (
				<div className={styles.AllowCommentsFrom}>
					<h5>Allow comments from</h5>
					<div className={styles.AllowCommentsBox}>
						<div>
							<p>Everyone</p>
						</div>
						<Switch
							onChange={(e) => handelSwitchBtn(e, "Everyone")}
							checked={Everyone}
							disabled={disableSwitch}
							offColor={"#EEEEEE"}
							onColor={"#EEEEEE"}
							offHandleColor={"#959595"}
							onHandleColor={"#3A0DBB"}
							uncheckedIcon={false}
							checkedIcon={false}
							activeBoxShadow={"0 0 0px 0px #909AFF"}
							height={19}
							width={46}
						/>
					</div>
					<div className={styles.AllowCommentsBox}>
						<div>
							<p>People you follow</p>
							<span>53 people</span>
						</div>
						<Switch
							onChange={(e) => handelSwitchBtn(e, "PeopleFollow")}
							checked={PeopleFollow}
							disabled={disableSwitch}
							offColor={"#EEEEEE"}
							onColor={"#EEEEEE"}
							offHandleColor={"#959595"}
							onHandleColor={"#3A0DBB"}
							uncheckedIcon={false}
							checkedIcon={false}
							activeBoxShadow={"0 0 0px 0px #909AFF"}
							height={19}
							width={46}
						/>
					</div>
					<div className={styles.AllowCommentsBox}>
						<div>
							<p>Your followers</p>
							<span>500 people</span>
						</div>
						<Switch
							onChange={(e) => handelSwitchBtn(e, "YourFollowers")}
							checked={YourFollowers}
							disabled={disableSwitch}
							offColor={"#EEEEEE"}
							onColor={"#EEEEEE"}
							offHandleColor={"#959595"}
							onHandleColor={"#3A0DBB"}
							uncheckedIcon={false}
							checkedIcon={false}
							activeBoxShadow={"0 0 0px 0px #909AFF"}
							height={19}
							width={46}
						/>
					</div>
					<div className={styles.AllowCommentsBox}>
						<div>
							<p>People you follow and your followers</p>
							<span>550 people</span>
						</div>
						<Switch
							onChange={(e) => handelSwitchBtn(e, "FollowFollowers")}
							checked={FollowFollowers}
							disabled={disableSwitch}
							offColor={"#EEEEEE"}
							onColor={"#EEEEEE"}
							offHandleColor={"#959595"}
							onHandleColor={"#3A0DBB"}
							uncheckedIcon={false}
							checkedIcon={false}
							activeBoxShadow={"0 0 0px 0px #909AFF"}
							height={19}
							width={46}
						/>
					</div>
				</div>
			)}

			{blockCommentFromBox && (
				<div className={styles.BlockCommentFromSearch}>
					<p>Block comments from</p>
					<div>
						<img src={search} alt="Search" />
						<input type="search" placeholder="Search" value={Search} onChange={(e) => setSearch(e.target.value)} />
					</div>
				</div>
			)}
		</div>
	);
};

export default Comment;
