import domToImage from "dom-to-image";
import React, { useEffect, useRef, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import { SketchPicker } from "react-color";
import Draggable from "react-draggable";
import Cropper from "react-easy-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import rightArrow from "../../../assets/svg/arrowRightNew.svg";
import threeDot from "../../../assets/svg/more-2-fill.svg";
import music from "../../../assets/svg/music-fill.svg";
import text from "../../../assets/svg/text.svg";
import axios from "../../../components/Hooks/axios";
import styles from "../StoryWrapper.module.scss";
import getCroppedImg from "./cropImage";
import getFilterImg from "./filterImage";
import getMirrorImg from "./mirrorImage";
import getAdjustmentImg from "./adjustmentImage";

export const CropEasy = ({ imagesArray, setImagesArray, setOpenCrop, setSelectedImageShow, setStoryPopup, setOwnRel }) => {
	const { userId } = useSelector((state) => state.auth);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [rotation, setRotation] = useState(0);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	const [cropShow, setCropShow] = useState(false);
	const [rotateShow, setRotateShow] = useState(false);
	const [aspectRatioShow, setAspectRatioShow] = useState(false);
	const [aspectValue, setAspectValue] = useState(1 / 1);

	const [effectShow, setEffectShow] = useState(false);
	const [effectValue, setEffectValue] = useState(styles.EffectNormal);
	const [adjustmentShow, setAdjustmentShow] = useState(false);
	const [adjustmentValue, setAdjustmentValue] = useState({
		contrast: 100,
		brightness: 100,
		saturate: 100,
		huerotate: 0,
		sepia: 0,
		invert: 0,
		grayscale: 0,
		blur: 0,
	});

	const [textShow, setTextShow] = useState(false);
	const inputRef = useRef(null);
	const [draggable, setDraggable] = useState(false);
	const [fontSize, setFontSize] = useState(10);
	const [textRotation, setTextRotation] = useState(0);
	const [isMouseDown, setIsMouseDown] = useState(false);
	const [fontFamily, setFontFamily] = useState("Poppins");
	const [colorPicker, setColorPicker] = useState(false);
	const [bgColor, setBgColor] = useState({ r: 0, g: 0, b: 0, a: 1 });

	const [mirrorShow, setMirrorShow] = useState(false);
	const [mirrorValueX, setMirrorValueX] = useState(1);
	const [mirrorValueY, setMirrorValueY] = useState(1);

	const [imageWidth, setImageWidth] = useState(100);
	const [imageHeight, setImageHeight] = useState(100);
	const [activeIndex, setActiveIndex] = useState(0);
	const [activeImageURL, setActiveImageURL] = useState("");
	const [updatedImagesArray, setUpdatedImagesArray] = useState([]);

	const responsive = {
		0: {
			items: 2,
		},
		500: {
			items: 4,
		},
		600: {
			items: 5,
		},
		// 700: {
		// 	items: 6,
		// },
		// 800: {
		// 	items: 7,
		// },
		// 1024: {
		// 	items: 8,
		// },
	};

	const UpdateImageArrayWithNewData = async (file) => {
		const newArray = [...updatedImagesArray];
		newArray[activeIndex] = file;
		setUpdatedImagesArray(newArray);
	};

	const cropImage = async () => {
		try {
			const { file, url } = await getCroppedImg(activeImageURL, croppedAreaPixels, rotation);
			setActiveImageURL(url);
			setCropShow(false);
			setRotation(0);
			setAspectValue(1 / 1);
			UpdateImageArrayWithNewData(file);
		} catch (error) {
			console.log(error);
		}
	};

	const handelMirror = async () => {
		try {
			const { file, url } = await getMirrorImg(activeImageURL, mirrorValueX, mirrorValueY);
			setActiveImageURL(url);
			UpdateImageArrayWithNewData(file);
			setMirrorShow(false);
			setMirrorValueX(1);
			setMirrorValueY(1);
		} catch (error) {
			console.log(error);
		}
	};

	const handelFilter = async () => {
		try {
			const { file, url } = await getFilterImg(activeImageURL, effectValue);
			setActiveImageURL(url);
			UpdateImageArrayWithNewData(file);

			setEffectShow(false);
			setEffectValue(styles.EffectNormal);
		} catch (error) {
			console.log(error);
		}
	};

	const handelAdjustment = async () => {
		try {
			const { file, url } = await getAdjustmentImg(activeImageURL, adjustmentValue);
			setActiveImageURL(url);
			UpdateImageArrayWithNewData(file);

			setAdjustmentShow(false);
			setAdjustmentValue({
				contrast: 100,
				brightness: 100,
				saturate: 100,
				huerotate: 0,
				sepia: 0,
				invert: 0,
				grayscale: 0,
				blur: 0,
			});
		} catch (error) {
			console.log(error);
		}
	};

	const handelTextAdd = () => {
		if (inputRef.current) {
			inputRef.current.blur();
			inputRef.current.style.outline = "none";
			inputRef.current.style.resize = "none";
		}

		const MainEffectImage = document.getElementById("MainEffectImage");

		if (MainEffectImage) {
			domToImage
				.toPng(MainEffectImage)
				.then(function (dataUrl) {
					fetch(dataUrl)
						.then((res) => res.blob())
						.then((blob) => {
							const file = new File([blob], "Effect", { type: "image/png" });
							setActiveImageURL(URL.createObjectURL(file));
							UpdateImageArrayWithNewData(file);

							setTextShow(false);
							setDraggable(false);
							setTextRotation(0);
						});
				})
				.catch(function (error) {
					console.error("Error converting textarea to image:", error);
				});
		}
	};

	const onImageLoad = (e) => {
		setImageWidth(e.target.width);
		setImageHeight(e.target.height);

		// const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
		// const crop = centerCrop(
		// 	makeAspectCrop(
		// 		{
		// 			unit: "%",
		// 			width: 90,
		// 			height: 50,
		// 		},
		// 		16 / 9,
		// 		width,
		// 		height
		// 	),
		// 	width,
		// 	height
		// );

		// setCroppedAreaPixels(crop);
	};

	const handelRemoveImage = (indexToRemove) => {
		const updatedArray1 = imagesArray.filter((_, index) => index !== indexToRemove);
		const updatedArray2 = updatedImagesArray.filter((_, index) => index !== indexToRemove);
		setImagesArray(updatedArray1);
		setUpdatedImagesArray(updatedArray2);

		if (activeIndex === 0 && updatedArray2.length > 0) setActiveIndex(0);
		else setActiveIndex(activeIndex - 1);

		if (updatedArray2.length === 0) {
			setOpenCrop(false);
			setSelectedImageShow(true);
		}
	};

	useEffect(() => {
		setUpdatedImagesArray(imagesArray);
	}, []);

	useEffect(() => {
		if (updatedImagesArray.length === 0) return;
		setActiveImageURL(URL.createObjectURL(updatedImagesArray[activeIndex]));
	}, [activeIndex, updatedImagesArray]);

	useEffect(() => {
		if (textShow) inputRef?.current?.focus();
	}, [textShow]);

	useEffect(() => {
		if (draggable) inputRef?.current?.blur();
		else inputRef?.current?.focus();
	}, [draggable]);

	const handelUploadImage = () => {
		if (effectShow || adjustmentShow || cropShow || textShow) toast.warn("Please Finish Editing !!");
		const id = toast.loading("Story Uploading...");
		const formData = new FormData();
		formData.append("userId", userId);
		formData.append("mediaType", "image");
		updatedImagesArray.forEach((item) => {
			formData.append("mediaUrl", item);
		});

		axios
			.post("/story", formData)
			.then(({ data }) => {
				toast.update(id, { render: data.message, type: "success", isLoading: false, autoClose: 2000 });
				setOwnRel(Math.random());
				setStoryPopup(false);
				setOpenCrop(false);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<div className={styles.CropperMainWrapper}>
			<div className={styles.controlsBar}>
				<div className={styles.Right}>
					<img
						src={threeDot}
						alt=""
						onClick={() => {
							toast.info("Three Dot Coming Soon...");
						}}
					/>
					<img src={text} alt="" onClick={() => setTextShow(!textShow)} />
					<img
						src={music}
						alt=""
						onClick={() => {
							toast.info("Music Icon Coming Soon...");
						}}
					/>
				</div>
				<img src={rightArrow} alt="" onClick={handelUploadImage} />
			</div>

			<div className={styles.CropContainer}>
				{!effectShow && !adjustmentShow && !cropShow && !textShow && !mirrorShow && (
					<>
						{activeIndex > 0 && (
							<button
								className={styles.LeftBtn}
								onClick={() => {
									if (activeIndex > 0) setActiveIndex(activeIndex - 1);
								}}>
								<svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
									<path
										d="M24.4661 27.0538L35.3633 16.1565L32.2498 13.043L18.2389 27.0538L32.2498 41.0644L35.3633 37.9509L24.4661 27.0538Z"
										fill="white"
									/>
								</svg>
							</button>
						)}

						{activeIndex < imagesArray.length - 1 && (
							<button
								className={styles.RightBtn}
								onClick={() => {
									if (activeIndex < imagesArray.length - 1) setActiveIndex(activeIndex + 1);
								}}>
								<svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
									<path
										d="M29.4675 27.0538L18.5703 16.1565L21.6838 13.043L35.6947 27.0538L21.6838 41.0644L18.5703 37.9509L29.4675 27.0538Z"
										fill="white"
									/>
								</svg>
							</button>
						)}
					</>
				)}

				{effectShow || adjustmentShow || textShow || mirrorShow ? (
					<div className={styles.EffectPreview} id="MainEffectImage" style={{ width: imageWidth, height: imageHeight }}>
						<img
							src={activeImageURL}
							alt=""
							className={`${styles.img} ${adjustmentShow ? "" : effectValue}`}
							style={{
								filter: adjustmentShow
									? `contrast(${adjustmentValue.contrast}%) brightness(${adjustmentValue.brightness}%) saturate(${adjustmentValue.saturate}%) hue-rotate(${adjustmentValue.huerotate}deg) sepia(${adjustmentValue.sepia}%) invert(${adjustmentValue.invert}%) grayscale(${adjustmentValue.grayscale}%) blur(${adjustmentValue.blur}px)`
									: "",
								transform: `scale(${mirrorValueX},${mirrorValueY})`,
								opacity: `${adjustmentValue.opacity}`,
							}}
						/>
						{textShow && (
							<Draggable onStart={() => draggable}>
								<div>
									<textarea
										ref={inputRef}
										className={styles.TextArea}
										readOnly={draggable}
										onClick={() => {
											setColorPicker(false);
										}}
										onKeyDown={(e) => {
											if (e.key === "Control") setDraggable(true);
											else setDraggable(false);
										}}
										onKeyUp={(e) => {
											setDraggable(false);
										}}
										style={{
											cursor: draggable ? "grabbing" : "text",
											resize: draggable ? "none" : "both",
											fontSize: `${fontSize}px`,
											transform: `rotate(${textRotation}deg)`,
											color: `rgba(${bgColor.r},${bgColor.g},${bgColor.b},${bgColor.a})`,
											// fontFamily: fontFamily,
											// outline: `2px solid rgba(${bgColor.r},${bgColor.g},${bgColor.b},${bgColor.a})`,
										}}></textarea>
								</div>
							</Draggable>
						)}
					</div>
				) : cropShow ? (
					<Cropper
						image={activeImageURL}
						crop={crop}
						zoom={zoom}
						rotation={rotation}
						aspect={aspectValue}
						onZoomChange={setZoom}
						onRotationChange={setRotation}
						onCropChange={setCrop}
						onCropComplete={(croppedArea, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels)}
					/>
				) : (
					<img src={activeImageURL} onLoad={onImageLoad} className={styles.img} />
				)}
			</div>

			<div className={styles.BottomWrapper}>
				{!effectShow && !adjustmentShow && !cropShow && !textShow && !mirrorShow && (
					<button className={styles.RemoveBtn} onClick={() => handelRemoveImage(activeIndex)}>
						X Remove
					</button>
				)}

				{!effectShow && !cropShow && !rotateShow && !aspectRatioShow && !adjustmentShow && !textShow && !mirrorShow && (
					<div className={styles.ButtonWrapper}>
						<button onClick={() => setCropShow(true)}>
							<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
								<path
									d="M9.73295 19.127H16.9268V21.3117H7.09585C6.49259 21.3117 6.00353 20.8226 6.00353 20.2194V8.2038H2.72656V6.01916H6.00353V2.74219H8.18818V17.5823L17.5666 8.2038H10.3728V6.01916H19.7513L22.5436 3.2268L24.0884 4.77157L21.296 7.56393V19.127H24.573V21.3117H21.296V24.5886H19.1114V9.74858L9.73295 19.127Z"
									fill="white"
								/>
							</svg>
							Crop
						</button>
						{/* <button onClick={() => setRotateShow(true)}>
							<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
								<path
									d="M22.2477 12.1182L24.2449 10.1209L25.7897 11.6657L21.1554 16.3L16.5211 11.6657L18.0659 10.1209L20.0631 12.1182V9.29364C20.0631 7.48383 18.596 6.01668 16.7861 6.01668H12.4168V3.83203H16.7861C19.8025 3.83203 22.2477 6.27728 22.2477 9.29364V12.1182ZM14.6015 10.386C15.2048 10.386 15.6938 10.875 15.6938 11.4783V22.4015C15.6938 23.0048 15.2048 23.4938 14.6015 23.4938H3.67826C3.07499 23.4938 2.58594 23.0048 2.58594 22.4015V11.4783C2.58594 10.875 3.07499 10.386 3.67826 10.386H14.6015ZM13.5092 12.5706H4.77058V21.3092H13.5092V12.5706Z"
									fill="white"
								/>
							</svg>
							Rotate
						</button> */}
						<button onClick={() => setAdjustmentShow(true)}>
							<svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27" fill="none">
								<path
									d="M13.9726 20.2179C10.3529 20.2179 7.41865 17.2836 7.41865 13.664C7.41865 10.0443 10.3529 7.11005 13.9726 7.11005C17.5922 7.11005 20.5265 10.0443 20.5265 13.664C20.5265 17.2836 17.5922 20.2179 13.9726 20.2179ZM13.9726 18.0333C16.3856 18.0333 18.3419 16.077 18.3419 13.664C18.3419 11.2509 16.3856 9.2947 13.9726 9.2947C11.5595 9.2947 9.60329 11.2509 9.60329 13.664C9.60329 16.077 11.5595 18.0333 13.9726 18.0333ZM12.8803 1.64844H15.0649V4.92541H12.8803V1.64844ZM12.8803 22.4026H15.0649V25.6795H12.8803V22.4026ZM4.70392 5.9401L6.24869 4.39532L8.56586 6.71249L7.02108 8.25726L4.70392 5.9401ZM19.3793 20.6155L20.9241 19.0707L23.2413 21.3879L21.6965 22.9327L19.3793 20.6155ZM21.6965 4.39532L23.2413 5.9401L20.9241 8.25726L19.3793 6.71249L21.6965 4.39532ZM7.02108 19.0707L8.56586 20.6155L6.24869 22.9327L4.70392 21.3879L7.02108 19.0707ZM25.9881 12.5717V14.7563H22.7112V12.5717H25.9881ZM5.234 12.5717V14.7563H1.95703V12.5717H5.234Z"
									fill="white"
								/>
							</svg>
							Adjustment
						</button>
						<button onClick={() => setEffectShow(true)}>
							<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
								<path
									d="M16.6087 11.4202C16.135 10.9715 15.8302 10.3733 15.7457 9.72636L15.3056 6.35933L12.3229 7.98235C11.7497 8.29422 11.0867 8.39924 10.4453 8.27974L7.10701 7.65785L7.7289 10.9961C7.8484 11.6376 7.74338 12.3005 7.43153 12.8737L5.80849 15.8564L9.17553 16.2964C9.8225 16.381 10.4206 16.6858 10.8693 17.1595L13.2045 19.6248L14.6635 16.5586C14.9439 15.9694 15.4185 15.4947 16.0077 15.2144L19.074 13.7553L16.6087 11.4202ZM16.6362 17.4972L14.2193 22.5766C14.0637 22.9036 13.6727 23.0424 13.3458 22.8869C13.273 22.8522 13.2071 22.8044 13.1516 22.7457L9.28325 18.6619C9.1797 18.5525 9.04167 18.4822 8.89238 18.4627L3.31466 17.7336C2.95574 17.6866 2.70283 17.3577 2.74974 16.9988C2.7602 16.9187 2.78535 16.8414 2.82392 16.7705L5.51258 11.8295C5.58455 11.6972 5.60879 11.5442 5.58121 11.3962L4.55099 5.86617C4.4847 5.51033 4.71943 5.16813 5.07528 5.10183C5.15461 5.08705 5.236 5.08705 5.31534 5.10183L10.8454 6.13205C10.9934 6.15962 11.1464 6.13539 11.2786 6.06342L16.2196 3.37476C16.5376 3.20175 16.9356 3.31924 17.1086 3.63718C17.1472 3.70807 17.1723 3.78547 17.1827 3.8655L17.9118 9.4432C17.9314 9.59251 18.0017 9.73054 18.111 9.83408L22.195 13.7024C22.4578 13.9513 22.469 14.3662 22.2201 14.6289C22.1646 14.6876 22.0987 14.7354 22.0259 14.77L16.9465 17.187C16.8105 17.2518 16.7009 17.3612 16.6362 17.4972ZM17.5066 19.6023L19.0514 18.0575L23.6858 22.6918L22.141 24.2365L17.5066 19.6023Z"
									fill="white"
								/>
							</svg>
							Effect
						</button>
						<button onClick={() => setMirrorShow(true)}>Mirror</button>
					</div>
				)}

				{cropShow && !rotateShow && !aspectRatioShow && (
					<div className={styles.CropWrapper}>
						<div className={styles.Left}>
							<button onClick={() => setRotateShow(true)}>
								<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
									<path
										d="M22.2477 12.1182L24.2449 10.1209L25.7897 11.6657L21.1554 16.3L16.5211 11.6657L18.0659 10.1209L20.0631 12.1182V9.29364C20.0631 7.48383 18.596 6.01668 16.7861 6.01668H12.4168V3.83203H16.7861C19.8025 3.83203 22.2477 6.27728 22.2477 9.29364V12.1182ZM14.6015 10.386C15.2048 10.386 15.6938 10.875 15.6938 11.4783V22.4015C15.6938 23.0048 15.2048 23.4938 14.6015 23.4938H3.67826C3.07499 23.4938 2.58594 23.0048 2.58594 22.4015V11.4783C2.58594 10.875 3.07499 10.386 3.67826 10.386H14.6015ZM13.5092 12.5706H4.77058V21.3092H13.5092V12.5706Z"
										fill="white"
									/>
								</svg>
								Rotate
							</button>
							<button onClick={() => setAspectRatioShow(true)}>Aspect Ratio</button>
						</div>

						<div className={styles.Right}>
							<button
								onClick={() => {
									setCropShow(false);
									cropImage();
								}}>
								Go
							</button>
							<button
								onClick={() => {
									setCropShow(false);
								}}>
								Cancel
							</button>
						</div>
					</div>
				)}

				{rotateShow && (
					<div className={styles.RotateWrapper}>
						<div className={styles.Left}>
							<span>{rotation}%</span>
							<input
								type="range"
								value={rotation}
								step={1}
								min={0}
								max={360}
								onChange={(e) => setRotation(e.target.value)}
							/>
						</div>

						<div className={styles.Right}>
							<button
								onClick={() => {
									setRotateShow(false);
								}}>
								Go
							</button>
							<button
								onClick={() => {
									setRotateShow(false);
									setRotation(0);
								}}>
								Cancel
							</button>
						</div>
					</div>
				)}

				{aspectRatioShow && (
					<div className={styles.AspectRatioWrapper}>
						<div className={styles.Left}>
							<button onClick={() => setAspectValue(1 / 1)}>1 : 1</button>
							<button onClick={() => setAspectValue(1 / 2)}>1 : 2</button>
							<button onClick={() => setAspectValue(2 / 1)}>2 : 1</button>
							<button onClick={() => setAspectValue(4 / 5)}>4 : 5</button>
							<button onClick={() => setAspectValue(5 / 4)}>5 : 4</button>
							<button onClick={() => setAspectValue(16 / 9)}>16 : 9</button>
						</div>

						<div className={styles.Right}>
							<button
								onClick={() => {
									setAspectRatioShow(false);
								}}>
								Go
							</button>
							<button
								onClick={() => {
									setAspectRatioShow(false);
									setAspectValue(1 / 1);
								}}>
								Cancel
							</button>
						</div>
					</div>
				)}

				{effectShow && (
					<div className={styles.EffectShowWrapper}>
						<div className={styles.Left}>
							<AliceCarousel mouseTracking disableButtonsControls disableDotsControls responsive={responsive}>
								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => {
										setEffectValue(styles.EffectNormal);
										UpdateImageArrayWithNewData(imagesArray[activeIndex]);
									}}>
									<img
										src={URL.createObjectURL(imagesArray[activeIndex])}
										alt=""
										className={`${styles.EffectNormal}`}
									/>
									<p>Normal</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.Effect1977)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.Effect1977} />
									{/* <div className={`${styles.EffectPre} ${styles.Effect1977}`} /> */}
									{/* </div> */}
									<p>1977</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectAden)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectAden} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectAden}`} /> */}
									{/* </div> */}
									<p>Aden</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectAmaro)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectAmaro} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectAmaro}`} /> */}
									{/* </div> */}
									<p>Amaro</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectBrannan)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectBrannan} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectBrannan}`} /> */}
									{/* </div> */}
									<p>Brannan</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectBrooklyn)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectBrooklyn} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectBrooklyn}`} /> */}
									{/* </div> */}
									<p>Brooklyn</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectClarendon)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectClarendon} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectClarendon}`} /> */}
									{/* </div> */}
									<p>Clarendon</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectEarlybird)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectEarlybird} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectEarlybird}`} /> */}
									{/* </div> */}
									<p>Earlybird</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectGingham)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectGingham} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectGingham}`} /> */}
									{/* </div> */}
									<p>Gingham</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectHudson)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectHudson} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectHudson}`} /> */}
									{/* </div> */}
									<p>Hudson</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectInkwell)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectInkwell} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectInkwell}`} /> */}
									{/* </div> */}
									<p>Inkwell</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectLofi)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectLofi} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectLofi}`} /> */}
									{/* </div> */}
									<p>Lofi</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectMaven)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectMaven} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectMaven}`} /> */}
									{/* </div> */}
									<p>Maven</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectPerpetua)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectPerpetua} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectPerpetua}`} /> */}
									{/* </div> */}
									<p>Perpetua</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectReyes)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectReyes} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectReyes}`} /> */}
									{/* </div> */}
									<p>Reyes</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectStinson)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectStinson} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectStinson}`} /> */}
									{/* </div> */}
									<p>Stinson</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectToaster)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectToaster} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectToaster}`} /> */}
									{/* </div> */}
									<p>Toaster</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectWalden)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectWalden} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectWalden}`} /> */}
									{/* </div> */}
									<p>Walden</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectValencia)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectValencia} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectValencia}`} /> */}
									{/* </div> */}
									<p>Valencia</p>
								</div>

								<div
									className={styles.ImageWrapper}
									onDragStart={(e) => e.preventDefault()}
									onClick={() => setEffectValue(styles.EffectXpro2)}>
									{/* <div className={styles.Image}> */}
									<img src={activeImageURL} alt="" className={styles.EffectXpro2} />
									{/* <div className={`${styles.EffectPre} ${styles.EffectXpro2}`} /> */}
									{/* </div> */}
									<p>Xpro2</p>
								</div>
							</AliceCarousel>
						</div>

						<div className={styles.Right}>
							<button onClick={handelFilter}>Go</button>
							<button
								onClick={() => {
									setEffectShow(false);
									setEffectValue(styles.EffectNormal);
								}}>
								Cancel
							</button>
						</div>
					</div>
				)}

				{adjustmentShow && (
					<div className={styles.AdjustmentWrapper}>
						<div className={styles.Left}>
							<div className={styles.RangeMainWrapper}>
								<div className={styles.RangeWrapper}>
									<label htmlFor="Contrast">Contrast : </label>
									<input
										type="range"
										id="Contrast"
										min={0}
										max={300}
										value={adjustmentValue.contrast}
										onChange={(e) => setAdjustmentValue({ ...adjustmentValue, contrast: e.target.value })}
									/>
									<span>{adjustmentValue.contrast}</span>
								</div>

								<div className={styles.RangeWrapper}>
									<label htmlFor="Saturate">Saturate : </label>
									<input
										type="range"
										id="Saturate"
										min={0}
										max={300}
										value={adjustmentValue.saturate}
										onChange={(e) => setAdjustmentValue({ ...adjustmentValue, saturate: e.target.value })}
									/>
									<span>{adjustmentValue.saturate}</span>
								</div>

								<div className={styles.RangeWrapper}>
									<label htmlFor="Brightness">Brightness : </label>
									<input
										type="range"
										id="Brightness"
										min={0}
										max={300}
										value={adjustmentValue.brightness}
										onChange={(e) => setAdjustmentValue({ ...adjustmentValue, brightness: e.target.value })}
									/>
									<span>{adjustmentValue.brightness}</span>
								</div>

								<div className={styles.RangeWrapper}>
									<label htmlFor="grayscale">Grayscale : </label>
									<input
										type="range"
										id="grayscale"
										min={0}
										max={100}
										value={adjustmentValue.grayscale}
										onChange={(e) => setAdjustmentValue({ ...adjustmentValue, grayscale: e.target.value })}
									/>
									<span>{adjustmentValue.grayscale}</span>
								</div>
							</div>

							<div className={styles.RangeMainWrapper}>
								<div className={styles.RangeWrapper}>
									<label htmlFor="sepia">Sepia : </label>
									<input
										type="range"
										id="sepia"
										min={0}
										max={100}
										value={adjustmentValue.sepia}
										onChange={(e) => setAdjustmentValue({ ...adjustmentValue, sepia: e.target.value })}
									/>
									<span>{adjustmentValue.sepia}</span>
								</div>

								<div className={styles.RangeWrapper}>
									<label htmlFor="invert">Invert : </label>
									<input
										type="range"
										id="invert"
										min={0}
										max={100}
										value={adjustmentValue.invert}
										onChange={(e) => setAdjustmentValue({ ...adjustmentValue, invert: e.target.value })}
									/>
									<span>{adjustmentValue.invert}</span>
								</div>

								<div className={styles.RangeWrapper}>
									<label htmlFor="huerotate">HueRotate : </label>
									<input
										type="range"
										id="huerotate"
										min={0}
										max={360}
										value={adjustmentValue.huerotate}
										onChange={(e) => setAdjustmentValue({ ...adjustmentValue, huerotate: e.target.value })}
									/>
									<span>{adjustmentValue.huerotate}</span>
								</div>

								<div className={styles.RangeWrapper}>
									<label htmlFor="blur">Blur : </label>
									<input
										type="range"
										id="blur"
										min={0}
										max={10}
										value={adjustmentValue.blur}
										onChange={(e) => setAdjustmentValue({ ...adjustmentValue, blur: e.target.value })}
									/>
									<span>{adjustmentValue.blur}</span>
								</div>
							</div>
						</div>

						<div className={styles.Right}>
							<button onClick={handelAdjustment}>Go</button>
							<button
								onClick={() => {
									setAdjustmentShow(false);
									setAdjustmentValue({
										contrast: 100,
										brightness: 100,
										saturate: 100,
										huerotate: 0,
										sepia: 0,
										invert: 0,
										grayscale: 0,
										blur: 0,
									});
								}}>
								Cancel
							</button>
						</div>
					</div>
				)}

				{textShow && (
					<div className={styles.TextWrapper}>
						<div className={styles.Left}>
							<button onClick={() => setDraggable(!draggable)} className={styles.draggableBtn}>
								{draggable ? "Drag End" : "Drag Start"}
							</button>

							<select
								onChange={(e) => setFontSize(e.target.value)}
								defaultValue={fontSize}
								className={styles.fontSizeBtn}>
								<option value="10">10</option>
								<option value="15">15</option>
								<option value="20">20</option>
								<option value="25">25</option>
								<option value="30">30</option>
								<option value="35">35</option>
								<option value="40">40</option>
								<option value="45">45</option>
								<option value="50">50</option>
								<option value="55">55</option>
							</select>

							<input
								type="range"
								min={-180}
								max={180}
								className={styles.rangeBtn}
								value={textRotation}
								onChange={(e) => setTextRotation(e.target.value)}
							/>

							<button
								className={styles.ColorBallBtn}
								style={{
									background: `rgba(${bgColor.r},${bgColor.g},${bgColor.b},${bgColor.a})`,
								}}
								onClick={() => setColorPicker(!colorPicker)}>
								{colorPicker && (
									<div className={styles.ColorPicker} onClick={(e) => e.stopPropagation()}>
										<SketchPicker color={bgColor} onChange={(e) => setBgColor(e.rgb)} />
									</div>
								)}
							</button>
						</div>

						<div className={styles.Right}>
							<button onClick={handelTextAdd}>Go</button>
							<button
								onClick={() => {
									setTextShow(false);
								}}>
								Cancel
							</button>
						</div>
					</div>
				)}

				{mirrorShow && (
					<div className={styles.MirrorWrapper}>
						<div className={styles.Left}>
							<button
								onClick={() => {
									if (mirrorValueX === 1) setMirrorValueX(-1);

									if (mirrorValueX === -1) setMirrorValueX(1);
								}}>
								Rotate-X
							</button>
							<button
								onClick={() => {
									if (mirrorValueY === 1) setMirrorValueY(-1);
									if (mirrorValueY === -1) setMirrorValueY(1);
								}}>
								Rotate-Y
							</button>
							<button
								onClick={() => {
									setMirrorValueX(1);
									setMirrorValueY(1);
								}}>
								Reset
							</button>
						</div>

						<div className={styles.Right}>
							<button onClick={handelMirror}>Go</button>
							<button
								onClick={() => {
									setMirrorShow(false);
								}}>
								cancel
							</button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
