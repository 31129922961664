import React, { useRef } from "react";
import { toast } from "react-toastify";
import arrow from "../../assets/svg/arrowRightNew.svg";
import styles from "./PostWrapper.module.scss";

const SelectedImage = ({ imagesArray, setImagesArray, setCropContShow, setUploadSection, setSelectedImageShow }) => {
	const inputRef = useRef();

	const handelRemoveImage = (indexToRemove) => {
		const updatedArray = imagesArray.filter((_, index) => index !== indexToRemove);
		setImagesArray(updatedArray);
	};

	const handelAddPhotos = (e) => {
		const file = e.target.files;
		if (file) {
			let tempArray = Array(...file);
			setImagesArray((prev) => [...prev, ...tempArray]);
		}

		e.target.value = null;
	};

	const handleDragOver = (e) => {
		e.preventDefault();
	};

	const handelDrop = (e) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		setImagesArray((prev) => [...prev, file]);
	};

	const handelArrowButton = () => {
		if (imagesArray?.length === 0) toast.error("Please Select an Image !");
		else {
			setSelectedImageShow(false);
			// setUploadSection(true);
			setCropContShow(true);
		}
	};

	return (
		<div className={styles.SelectedImageWrapper}>
			<div className={styles.Top}>
				<img src={arrow} alt="" className={styles.PopupCloseBtn} onClick={() => setSelectedImageShow(false)} />
				<h1>eMagz</h1>
				<img src={arrow} alt="" onClick={handelArrowButton} />
			</div>

			<div className={styles.Bottom} onDrop={handelDrop} onDragOver={handleDragOver}>
				<div className={styles.ImageSection}>
					{imagesArray?.map((data, index) => {
						return (
							<div key={index} className={styles.ImageWrapper}>
								<div className={styles.remove} onClick={() => handelRemoveImage(index)}>
									X
								</div>
								<img src={URL.createObjectURL(data)} alt="" />
							</div>
						);
					})}
				</div>

				<div className={styles.TextSection}>
					<h1>Drag & Drop your image or video</h1>
					<h4>Or</h4>
					<input type="file" multiple ref={inputRef} style={{ display: "none" }} onChange={handelAddPhotos} />
					<button onClick={() => inputRef.current.click()}>Upload from computer</button>
				</div>
			</div>
		</div>
	);
};

export default SelectedImage;
