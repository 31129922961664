import React from "react";
import { FallingLines } from "react-loader-spinner";

const Loading = ({ className, color = "#3A0DBB" }) => {
	return (
		<div
			className={className}
			style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
			<FallingLines color={color} width="10rem" visible={true} ariaLabel="falling-lines-loading" />
		</div>
	);
};

export default Loading;
