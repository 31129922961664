// import Cookies from "js-cookie";

import { toast } from "react-toastify";
import axios from "./axios";

export const setCookies = ({ url, token, personaUserId, currentUserId }) => {
	axios
		.get(`/persona/user-template/${personaUserId}`)
		.then(({ data }) => {
			if (data.length >= 1) window.open(`${url}/${personaUserId}/${currentUserId}/${token}`, "_self");
			else toast.info("Didn't have a persona");
		})
		.catch((e) => console.log(e));

	// window.open(`${url}`, "_self");

	// Cookies.set("E_persona_userId", JSON.stringify(personaUserId), { domain: ".emagz.live" });
	// Cookies.set("E_current_userId", JSON.stringify(currentUserId), { domain: ".emagz.live" });
	// Cookies.set("E_token", JSON.stringify(token), { domain: ".emagz.live" });

	// Cookies.set("E_persona_userId", JSON.stringify(personaUserId));
	// Cookies.set("E_current_userId", JSON.stringify(currentUserId));
	// Cookies.set("E_token", JSON.stringify(token));
};

// domain: ".emagz.live",
// secure: true,
// expires: 123456
