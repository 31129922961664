import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "../../components/Hooks/axios";
import styles from "./Support.module.scss";
import { EmailValidate } from "../../components/Hooks/EmailValidate";

const EmailPopup = ({ setEmailPopup }) => {
	const [email, setEmail] = useState("");
	const [reason, setReason] = useState("");
	const [message, setMessage] = useState("");

	const handelSubmit = () => {
		if (email === "" && reason === "" && message === "") return toast.warn("Please Fill all the fields!!");
		if (EmailValidate(email)) return toast.error("Invalid Email !");

		const raw = {
			email,
			reason,
			message,
		};

		axios
			.post("/support/create", raw)
			.then(({ data }) => {
                if (data.status) {
                    toast.success("Response Submitted Successfully !!")
                    setEmailPopup(false)
                    setEmail("")
                    setReason("")
					setMessage("")
					console.log(data)

                }
			})
			.catch((e) => console.log(e));
	};

	return (
		<div className={styles.PopupWrapper}>
			<div className={styles.Top}>
				<div>
					<button onClick={() => setEmailPopup(false)}>{"<"}</button>
					<h1>Support</h1>
				</div>
				<p>Email Setting</p>
			</div>
			<div className={styles.Bottom}>
				<div className={styles.EmailWrapper}>
					<input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
					<input type="text" placeholder="Select Reason" value={reason} onChange={(e) => setReason(e.target.value)} />
					<textarea
						placeholder="Message"
						defaultValue={message}
						onChange={(e) => setMessage(e.target.value)}></textarea>
					<button onClick={handelSubmit}>Submit</button>
				</div>
			</div>
		</div>
	);
};

export default EmailPopup;
