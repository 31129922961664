import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useUserLoginValidate = () => {
	const navigate = useNavigate();

	const userCookies = Cookies.get("E_current_userId");
	
	useEffect(() => {
		if (userCookies) return navigate("/Home");
		else return navigate("/");
	}, []);
};

export default useUserLoginValidate;
