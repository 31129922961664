import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Bg from "../../../../components/BG/Bg";
import classes from "./Congratulations.module.css";
import axios from "../../../../components/Hooks/axios";
import { setUserObject } from "../../../../redux/slices/authSlice";
import { addAccount } from "../../../../redux/slices/accountSlice";

const Congratulations = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [btnDisable, setBtnDisable] = useState(false);
	const { userId, token } = useSelector((state) => state.auth);

	const handelSubmit = () => {
		setBtnDisable(true);
		axios
			.get(`/auth/get-user-details/${userId}`)
			.then(({ data }) => {
				dispatch(setUserObject(data));
				dispatch(addAccount({ userId, user: data, token }));
				navigate("/ChoseTemplate", { replace: true });
				setBtnDisable(false);
			})
			.catch(({ response }) => {
				console.log(response);
			});
	};

	return (
		<div className={classes["main-container"]}>
			<Bg />
			<div className={classes.container}>
				<h1 className={classes.heading}>Congratulations</h1>
				<p className={classes.text1}>Customize your Persona</p>
				<div className={classes["text-container"]}>
					<div className={classes.row1}>
						<p>View Insights</p>
						<p>Analyses your post</p>
						<p>Redirect to store</p>
					</div>
					<div className={classes.row2}>
						<p>Personalized Professional persona</p>
						<p>View Audience Base</p>
					</div>
					<div className={classes.row3}>
						<p>Promote Posts</p>
						<p>Upload product images</p>
					</div>
				</div>
				<button disabled={btnDisable} onClick={handelSubmit} className={classes.btn}>
					Choose Template
				</button>
			</div>
		</div>
	);
};

export default Congratulations;
