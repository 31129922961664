import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../../../../components/Hooks/axios";
import Modal from "../../../../components/Modal/Modal";
import { setUserObject } from "../../../../redux/slices/authSlice";
import classes from "../Update Name/UpdateName.module.css";
import Verification from "../Verification/Verification";

const UpdateMobileNo = ({ onClose, mobile_number }) => {
	console.log(mobile_number);
	const { userId } = useSelector((state) => state.auth);
	const [verification, setVerification] = useState(false);
	const dispatch = useDispatch();
	const [btnDisable, setBtnDisable] = useState(false);
	const [content, setContent] = useState(mobile_number === "0000000000" ? "" : mobile_number);

	function isNumeric(value) {
		return isNaN(parseFloat(value)) && isFinite(value);
	}

	const handelUpdateName = () => {
		if (isNumeric(content)) return toast.error("Invalid number !");
		if (content.length > 10 || content.length < 10) return toast.error("Invalid number !");
		setBtnDisable(true);

		const data = JSON.stringify({
			mobile_number: content,
		});

		axios
			.put(`/auth/update-user-details?userId=${userId}`, data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(({ data }) => {
				toast.success(data.message);
				dispatch(setUserObject(data.user));
				setBtnDisable(false);
				onClose(false);
			})
			.catch((response) => {
				console.log(response);
				setBtnDisable(false);
			});
	};

	return (
		<Modal onClose={onClose}>
			{verification && <Verification onClose={setVerification} />}
			<div className={classes.overlay} onClick={(e) => e.stopPropagation()}>
				<div className={classes.form}>
					<h2>Update Mobile No</h2>
					<p>Please enter your new mobile number which you want to update and use</p>
					<label htmlFor="mobile-no">New Mobile Number</label>
					<input
						type="number"
						placeholder="+91 000 000 0000"
						value={content}
						onChange={(e) => setContent(e.target.value)}
					/>
					<div className={classes.actions}>
						<button className={classes.btn} disabled={btnDisable} onClick={handelUpdateName}>
							Update
						</button>
						<button className={classes.link} onClick={() => onClose(false)}>
							Back
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default UpdateMobileNo;
