import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ChangeLogo from "../../../assets/svg/Change password.svg";
import axios from "../../../components/Hooks/axios";
import { setUserObject } from "../../../redux/slices/authSlice";
import { setTempEmail } from "../../../redux/slices/tempSlice";
import LoadingBg from "../Loading Bg/LoadingBg";
import classes from "./ChangePassword.module.css";

const ChangePassword = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { tempEmail } = useSelector((state) => state.temp);

	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [btnDisable, setBtnDisable] = useState(false);

	useEffect(() => {
		if (tempEmail === "") navigate(-1, { replace: true });
	}, []);

	const passwordValidate = () => {
		const number = /\d/;
		const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
		const uppercaseRegex = /[A-Z]/;

		if (password.length < 8) {
			toast.error("Password length must be 8 or more!!");
			return true;
		}

		if (!number.test(password)) {
			toast.error("Please add a number!!");
			return true;
		}

		if (!specialCharacterRegex.test(password)) {
			toast.error("Please add a special character!!");
			return true;
		}

		if (!uppercaseRegex.test(password)) {
			toast.error("Please add a uppercase latter!!");
			return true;
		}
		return false;
	};

	const handelChangePassword = () => {
		if (password === "" || confirmPassword === "") return toast.warn("Please fill all the fields !");
		if (passwordValidate()) return;
		if (password !== confirmPassword) return toast.error("Password and confirm password does not match !");
		setBtnDisable(true);

		var raw = JSON.stringify({
			email: tempEmail,
			password: password,
		});

		axios
			.post("/auth/changepassword", raw, {
				headers: {
					"Content-Type": "application/json",
					Authorization: location.state.token,
				},
			})
			.then(({ data }) => {
				toast.success(data.message);
				setBtnDisable(false);
				if (location.state?.pageLocation === "/Privacy") {
					axios
						.get(`/auth/get-user-details/${location.state?.userId}`)
						.then(({ data }) => {
							dispatch(setUserObject(data));
							navigate(location.state?.pageLocation, { replace: true });
							dispatch(setTempEmail(""));
						})
						.catch((e) => console.log(e));
				} else {
					navigate("/signin", { replace: true });
					dispatch(setTempEmail(""));
				}
			})
			.catch(({ response: { data } }) => {
				setBtnDisable(false);
				toast.error(data.error);
			});
	};

	if(tempEmail)
	return (
		<div className={classes["main-container"]}>
			<LoadingBg />
			<div className={classes["sub-container"]}>
				<div className={classes.header}>
					<div className={classes["text-container"]}>
						<p className={classes.heading}>Change Password</p>
						<p className={classes.text}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus est tortor gravida nibh id at.
						</p>
					</div>
					<div className={classes.image}>
						<img src={ChangeLogo} alt="" />
					</div>
				</div>
				<div className={classes.form}>
					<div className={classes["input-container"]}>
						<div className={classes.input}>
							<label htmlFor="password" className={classes.label}>
								New Password
							</label>
							<input
								type="password"
								className={classes["input-box"]}
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
						<div className={classes.input}>
							<label htmlFor="password" className={classes.label}>
								Confirm Password
							</label>
							<input
								type="password"
								className={classes["input-box"]}
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>
						</div>
					</div>
					<div className={classes.actions}>
						<button type="submit" className={classes.btn} disabled={btnDisable} onClick={handelChangePassword}>
							Submit
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChangePassword;
