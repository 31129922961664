import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../../../../components/Hooks/axios";
import Modal from "../../../../components/Modal/Modal";
import { setUserObject } from "../../../../redux/slices/authSlice";
import classes from "./Update.module.scss";

const Update = ({ setUpdateShow, activeUpdateData }) => {
	const dispatch = useDispatch();
	const [btnDisable, setBtnDisable] = useState(false);
	const [content, setContent] = useState(activeUpdateData?.content?.data);

	const handelUpdateName = () => {
		if (content === "") return toast.warn("Please fill the filed !");
		setBtnDisable(true);

		const raw = {
			[activeUpdateData.keyItem]: {
				active: activeUpdateData?.content?.active,
				data: content,
			},
		};
		
		axios
			.put(`/auth/updateIconData`, raw)
			.then(({ data }) => {
				toast.success("Data Updated Successfully !")
				setBtnDisable(false);
				setUpdateShow(false);
				dispatch(setUserObject(data.data));
			})
			.catch(({ response }) => {
				setBtnDisable(false);
				console.log("Error => ", response);
			});
	};

	return (
		<Modal onClose={setUpdateShow}>
			<div className={classes.overlay} onClick={(e) => e.stopPropagation()}>
				<div className={classes.form}>
					<h2>Update {activeUpdateData.heading}</h2>

					<p>{activeUpdateData.desc}</p>

					<label htmlFor="name">{activeUpdateData.heading}</label>

					<input
						type="name"
						placeholder={`Enter your ${activeUpdateData.heading}`}
						value={content}
						onChange={(e) => setContent(e.target.value)}
					/>

					<div className={classes.actions}>
						<button className={classes.btn} disabled={btnDisable} onClick={handelUpdateName}>
							Update
						</button>

						<button className={classes.link} onClick={() => setUpdateShow(false)}>
							Back
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default Update;
