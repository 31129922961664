import React, { useEffect, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { socket } from "../../App";
import EP1 from "../../assets/images/EP1.jpg";
import Logo from "../../assets/svg/Logo_New.svg";
import SettingIcon from "../../assets/svg/SettingIcon.svg";
import SearchIcon from "../../assets/svg/search.svg";
import Header from "../../components/Header/Header";
import ScrollToTop from "../../components/Hooks/ScrollToTop";
import axios from "../../components/Hooks/axios";
import ActiveChat from "./ActiveChat";
import styles from "./Chat.module.scss";
import ChatMessageRequest from "./ChatMessageRequest";
import ChatSetting from "./ChatSetting";

export const Chat = () => {
	const { userId, user } = useSelector((state) => state.auth);
	const [conversations, setConversations] = useState([]);
	const [Search, setSearch] = useState("");
	const [ActiveChatId, setActiveChatID] = useState(null);
	const [SettingPopup, setSettingPopup] = useState(false);
	const [MessageRequest, setMessageRequest] = useState(false);
	const [reload, setReload] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const { pathname } = useLocation();
	const [activeUserData, setActiveUserData] = useState([]);

	useEffect(() => {
		if (userId === "") return;
		setIsLoading(true);
		axios
			.get(`/conversations/${userId}`)
			.then(({ data }) => {
				setIsLoading(false);
				setConversations(data);
				if (pathname.split("/")[2] === undefined) setActiveChatID(data[0]);
				else setActiveChatID(data[pathname.split("/")[2]]);
			})
			.catch(({ response }) => {
				console.log(response);
			});
	}, [userId, reload]);

	useEffect(() => {
		if (userId === "") return;
		if (user.is_active === true) socket.emit("joinLoginRoom", { userId });
		else socket.emit("joinLoginRoom", { userId, activeStatus: false });

		socket.on("userOnline", (payload) => {
			setActiveUserData(payload);
		});
	}, [socket, userId]);

	const filterData = conversations.filter((item) => {
		if (item?.userData?.username?.toLowerCase()?.includes(Search?.toLowerCase())) return true;
	});

	return (
		<div className="App">
			<div className="MainContainer">
				<ScrollToTop />

				<Header />
				<div className={`${styles.Container} ${styles.Chat}`}>
					<div className={styles.LeftChatContainer}>
						<h1 className={styles.ChatText}>Chat</h1>

						<div className={styles.SearchSettingContainer}>
							<div className={styles.SearchContainer}>
								<input
									type="search"
									placeholder="Search"
									value={Search}
									onChange={(e) => setSearch(e.target.value)}
								/>
								<img src={SearchIcon} alt="" />
							</div>
							<div className={styles.SettingIcon}>
								<img
									src={SettingIcon}
									alt=""
									onClick={() => {
										setSettingPopup(!SettingPopup);
										setMessageRequest(false);
									}}
								/>
							</div>
						</div>

						<div className={styles.OnlineContainer}>
							<h2>Online</h2>

							{activeUserData.length === 0 ? <p className={styles.NoOnlineUser}>No user Online</p>
								:
								<div className={styles.OnlineBoxContainer}>
									{activeUserData?.map((data, index) => (
										<div key={index} className={styles.OnlineBox}>
											<img src={data?.userData?.pic} alt="" />
											<div className={styles.greenDot} />
										</div>
									))}
								</div>}
						</div>

						<div className={styles.ChatProfileContainer}>
							{isLoading ? (
								<div className={styles.loadingContainer}>
									<FallingLines color="#3A0DBB" width="100" visible={true} ariaLabel="falling-lines-loading" />
								</div>
							) : filterData.length === 0 ? (
								<h2>No Conversation Yet</h2>
							) : (
								filterData.map((data, index) => {
									if (data.userData !== null)
										return (
											<div
												key={index}
												className={styles.ChatProfileBox}
												onClick={() => {
													setSettingPopup(false);
													setMessageRequest(false);
													setActiveChatID(data);
												}}>
												<div className={styles.ChatProfileBoxLeft}>
													<div className={styles.ChatProfileImage}>
														<img src={data?.userData?.ProfilePic} alt="" />

														{activeUserData.map((onlineUser, index) => {
															if (onlineUser.userId === data?.userData?._id)
																return <div key={index} className={styles.greenDot} />;
														})}
													</div>
													<div className={styles.ChatProfileText}>
														<h2>{data?.userData?.username}</h2>
														<h5>{data?.resentMessage?.text}</h5>
													</div>
												</div>
												{data?.recentSMS_Count ? (
													<span>{data?.recentSMS_Count}</span>
												) : (
													<p>{data?.time}</p>
												)}
											</div>
										);
								})
							)}
						</div>
					</div>

					<div className={styles.RightChatContainer}>
						{!ActiveChatId && !SettingPopup && !MessageRequest ? (
							<div className={styles.OverlayWrapper}>
								<div className={styles.Overlay}>
									<img src={Logo} alt="" />
									<h1>Start chat...</h1>
								</div>
							</div>
						) : (
							""
						)}
						{SettingPopup ? (
							<ChatSetting setMessageRequest={setMessageRequest} setSettingPopup={setSettingPopup} />
						) : MessageRequest ? (
							<ChatMessageRequest reload={reload} setReload={setReload} />
						) : (
							ActiveChatId && <ActiveChat ActiveChatId={ActiveChatId} />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
