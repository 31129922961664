import domToImage from "dom-to-image";
import React, { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import rightArrow from "../../assets/svg/arrowRightNew.svg";
import colorBall from "../../assets/svg/colorBall.svg";
import threeDot from "../../assets/svg/more-2-fill.svg";
import music from "../../assets/svg/music-fill.svg";
import textSvg from "../../assets/svg/text.svg";
import axios from "../../components/Hooks/axios";
import { setPostReload } from "../../redux/slices/tempSlice";
import styles from "./StoryWrapper.module.scss";

const TextBox = ({ setStoryPopup, setOwnRel }) => {
	const { userId } = useSelector((state) => state.auth);
	const [colorPicker, setColorPicker] = useState(false);
	const [bgColor, setBgColor] = useState({ r: 0, g: 0, b: 0, a: 1 });
	const [text, setText] = useState("");
	const textRef = useRef();
	const dispatch = useDispatch();

	const handelSubmit = () => {
		if (text === "") return toast.warn("Please wring something !");
		const div = document.getElementById("textarea");
		const id = toast.loading("Story Uploading...");

		if (div) {
			domToImage
				.toPng(div)
				.then(function (dataUrl) {
					fetch(dataUrl)
						.then((res) => res.blob())
						.then((blob) => {
							const file = new File([blob], "Text Story", { type: "image/png" });
							const formData = new FormData();
							formData.append("userId", userId);
							formData.append("mediaType", "image");
							formData.append("mediaUrl", file);

							axios
								.post("/story", formData)
								.then(({ data }) => {
									toast.update(id, {
										render: data.message,
										type: "success",
										isLoading: false,
										autoClose: 2000,
									});
									setOwnRel(Math.random());
									setStoryPopup(false);
									dispatch(setPostReload(true));
								})
								.catch((e) => {
									console.log(e);
								});
						});
				})
				.catch(function (error) {
					console.error("Error converting textarea to image:", error);
				});
		}
	};

	useEffect(() => {
		textRef?.current?.focus();
	}, []);

	return (
		<div
			className={styles.TextBox}
			style={{
				background: `rgba(${bgColor.r},${bgColor.g},${bgColor.b},${bgColor.a})`,
			}}>
			<div className={styles.controls}>
				<div className={styles.Right}>
					<img
						src={threeDot}
						alt=""
						onClick={() => {
							toast.info("Three Dot Clicked");
						}}
					/>
					<img
						src={textSvg}
						alt=""
						onClick={() => {
							toast.info("Text Clicked");
						}}
					/>
					<img src={colorBall} alt="" onClick={() => setColorPicker(!colorPicker)} />
					<img
						src={music}
						alt=""
						onClick={() => {
							toast.info("Music Clicked");
						}}
					/>
				</div>
				<img src={rightArrow} alt="" onClick={handelSubmit} className={styles.ArrowRight} />
			</div>

			{colorPicker && (
				<div className={styles.ColorPicker}>
					<SketchPicker
						color={bgColor}
						onChange={(e) => {
							// setBgColor(e.hex);
							setBgColor(e.rgb);
						}}
					/>
				</div>
			)}

			<textarea
				id="textarea"
				className={styles.Textarea}
				ref={textRef}
				defaultValue={text}
				onChange={(e) => setText(e.target.value)}
				onClick={() => setColorPicker(false)}
				style={{
					background: `rgba(${bgColor.r},${bgColor.g},${bgColor.b},${bgColor.a})`,
					fontSize: `${
						text.length >= 28
							? text.length >= 70
								? text.length >= 145
									? text.length >= 275
										? text.length >= 575
											? text.length >= 775
												? text.length >= 1130
													? text.length >= 2000
														? "1rem"
														: "1.2rem"
													: "1.5rem"
												: "1.8rem"
											: "2rem"
										: "3rem"
									: "4rem"
								: "6rem"
							: "10rem"
					}`,
				}}></textarea>
		</div>
	);
};

export default TextBox;
