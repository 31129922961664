import React, { useEffect, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "../../App";
import axios from "../../components/Hooks/axios";
import { fetchMessageReq } from "../../redux/slices/chatSlice";
import styles from "./Chat.module.scss";

const ChatMessageRequest = ({ reload, setReload }) => {
	const dispatch = useDispatch();
	const [time, setTime] = useState("latest");
	const { messageReqData, isLoading } = useSelector((state) => state.chat);
	const { userId, user } = useSelector((state) => state.auth);

	useEffect(() => {
		dispatch(fetchMessageReq(time));
	}, [reload, time]);

	const handelAcceptButton = (data) => {
		let sd = {
			notification_from: userId,
			notification_to: data.senderId._id,
			notification: {},
			title: "Message Request",
			message: `${user.username} accept your message request`,
		};

		axios
			.post(`/chat_setting/requests/${data._id}/accept`)
			.then(({ data }) => {
				setReload(Math.random());
				socket.emit("notification", sd);
			})
			.catch(({ response: { data } }) => console.log(data));

		const raw = JSON.stringify({
			senderId: userId,
			receiverId: data.senderId._id,
		});

		axios
			.post(`/conversations/`, raw, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(({ data }) => {
				setReload(Math.random());
			})
			.catch(({ response: { data } }) => console.log(data));
	};

	const handelRejectButton = (data) => {
		let sd = {
			notification_from: userId,
			notification_to: data.senderId._id,
			notification: {},
			title: "Message Request",
			message: `${user.username} decline your message request`,
		};

		axios
			.delete(`/chat_setting/removeRequest/${data._id}`)
			.then(({ data }) => {
				setReload(Math.random());
				socket.emit("notification", sd);
			})
			.catch(({ response: { data } }) => console.log(data));
	};

	return (
		<div className={styles.MessageRequestContainer}>
			<h1 className={styles.MessageRequestText}>Message Request</h1>
			<h2 className={styles.MessageRequestText2}>Message Request</h2>

			<select className={styles.SelectMessageReq} defaultValue={time} onChange={(e) => setTime(e.target.value)}>
				<option value="latest">Latest</option>
				<option value="old">Old</option>
			</select>

			<div className={styles.MessageRequestBoxContainer}>
				{isLoading ? (
					<div className={styles.loadingContainer}>
						<FallingLines color="#3A0DBB" width="100" visible={true} ariaLabel="falling-lines-loading" />
					</div>
				) : (
					messageReqData.map((data, index) => {
						return (
							<div key={index} className={styles.MessageRequestBox}>
								<div className={styles.CMRLeft}>
									<img src={data?.senderId?.ProfilePic} alt="" />
									<div>
										<h4>{data?.senderId?.username}</h4>
										<h3>{data?.senderId?.resentMessage}</h3>
									</div>
								</div>
								<div className={styles.CMRRight}>
									<button type="button" onClick={() => handelAcceptButton(data)}>
										Accept Request
									</button>
									<button type="button" onClick={() => handelRejectButton(data)}>
										Reject & Block
									</button>
								</div>
							</div>
						);
					})
				)}
			</div>
		</div>
	);
};

export default ChatMessageRequest;
