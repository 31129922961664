import classes from "./Items.module.css";

const Items2 = ({ heading, desc, content, setActiveInfo, setInfoUpdateShow, choose = false, keyName }) => (
	<div className={classes["info-container"]}>
		<div className={classes.subject}>
			<p className={classes.heading}>{heading}</p>
			<p className={classes.content}>{content}</p>
		</div>
		<button
			className={classes.action}
			style={{ cursor: "pointer" }}
			onClick={() => {
				setActiveInfo({ heading, desc, content, choose, keyName });
				setInfoUpdateShow(true);
			}}>
			Update
		</button>
	</div>
);

export default Items2;
