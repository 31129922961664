import { useEffect } from "react";
import { useSelector } from "react-redux";

const PersonaRedirect = () => {
	const { userId, token } = useSelector((state) => state.auth);

	useEffect(() => {
		if (userId === "") window.open(`/Home`, "_self");
		else window.open(`${process.env.REACT_APP_TEMPLATE_BASE_URL}/${userId}/${userId}/${token}`, "_self");
	}, []);
};

export default PersonaRedirect;
