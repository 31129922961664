export const createImage = (url) =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener("load", () => resolve(image));
		image.addEventListener("error", (error) => reject(error));
		image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
		image.src = url;
	});

export default async function getAdjustmentImg(imageSrc, adjustmentValue) {
	const image = await createImage(imageSrc);
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");

	if (!ctx) return null;

	canvas.width = image.width;
	canvas.height = image.height;

	const filterData = `contrast(${adjustmentValue.contrast}%) brightness(${adjustmentValue.brightness}%) saturate(${adjustmentValue.saturate}%) sepia(${adjustmentValue.sepia}%) hue-rotate(${adjustmentValue.huerotate}deg) grayscale(${adjustmentValue.grayscale}%) invert(${adjustmentValue.invert}%) blur(${adjustmentValue.blur}px)`;

	ctx.filter = filterData;

	ctx.drawImage(image, 0, 0);

	// As a blob
	return new Promise((resolve, reject) => {
		canvas.toBlob((file) => {
			file.name = "Mirror.jpeg";
			resolve({ file: file, url: URL.createObjectURL(file) });
		}, "image/jpeg");
	});
}
