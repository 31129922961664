import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import arrow from "../../assets/svg/arrowRightNew.svg";
import { setPollEnable } from "../../redux/slices/tempSlice";
import { PostCrop } from "./PostCrop";
import styles from "./PostWrapper.module.scss";
import SelectedImage from "./SelectedImage";
import TextBox from "./TextBox";
import UploadSection from "./UploadSection";

const PostWrapper = ({ setPostPopup, fileData, setFileData }) => {
	const dispatch = useDispatch();
	const { pollObj } = useSelector((s) => s.temp);
	// Image Data
	const [imagesArray, setImagesArray] = useState([]);
	const image = useRef();

	// Text Box
	const [text, setText] = useState("");
	const [bgColor, setBgColor] = useState({ r: 0, g: 0, b: 0, a: 1 });

	// Components
	const [textBox, setTextBox] = useState(false);
	const [selectedImageShow, setSelectedImageShow] = useState(false);
	const [cropContShow, setCropContShow] = useState(false);
	const [uploadSection, setUploadSection] = useState(false);

	useEffect(() => {
		if (fileData === null) return;
		if (fileData.type === "video/mp4") {
			setUploadSection(true);
			setImagesArray(Array(fileData));
		} else {
			setSelectedImageShow(true);
			setImagesArray(Array(fileData));
		}
		setFileData(null);
	}, []);

	const handleFileInputChange = (e) => {
		const file = e.target.files;
		if (file) {
			if (file[0].type === "video/mp4") {
				setUploadSection(true);
				setImagesArray(Array(...file));
			} else {
				setSelectedImageShow(true);
				setImagesArray(Array(...file));
			}
		}

		e.target.value = null;
	};

	const Controls = () => {
		return (
			<div className={styles.Controls}>
				<h1>{pollObj.enablePoll ? "Create Poll" : "Update eMagz"} </h1>
				<h4>Drag & Drop your image or video</h4>
				<h4>Or</h4>
				<div className={styles.buttonContainer}>
					<button className={styles.button} onClick={() => setTextBox(true)}>
						Text
					</button>
					<button className={styles.button} onClick={() => toast.info("VMagz Coming Soon...")}>
						VMagz
					</button>
					<button
						className={styles.button}
						onClick={() => {
							toast.info("Working...");
						}}>
						Camera
					</button>
					<button className={styles.button} onClick={() => image.current.click()}>
						Upload From Computer
					</button>
				</div>
				<input type="file" multiple ref={image} style={{ display: "none" }} onChange={handleFileInputChange} />
			</div>
		);
	};

	return (
		<div className={styles.PostWrapperOuter}>
			<div className={styles.PostWrapperInner}>
				{!textBox && !uploadSection && !selectedImageShow && !cropContShow && (
					<img
						src={arrow}
						alt=""
						className={styles.CloseBtn}
						onClick={() => {
							dispatch(setPollEnable(false));
							setPostPopup(false);
						}}
					/>
				)}

				{!textBox && !uploadSection && !selectedImageShow && !cropContShow && <Controls />}
				{textBox && <TextBox {...{ setImagesArray, setTextBox, setUploadSection, text, setText, bgColor, setBgColor }} />}

				{selectedImageShow && (
					<SelectedImage
						{...{
							setSelectedImageShow,
							// setUploadSection,
							imagesArray,
							setImagesArray,
							setCropContShow,
						}}
					/>
				)}

				{cropContShow && (
					<PostCrop
						{...{
							imagesArray,
							setImagesArray,
							setCropContShow,
							setSelectedImageShow,
							setUploadSection,
						}}
					/>
				)}

				{uploadSection && (
					<UploadSection
						{...{
							setPostPopup,
							setUploadSection,
							setSelectedImageShow,
							imagesArray,
							setImagesArray,
							text,
							setTextBox,
							setCropContShow,
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default PostWrapper;
