import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../components/Hooks/axios";

const initialState = {
	messageReqData: [],
	isLoading: false,
};

export const fetchMessageReq = createAsyncThunk("fetchMessageReq", async (time) => {
	const response = await axios.get(`/chat_setting/requests?filter=${time}`);
	return response.data;
});

const chatSlice = createSlice({
	name: "chatSlice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchMessageReq.pending, (state, { payload }) => {
			state.isLoading = true;
		});
		builder.addCase(fetchMessageReq.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			state.messageReqData = payload.filter((item) => item.status !== "accepted");
		});
	},
});

export const {} = chatSlice.actions;
export default chatSlice.reducer;
