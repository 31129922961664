import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { toast } from "react-toastify";
import exit from "../../assets/svg/exit.svg";
import { removeUser } from "../../redux/slices/accountSlice";
import { logOut, switchAccount } from "../../redux/slices/authSlice";
import { setPostReload } from "../../redux/slices/tempSlice";
import { setCookies } from "../Hooks/setCookies";
import styles from "./Header.module.css";

const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [LogoutPopup, setLogoutPopup] = useState(false);
	const { userId, user, token } = useSelector((state) => state.auth);
	const { users } = useSelector((state) => state.account);

	const handelLogout = () => {
		toast.success("Logout SuccessFully!!");
		setLogoutPopup(false);
		if (users.length === 1) {
			dispatch(logOut());
			navigate("/", { replace: true });
			dispatch(removeUser(users[0].userId));
		} else {
			const data = users.find((obj) => userId === obj.userId);
			const index = users.findIndex((obj) => userId === obj.userId);
			if (index === 0) {
				dispatch(switchAccount({ userId: users[1].userId, user: users[1].user, token: users[1].token }));
				dispatch(removeUser(data.userId));
			} else {
				dispatch(switchAccount({ userId: users[0].userId, user: users[0].user, token: users[0].token }));
				dispatch(removeUser(data.userId));
			}
		}
		// window.location.reload();
	};

	return (
		<nav className={styles.HeaderContainer}>
			<div className={styles.logoContainer}>
				<Link to="/">
					<img src={logo} alt="logo" />
				</Link>
			</div>

			<div className={styles.navContainer}>
				<ul>
					<li>
						<Link to="/Home" onClick={() => dispatch(setPostReload(true))}>
							Home
						</Link>
					</li>
					<li>
						<Link
							onClick={() => {
								// if (user?.personalTemplate === "") toast.info("You didn't have a persona");
								// else
								setCookies({
									url: `${process.env.REACT_APP_TEMPLATE_BASE_URL}`,
									token,
									personaUserId: user._id,
									currentUserId: user._id,
								});
							}}>
							Persona
						</Link>
					</li>
					<li>
						<Link to="/Explore">Explore</Link>
					</li>
					<li>
						<Link to="/Chat">Chat</Link>
					</li>
					<li>
						<Link to="/Setting">Setting</Link>
					</li>
				</ul>
			</div>

			<div className={styles.logoutContainer}>
				<Link onClick={() => setLogoutPopup(true)}>
					<img src={exit} alt="exit" />
				</Link>
			</div>

			{LogoutPopup && (
				<div className={styles.LogoutPopup}>
					<h1>
						Do You Want To <span>End Live ?</span>
					</h1>
					<div>
						<button type="button" onClick={handelLogout}>
							Log Out
						</button>
						<button type="button" onClick={() => setLogoutPopup(false)}>
							Cancel
						</button>
					</div>
				</div>
			)}
		</nav>
	);
};

export default Header;
