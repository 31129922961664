import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { toast } from "react-toastify";
import RightArrow from "../../assets/svg/RightArrow";
import search from "../../assets/svg/search.svg";
import axios from "../../components/Hooks/axios";
import { setUserObject } from "../../redux/slices/authSlice";
import styles from "./Privacy.module.scss";

export const Emagz = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const [Everyone, setEveryone] = useState(true);
	const [CloseFriends, setCloseFriends] = useState(false);
	const [Specific, setSpecific] = useState(false);
	const [Search, setSearch] = useState("");
	const [disableSwitch, setDisableSwitch] = useState(false);

	useEffect(() => {
		if (user === null) return;
		setEveryone(user?.story_privacy?.everyone);
		setCloseFriends(user?.story_privacy?.close_friend);
		setSpecific(user?.story_privacy?.specific);
	}, [user]);

	const handelSwitchBtn = (e, content) => {
		setDisableSwitch(true);
		let ev = Everyone;
		let cf = CloseFriends;
		let sf = Specific;


		if (content === "Everyone") {
			if (Everyone) return setDisableSwitch(false);
			ev = e;
			cf = false;
			sf = false;
		}
		if (content === "CloseFriends") {
			if (CloseFriends) return setDisableSwitch(false);
			ev = false;
			cf = e;
			sf = false;
		}
		
		if (content === "Specific") {
			if (Specific) return setDisableSwitch(false);
			ev = false;
			cf = false;
			sf = true;
		}

		const id = toast.loading("Please Wait..");

		setEveryone(ev);
		setCloseFriends(cf);
		setSpecific(sf);

		const raw = JSON.stringify({
			story_privacy: {
				everyone: ev,
				close_friend: cf,
				specific: sf,
			},
		});

		if (sf) {
			setDisableSwitch(false);
			toast.update(id, {
				render: "Pending!!",
				type: "info",
				isLoading: false,
				autoClose: 2000,
			});
			return;
		}
		
		axios
			.post("/auth/change-story-privacy", raw, {
				headers: {
					"content-type": "application/json",
				},
			})
			.then(({ data }) => {
				setDisableSwitch(false);
				console.log(data.data.story_privacy);
				toast.update(id, {
					render: "Your Story Privacy Successfully Updated",
					type: "success",
					isLoading: false,
					autoClose: 2000,
				});
				dispatch(setUserObject(data.data));
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<>
			<div className={styles.Emagz}>
				<h1>Emagz</h1>
				<h2>Hide your story's from</h2>
				<div className={styles.AllowCommentsBox}>
					<div>
						<p>Everyone</p>
					</div>
					<Switch
						onChange={(e) => handelSwitchBtn(e, "Everyone")}
						checked={Everyone}
						disabled={disableSwitch}
						offColor={"#EEEEEE"}
						onColor={"#EEEEEE"}
						offHandleColor={"#959595"}
						onHandleColor={"#3A0DBB"}
						uncheckedIcon={false}
						checkedIcon={false}
						activeBoxShadow={"0 0 0px 0px #909AFF"}
						height={19}
						width={46}
					/>
				</div>
				<div className={styles.AllowCommentsBox}>
					<div>
						<p>People you follow</p>
						<span>53 people</span>
					</div>
					<Switch
						onChange={(e) => handelSwitchBtn(e, "CloseFriends")}
						checked={CloseFriends}
						disabled={disableSwitch}
						offColor={"#EEEEEE"}
						onColor={"#EEEEEE"}
						offHandleColor={"#959595"}
						onHandleColor={"#3A0DBB"}
						uncheckedIcon={false}
						checkedIcon={false}
						activeBoxShadow={"0 0 0px 0px #909AFF"}
						height={19}
						width={46}
					/>
				</div>
				<div
					className={`${styles.AllowCommentsBox} ${styles.SpecificPerson}`}
					onClick={() => handelSwitchBtn(!Specific, "Specific")}>
					<p>Specific Person</p>
					<RightArrow />
				</div>
			</div>

			{Specific && (
				<div className={styles.BlockCommentFromSearch}>
					<p>Block comments from</p>
					<div>
						<img src={search} alt="Search" />
						<input type="search" placeholder="Search" value={Search} onChange={(e) => setSearch(e.target.value)} />
					</div>
				</div>
			)}
		</>
	);
};
