import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as CaretLeft } from "../../../assets/icons/CaretLeft.svg";
import { ReactComponent as FileUpload } from "../../../assets/icons/FileUpload.svg";
import Loading from "../../../components/Hooks/Loading";
import axios from "../../../components/Hooks/axios";
import styles from "./AdsApply.module.scss";

const AdsForm = ({ setActiveSection, activeAddData, setMyAddData }) => {
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [address, setAddress] = useState("");
	const [pinCode, setPinCode] = useState("");
	const [state, setState] = useState("");
	const [titleOfYourAdd, setTitleOfYourAdd] = useState("");
	const [aboutAd, setAboutAd] = useState("");
	const [webLink, setWebLink] = useState("");
	const [location, setLocation] = useState("");
	const [fileData, setFileData] = useState(null);
	const fileRef = useRef();

	const handelOnsubmit = () => {
		if (
			name === "" ||
			email === "" ||
			address === "" ||
			pinCode === "" ||
			state === "" ||
			titleOfYourAdd === "" ||
			aboutAd === "" ||
			location === "" ||
			fileData === null
		)
			return toast.error("Please fill all !!");
		setLoading(true);

		const formData = new FormData();
		formData.append("adsId", activeAddData._id);
		formData.append("name", name);
		formData.append("emailOrPhone", email);
		formData.append("address", address);
		formData.append("pin_code", pinCode);
		formData.append("state", state);
		formData.append("titleOfAd", titleOfYourAdd);
		formData.append("aboutAd", aboutAd);
		formData.append("webLink", webLink);
		formData.append("location", location);
		formData.append("adImg", fileData);

		axios
			.post(`/adsApply/apply`, formData)
			.then(({ data }) => {
				setLoading(false);
				toast.success("You successfully apply for add !");
				setMyAddData(data.data);
				setActiveSection("3");
			})
			.catch(({ response }) => {
				console.log("Error => ", response);
			});
	};

	return (
		<div className={styles.AdsFormWrapper}>
			<div className={styles.Top}>
				<h2>Apply for ads</h2>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus est tortor gravida nibh id at.</p>

				<button onClick={() => setActiveSection("1")}>
					<CaretLeft />
				</button>
			</div>

			<div className={styles.Bottom}>
				<div className={styles.Left}>
					<div className={styles.InputWrapper}>
						<label>Name</label>

						<div className={styles.Input}>
							<input type="text" value={name} onChange={(e) => setName(e.target.value)} />
						</div>
					</div>

					<div className={styles.InputWrapper}>
						<label>E-mail or Phone no</label>

						<div className={styles.Input}>
							<input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
						</div>
					</div>

					<div className={styles.InputWrapper}>
						<label>Address</label>

						<div className={styles.Input}>
							<input type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
						</div>
					</div>

					<div className={styles.InputWrapperRow}>
						<div className={styles.InputWrapper}>
							<label>Pin code</label>

							<div className={styles.Input}>
								<input type="text" value={pinCode} onChange={(e) => setPinCode(e.target.value)} />
							</div>
						</div>

						<div className={styles.InputWrapper}>
							<label>State</label>

							<div className={styles.Input}>
								<input type="text" value={state} onChange={(e) => setState(e.target.value)} />
							</div>
						</div>
					</div>

					<div className={styles.InputWrapper}>
						<label>Title of your Ad</label>

						<div className={styles.Input}>
							<input type="text" value={titleOfYourAdd} onChange={(e) => setTitleOfYourAdd(e.target.value)} />
						</div>
					</div>

					<div className={styles.InputWrapper}>
						<label> About Ad</label>

						<div className={`${styles.Input} ${styles.textarea}`}>
							<textarea value={aboutAd} onChange={(e) => setAboutAd(e.target.value)}></textarea>
						</div>
					</div>
				</div>

				<div className={styles.Right}>
					<div className={styles.InputWrapper}>
						<label>
							web Link <span>*(Optional)</span>
						</label>

						<div className={styles.Input}>
							<input type="text" value={webLink} onChange={(e) => setWebLink(e.target.value)} />
						</div>
					</div>

					<div className={styles.InputWrapper}>
						<label>Location</label>
						<span>location where you want to Display your ads Most</span>

						<div className={styles.Input}>
							<input type="text" value={location} onChange={(e) => setLocation(e.target.value)} />
						</div>
					</div>

					<div className={styles.Wrapper}>
						<label>Location</label>
						<span>location where you want to Display your ads Most</span>

						<div className={styles.box} onClick={() => fileRef.current.click()}>
							{fileData ? (
								<h4>{fileData.name}</h4>
							) : (
								<>
									<FileUpload />
									<h4>
										Drag or <span> Browse Your ad</span>
									</h4>
									<h5>Remember : banner size Should be 1024x920</h5>
								</>
							)}
							<input
								type="file"
								style={{ display: "none" }}
								ref={fileRef}
								accept="image/*"
								onChange={(e) => setFileData(e.target.files[0])}
							/>
						</div>
					</div>

					<button onClick={handelOnsubmit} disabled={loading}>
						{loading ? <Loading color={"#fff"} /> : "Submit"}
					</button>
				</div>
			</div>
		</div>
	);
};

export default AdsForm;
