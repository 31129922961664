import React, { useEffect, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import ScrollToTop from "../../components/Hooks/ScrollToTop";
import axios from "../../components/Hooks/axios";
import ChatPopup from "./ChatPopup";
import EmailPopup from "./EmailPopup";
import styles from "./Support.module.scss";
import TitlePopup from "./TitlePopup";

const Support = () => {
	const { userId } = useSelector((s) => s.auth);

	const [activeTicket, setActiveTicket] = useState(null)
	const [chatPopup, setChatPopup] = useState(false);
	const [emailPopup, setEmailPopup] = useState(false);
	const [titlePopup, setTitlePopup] = useState(false);
	const [supportData, setSupportData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [reload, setReload] = useState(0);

	useEffect(() => {
		if (userId === "") return;
		setIsLoading(true);
		axios
			.get(`/ticket/get/${userId}`)
			.then(({ data }) => {
				setIsLoading(false);
				setSupportData(data.data);
			})
			.catch((e) => console.log(e));
	}, [userId, reload]);

	return (
		<div className="App">
			<div className="MainContainer">
				<ScrollToTop />
				<Header />

				{titlePopup && <TitlePopup {...{ setTitlePopup, setReload }} />}

				<div className={styles.SupportWrapper}>
					<div className={styles.Left}>
						<div className={styles.Top}>
							<h1>Support</h1>
							<button
								onClick={() => {
									setChatPopup(false);
									setEmailPopup(true);
								}}>
								Email
							</button>
						</div>

						<div className={styles.Middle}>
							{isLoading ? (
								<div className={styles.Loading}>
									<FallingLines color="#3A0DBB" width="10rem" />
								</div>
							) : (
								<>
									<p>Recent</p>
									{[...supportData]
										.reverse()
										.splice(0, 2)
										.map((data, index) => {
											return (
												<div
													key={index}
													className={styles.Box}
													onClick={() => {
														setEmailPopup(false);
														setChatPopup(true);
														setActiveTicket(data);
													}}>
													<div className={styles.BoxLeft}>
														<h6>Ticket No #{data.ticketNumber}</h6>
														<h3>{data.ticketTitle}</h3>
														<span>{data.createdAt}</span>
													</div>
													<div className={styles.BoxRight}>
														{data?.ticketStaus ? "Active" : "Close"}
													</div>
												</div>
											);
										})}

									<p>History</p>
									{[...supportData]
										.reverse()
										.splice(2)
										.map((data, index) => {
											return (
												<div
													key={index}
													className={styles.Box}
													onClick={() => {
														setEmailPopup(false);
														setChatPopup(true);
														setActiveTicket(data);
													}}>
													<div className={styles.BoxLeft}>
														<h6>Ticket No #{data.ticketNumber}</h6>
														<h3>{data.ticketTitle}</h3>
														<span>{data.createdAt}</span>
													</div>
													<div className={styles.BoxRight}>
														{data?.ticketStaus ? "Active" : "Close"}
													</div>
												</div>
											);
										})}
								</>
							)}
						</div>

						<div className={styles.Bottom}>
							<button onClick={() => setTitlePopup(true)}>Rise Ticket</button>
						</div>
					</div>

					<div className={`${styles.Right} ${chatPopup || emailPopup ? styles.ActiveRight : ""}`}>
						{emailPopup && <EmailPopup setEmailPopup={setEmailPopup} />}

						{chatPopup && <ChatPopup setChatPopup={setChatPopup} activeTicket={activeTicket} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Support;
