import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Bg from "../../../../components/BG/Bg";
import axios from "../../../../components/Hooks/axios";
import classes from "./GetStarted1.module.css";

const GetStarted1 = () => {
	const navigate = useNavigate();
	const { userId } = useSelector((state) => state.auth);
	const {state} = useLocation()
	const [isSelected, setIsSelected] = useState(true);
	const [isSelected2, setIsSelected2] = useState(false);
	const [type, setType] = useState("personal");
	const [btnDisable, setBtnDisable] = useState(false);
	const [name, setName] = useState(state);

	const personalHandler = () => {
		setIsSelected(true);
		setIsSelected2(false);
		setType("personal");
	};

	const professionalHandler = () => {
		setIsSelected2(true);
		setIsSelected(false);
		setType("professional");
	};

	const submitHAndler = () => {
		setBtnDisable(true);
		axios
			.put(`/auth/profile/${userId}`, {
				accountType: type,
			})
			.then(({ data }) => {
				toast.success(data?.message);
				if (isSelected) navigate("/get-started/congratulation", { replace: true });
				if (isSelected2) navigate("/get-started/professional", { replace: true });
			})
			.catch(({ response }) => {
				console.log(response?.data);
			});
		setBtnDisable(false);
	};

	return (
		<div className={classes["main-container"]}>
			<Bg />
			<div className={classes.container}>
				<h1 className={classes.heading}>Let's Get Started</h1>
				<p className={classes.text1}>Customize your persona</p>
				<div className={classes.input}>
					<label className={classes.text2} htmlFor="username">
						User Name
					</label>
					<input type="text" readOnly placeholder={name} className={classes["input-box"]} />
				</div>
				<label className={classes.text2} htmlFor="account">
					Select account type
				</label>
				<div className={classes.buttons}>
					<div
						className={!isSelected ? classes.btn : classes["btn-active"]}
						value={isSelected}
						onClick={personalHandler}>
						Personal Account
					</div>
					<div
						className={!isSelected2 ? classes.btn : classes["btn-active"]}
						value={isSelected2}
						onClick={professionalHandler}>
						Professional Account
					</div>
				</div>
				<button type="submit" className={classes.btn2} onClick={submitHAndler} disabled={btnDisable}>
					Continue
				</button>
			</div>
		</div>
	);
};

export default GetStarted1;
