import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CaretLeft } from "../../../assets/icons/CaretLeft.svg";
import Header from "../../../components/Header/Header";
import ScrollToTop from "../../../components/Hooks/ScrollToTop";
import styles from "./YourAds.module.scss";

const YourAds = () => {
	const navigate = useNavigate();
	const [activeAds, setActiveAds] = useState("current");

	return (
		<div className="App">
			<div className="MainContainer">
				<ScrollToTop />
				<Header />

				<div className={`${styles.Container} ${styles.YourAds}`}>
					<div className={styles.Row1}>
						<div className={styles.Left}>
							<h1>Yours Ads</h1>
						</div>

						<div className={styles.Right} onClick={() => navigate(-1)}>
							<CaretLeft />
						</div>
					</div>

					<div className={styles.Row2}>
						<button className={activeAds === "current" ? styles.Active : ""} onClick={() => setActiveAds("current")}>
							Current Ads
						</button>
						<button className={activeAds === "coming" ? styles.Active : ""} onClick={() => setActiveAds("coming")}>
							Up Coming Ads
						</button>
					</div>

					<div className={styles.Row3}>
						<div className={styles.Current} style={{ marginLeft: activeAds === "current" ? "0" : "-100%" }}>
							<div className={styles.row1}>
								{Array(3)
									.fill("")
									.map((data, index) => (
										<div key={index} className={styles.Box}>
											<h1>Basic Ad</h1>

											<p>For companies that need to manage work happening across multiple teams.</p>

											<img src="https://picsum.photos/1500/500" alt="" />

											<h2 className={styles.Amount}>
												$300<span>/</span>
												<span>month</span>
											</h2>

											<div className={styles.Wrapper}>
												<h6>17 days Left</h6>
												<button>Expire On 12 Nov 2023</button>
											</div>
										</div>
									))}
							</div>

							<h2 className={styles.Previous}>Previous Ads</h2>

							<div className={styles.row2}>
								{Array(13)
									.fill("")
									.map((data, index) => (
										<div key={index} className={styles.Box}>
											<h1>Basic Ad</h1>

											<p>For companies that need to manage work happening across multiple teams.</p>

											<img src="https://picsum.photos/1500/500" alt="" />

											<h2 className={styles.Amount}>
												$300<span>/</span>
												<span>month</span>
											</h2>

											<div className={styles.Wrapper2}>
												<h6>Expired </h6>
												<button>Expire On 12 Nov 2023</button>
											</div>
										</div>
									))}
							</div>
						</div>

						<div className={styles.Coming} style={{ marginLeft: activeAds === "coming" ? "0" : "100%" }}>
							<div className={styles.row1}>
								{Array(3)
									.fill("")
									.map((data, index) => (
										<div key={index} className={styles.Box}>
											<h1>Basic Ad</h1>

											<p>For companies that need to manage work happening across multiple teams.</p>

											<img src="https://picsum.photos/1500/500" alt="" />

											<h2 className={styles.Amount}>
												$300<span>/</span>
												<span>month</span>
											</h2>

											<div className={styles.Wrapper3}>
												<button>Your ad will Display within 2 weeks</button>
											</div>
										</div>
									))}
							</div>

							<h2 className={styles.Previous}>Previous Ads</h2>

							<div className={styles.row2}>
								{Array(6)
									.fill("")
									.map((data, index) => (
										<div key={index} className={styles.Box}>
											<h1>Basic Ad</h1>

											<p>For companies that need to manage work happening across multiple teams.</p>

											<img src="https://picsum.photos/1500/500" alt="" />

											<h2 className={styles.Amount}>
												$300<span>/</span>
												<span>month</span>
											</h2>

											<div className={styles.Wrapper2}>
												<h6>Expired </h6>
												<button>Re-apply</button>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default YourAds;
