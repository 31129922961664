import React from "react";
import Chart from "react-apexcharts";
import styles from "./LiveReviewPopup.module.scss";

const LiveReviewPopup = ({ setIsLiveReviewPopup }) => {
	return (
		<div className={styles.LiveReviewWrapper} onClick={() => setIsLiveReviewPopup(false)}>
			<div className={styles.LiveReview} onClick={(e) => e.stopPropagation()}>
				<div className={styles.Row1}>
					<div className={styles.Top}>
						<h2>Today live Reviews</h2>

						<div className={styles.Right}>
							<select>
								<option value="">Basic Ad</option>
								<option value="">Standard Ad</option>
								<option value="">Premium Ad</option>
							</select>
						</div>
					</div>

					<div className={styles.Mid}>
						<Chart
							options={{
								yaxis: {
									labels: {
										formatter: function (value) {
											return `${Math.floor(value / 3600)}h ${Math.floor((value % 3600) / 60)}m`;
										},
										style: {
											colors: "#CBD5E0",
										},
									},
								},
								xaxis: {
									categories: [1, 2, 3],
									tickPlacement: "between",
									labels: {
										formatter: function (value) {
											const year = Math.floor(value / 100);
											const month = value % 100;
											const dateObject = new Date(year, month - 1, 1);
											const formattedDate = dateObject.toLocaleString("en-US", {
												month: "short",
												year: "2-digit",
											});
											return formattedDate;
										},
										style: {
											colors: "#CBD5E0",
										},
									},
								},
								chart: {
									toolbar: {
										show: false,
									},
								},
								legend: {
									show: true,
									labels: {
										colors: "#fff",
									},
								},
								stroke: {
									show: true,
									curve: "smooth",
									lineCap: "butt",
									colors: undefined,
									width: 3,
									dashArray: 0,
								},
								dataLabels: {
									enabled: false,
								},
								grid: {
									borderColor: "#56577A",
									strokeDashArray: 4,
								},
							}}
							series={[
								{
									type: "line",
									name: "Month",
									color: "#0075FF",
									data: [1, 2, 3],
								},
								{
									type: "line",
									name: "Revenue",
									color: "#EE2CFF",
									data: [1, 2, 3],
								},
							]}
							width="100%"
							height="100%"
						/>
					</div>
				</div>

				<div className={styles.Row2}>
					<button className={styles.Active}>Clicked Users</button>

					<button>Recent visitors</button>
				</div>

				<div className={styles.Row3}>
					{Array(10)
						.fill("")
						.map((data, index) => (
							<div key={index} className={styles.Box}>
								<div className={styles.Wrapper}>
									<img src="https://picsum.photos/500/500" alt="" />

									<div className={styles.Text}>
										<h3>Rohan Mondal</h3>
										<p>@Rohan_Mondal</p>
									</div>
								</div>

								<button>View Profile</button>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export default LiveReviewPopup;
