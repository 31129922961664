import React, { useEffect, useRef, useState } from "react";
import styles from "./WebcamCapture.module.scss";

const WebcamCapture = ({ setOpenWebCame, setImageArray, setOpenCrop }) => {
	const videoRef = useRef(null);
	const canvasRef = useRef(null);
	const [image, setImage] = useState(null);
	const [reload, setReload] = useState(0);
	const [videoStream, setVideoStream] = useState(null);
	const [active, setActive] = useState(false);

	const startWebcam = async () => {
		try {
			setImage(null);
			const stream = await navigator.mediaDevices.getUserMedia({ video: true });
			setVideoStream(stream);
			videoRef.current.srcObject = stream;
		} catch (e) {
			console.log(e);
		}
	};

	const stopWebcam = () => {
		if (videoStream) {
			const tracks = videoStream.getTracks();

			tracks.forEach((track) => {
				track.stop();
				track.enabled = false;
			});

			setVideoStream(null);
		}
	};

	const captureImage = () => {
		const canvas = canvasRef.current;
		const context = canvas.getContext("2d");
		context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
		const imageData = canvas.toDataURL("image/jpeg");
		setImage(imageData);
	};

	const handelContinue = () => {
		stopWebcam();
		fetch(image)
			.then((res) => res.blob())
			.then((blob) => {
				const file = new File([blob], "File name", { type: "image/png" });
				setImageArray(Array(file));
				setOpenCrop(true);
				setOpenWebCame(false);
			});
	};

	useEffect(() => {
		startWebcam();

		return () => {
			stopWebcam();
		};
	}, [reload]);

	return (
		<div className={styles.WebcamCapture} onClick={() => setOpenWebCame(false)}>
			<canvas ref={canvasRef} style={{ display: "none" }} />
			<div className={styles.Wrapper} onClick={(e) => e.stopPropagation()}>
				<div className={styles.ViewWrapper}>
					<video ref={videoRef} autoPlay style={{ display: image ? "none" : "block" }} />

					{image && <img src={image} alt="" />}
				</div>

				{image ? (
					<div className={styles.ButtonWrapper}>
						<button onClick={() => setReload(Math.random() * 99)}>Recapture</button>
						<button onClick={() => handelContinue()}>Continue</button>
					</div>
				) : (
					<div className={styles.ButtonWrapper}>
						<button onClick={captureImage}>Capture Image</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default WebcamCapture;
