import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Bg from "../../../components/BG/Bg";
import axios from "../../../components/Hooks/axios";
import classes from "./Persona.module.css";

const Persona = () => {
	const navigate = useNavigate();
	const { userId, user } = useSelector((state) => state.auth);

	const [displayName, setDisPlayName] = useState("");
	const [skipDisable, setSkipDisable] = useState(false);

	const handelSetupPersonal = () => {
		if (displayName === "") return toast.warn("Please fill the fields !");
		setSkipDisable(true);

		const data = JSON.stringify({
			_id: userId,
			displayName,
			personalTemplate: user?.personalTemplate,
		});

		axios
			.post("auth/setup-personal-account", data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(({ data }) => {
				toast.success(data.message);
				navigate("/get-started/congratulation", { replace: true });
			})
			.catch(({ response }) => {
				console.log(response);
			});
	};

	return (
		<div className={classes["main-container"]}>
			<Bg />
			<div className={classes.container}>
				<div>
					<h1 className={classes.heading}>Set up your persona</h1>
					<p className={classes.text1}>Customize your Persona</p>
					<div className={classes.input}>
						<label className={classes.text2} htmlFor="display-namel">
							Display Name
						</label>
						<input
							type="text"
							className={classes["input-box"]}
							value={displayName}
							placeholder="Enter your Display Name"
							onChange={(e) => setDisPlayName(e.target.value)}
						/>
					</div>
					<div className={classes.actions}>
						<button
							type="submit"
							className={displayName ? classes.activeBtn : ""}
							disabled={skipDisable}
							onClick={handelSetupPersonal}>
							Continue
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Persona;
