import React, { useEffect, useState } from "react";
import { ReactComponent as ChartIcon } from "../../../../assets/icons/Chart.svg";
import { ReactComponent as Curser } from "../../../../assets/icons/Curser.svg";
import { ReactComponent as People } from "../../../../assets/icons/People.svg";
import { ReactComponent as Report } from "../../../../assets/icons/Report.svg";
import styles from "./AdsDetailPopup.module.scss";
import Chart from "react-apexcharts";

const AdsDetailPopup = ({ setIsAdsDetailPopup }) => {
	const [activeBtn, setActiveBtn] = useState("ads");
	const [marginLeft, setMarginLeft] = useState("0");

	useEffect(() => {
		if (activeBtn === "ads") setMarginLeft("0%");
		if (activeBtn === "click") setMarginLeft("-100%");
		if (activeBtn === "visitor") setMarginLeft("-200%");
		if (activeBtn === "report") setMarginLeft("-300%");
	}, [activeBtn]);

	return (
		<div className={styles.AdsDetailPopupWrapper} onClick={() => setIsAdsDetailPopup(false)}>
			<div className={styles.AdsDetailPopup} onClick={(e) => e.stopPropagation()}>
				<div className={styles.Row1}>
					<div className={styles.Box}>
						<h1>Basic Ad</h1>

						<p>For companies that need to manage work happening across multiple teams.</p>

						<img src="https://picsum.photos/1500/500" alt="" />

						<h2 className={styles.Amount}>
							$300<span>/</span>
							<span>month</span>
						</h2>

						<div className={styles.Wrapper}>
							<h6>17 days Left</h6>
							<button>Expire on 12 Nov 2023</button>
						</div>
					</div>
				</div>

				<div className={styles.Row2}>
					<button className={activeBtn === "ads" ? styles.Active : ""} onClick={() => setActiveBtn("ads")}>
						Ads Insights
					</button>
					<button className={activeBtn === "click" ? styles.Active : ""} onClick={() => setActiveBtn("click")}>
						Clickers
					</button>
					<button className={activeBtn === "visitor" ? styles.Active : ""} onClick={() => setActiveBtn("visitor")}>
						Visitors
					</button>
					<button className={activeBtn === "report" ? styles.Active : ""} onClick={() => setActiveBtn("report")}>
						Reports
					</button>
				</div>

				<div className={styles.Row3}>
					<div className={`${styles.Col} ${styles.Col1}`} style={{ marginLeft: marginLeft }}>
						<div className={styles.Top}>
							<div className={styles.RowCol}>
								<div className={styles.Left} style={{ background: "rgba(27, 71, 193, 0.15)" }}>
									<Curser />
								</div>

								<div className={styles.Right}>
									<p>This Month</p>
									<h2>60,000</h2>
									<p>Total Clicks</p>
								</div>
							</div>

							<div className={styles.RowCol}>
								<div className={styles.Left} style={{ background: "rgba(127, 74, 230, 0.20)" }}>
									<People />
								</div>

								<div className={styles.Right}>
									<p>This Month</p>
									<h2>60,000</h2>
									<p>Total Visitors</p>
								</div>
							</div>

							<div className={styles.RowCol}>
								<div className={styles.Left} style={{ background: "rgba(50, 212, 105, 0.20)" }}>
									<ChartIcon />
								</div>

								<div className={styles.Right}>
									<p>This Month</p>
									<h2>60,000</h2>
									<p>Total Revenue</p>
								</div>
							</div>

							<div className={styles.RowCol}>
								<div className={styles.Left} style={{ background: "rgba(255, 128, 128, 0.17)" }}>
									<Report />
								</div>

								<div className={styles.Right}>
									<p>This Month</p>
									<h2>60,000</h2>
									<p>Total Report</p>
								</div>
							</div>
						</div>

						<div className={styles.Bottom}>
							<h5>Today live Reviews</h5>
							<Chart
								options={{
									yaxis: {
										labels: {
											formatter: function (value) {
												return `${Math.floor(value / 3600)}h ${Math.floor((value % 3600) / 60)}m`;
											},
											style: {
												colors: "#CBD5E0",
											},
										},
									},
									xaxis: {
										categories: [1, 2, 3],
										tickPlacement: "between",
										labels: {
											formatter: function (value) {
												const year = Math.floor(value / 100);
												const month = value % 100;
												const dateObject = new Date(year, month - 1, 1);
												const formattedDate = dateObject.toLocaleString("en-US", {
													month: "short",
													year: "2-digit",
												});
												return formattedDate;
											},
											style: {
												colors: "#CBD5E0",
											},
										},
									},
									chart: {
										toolbar: {
											show: false,
										},
									},
									legend: {
										show: true,
										labels: {
											colors: "#fff",
										},
									},
									stroke: {
										show: true,
										curve: "smooth",
										lineCap: "butt",
										colors: undefined,
										width: 3,
										dashArray: 0,
									},
									dataLabels: {
										enabled: false,
									},
									grid: {
										borderColor: "#56577A",
										strokeDashArray: 4,
									},
								}}
								series={[
									{
										type: "line",
										name: "Month",
										color: "#0075FF",
										data: [3, 2, 5, 1, 6, 2, 8],
									}
								]}
								width="100%"
								height="100%"
							/>
						</div>
					</div>

					<div className={`${styles.Col} ${styles.Col2}`}>
						{Array(10)
							.fill("")
							.map((data, index) => (
								<div key={index} className={styles.Box}>
									<div className={styles.Wrapper}>
										<img src="https://picsum.photos/500/500" alt="" />

										<div className={styles.Text}>
											<h3>Rohan Mondal</h3>
											<p>@Rohan_Mondal</p>
										</div>
									</div>

									<button>View Profile</button>
								</div>
							))}
					</div>

					<div className={`${styles.Col} ${styles.Col3}`}>
						{Array(10)
							.fill("")
							.map((data, index) => (
								<div key={index} className={styles.Box}>
									<div className={styles.Wrapper}>
										<img src="https://picsum.photos/500/500" alt="" />

										<div className={styles.Text}>
											<h3>Rohan Mondal</h3>
											<p>@Rohan_Mondal</p>
										</div>
									</div>

									<button>View Profile</button>
								</div>
							))}
					</div>

					<div className={`${styles.Col} ${styles.Col4}`}>
						{Array(10)
							.fill("")
							.map((data, index) => (
								<div key={index} className={styles.Box}>
									<div className={styles.Wrapper}>
										<img src="https://picsum.photos/500/500" alt="" />

										<div className={styles.Text}>
											<h3>Rohan Mondal</h3>
											<p>@Rohan_Mondal</p>
										</div>
									</div>

									<button>View Profile</button>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdsDetailPopup;
