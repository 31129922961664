import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "../../../components/Hooks/axios";
import styles from "./HasTags.module.scss";
import { useDispatch } from "react-redux";
import { setUserObject } from "../../../redux/slices/authSlice";

const HasTags = ({ hasTags }) => {
    const dispatch = useDispatch();

	const [tag, setTag] = useState("");
	const [loading, setLoading] = useState(false);

	const handelTagAdd = () => {
		if (tag === "") return toast.error("Please add any tag !");

		setLoading(true);
        const tags =[tag];

		const raw = {
			tags: JSON.stringify(tags),
        };
		axios
			.put(`/auth/addHasTags`, raw)
			.then(({ data }) => {
                console.log(data);
                setLoading(false);
                setTag("");
                toast.success("Tag added successfully !")
                dispatch(setUserObject(data.data));
			})
			.catch(({ response }) => {
				console.log("Error => ", response);
                setLoading(false);
			});
	};

    const handelRemoveTag = (index) => {
        const id =toast.loading("Please wait...")
        axios
			.put(`/auth/removeHasTags`, {
				tagIndex: index,
			})
            .then(({ data }) => {
                toast.update(id, {
					render: "One tag remove successfully !",
					type: "success",
					isLoading: false,
					autoClose: 2000,
				});
				dispatch(setUserObject(data.data));
			})
			.catch(({ response }) => {
				console.log("Error => ", response);
				setLoading(false);
			});
    };

	return (
		<div className={styles.HasTags}>
			<div className={styles.Left}>
				{hasTags.length === 0 ? (
					<h2>No Tags</h2>
				) : (
					hasTags.map((data, index) => (
						<div key={index} className={styles.Tag}>
							<p>{data}</p>
							<button onClick={() => handelRemoveTag(index)}>X</button>
						</div>
					))
				)}
			</div>

			<div className={styles.Right}>
				<p>You add Maximum 10 hastages</p>

				<div className={styles.Wrapper}>
					<input type="text" placeholder="#Type Hastage" value={tag} onChange={(e) => setTag(e.target.value)} />
					<button onClick={handelTagAdd} disabled={loading}>
						{loading ? "Loading..." : "ADD"}
					</button>
				</div>
			</div>
		</div>
	);
};

export default HasTags;
