import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import I0 from "../../../../assets/images/I0.png";
import I1 from "../../../../assets/images/I1.png";
import I2 from "../../../../assets/images/I2.png";
import I3 from "../../../../assets/images/I3.png";
import I4 from "../../../../assets/images/I4.png";
import I5 from "../../../../assets/images/I5.png";
import I6 from "../../../../assets/images/I6.png";
import I7 from "../../../../assets/images/I7.png";
import I8 from "../../../../assets/images/I8.png";
import Logo from "../../../../assets/svg/Logo.svg";
import axios from "../../../../components/Hooks/axios";
import styles from "./Interest.module.scss";

const Interest = () => {
	const navigate = useNavigate();
	const { userId } = useSelector((state) => state.auth);
	const [interest, setInterest] = useState([]);

	const choseInterest = (data) => {
		const result = interest.find((item) => item === data);
		if (result === data) {
			setInterest(interest.filter((item) => item !== data));
		} else {
			setInterest((pre) => [...pre, data]);
		}
	};

	const handelNext = () => {
		if (interest.length === 0) return toast.warn("Please chose one item");

		const data = JSON.stringify({
			_id: userId,
			interestName: interest,
		});

		axios
			.post("/auth/addInterest", data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(({ data }) => {
				axios
					.get(`/auth/profile/get-unique-name/${userId}`, {
						headers: {
							"Content-Type": "application/json",
						},
					})
					.then(({ data }) => {
						navigate("/get-started", { replace: true, state: data.GetstatedName });
					})
					.catch(({ response }) => {
						console.log(response.data);
					});
			})
			.catch(({ response }) => {
				console.log(response);
			});
	};

	return (
		<div className={styles.InterestContainer}>
			<div className={styles.Container1}>
				<img src={Logo} alt="logo" />
			</div>
			<div className={styles.Container2}>
				<h1>Choose Your Interest</h1>
				<p>Please select at least 3 interest, later you can add more </p>
			</div>

			<div className={styles.Container3}>
				<div
					className={`${styles.Box} ${interest.find((item) => item === "sports") === "sports" && styles.active}`}
					onClick={() => choseInterest("sports")}>
					<div className={styles.InnerBox}>
						<img className={styles.Check} src={I0} alt="I0" />
						<img src={I1} alt="I1" />
						<h2>Sports</h2>
					</div>
				</div>

				<div
					className={`${styles.Box} ${interest.find((item) => item === "health") === "health" && styles.active}`}
					onClick={() => choseInterest("health")}>
					<div className={styles.InnerBox}>
						<img className={styles.Check} src={I0} alt="I0" />
						<img src={I2} alt="I2" />
						<h2>Health</h2>
					</div>
				</div>

				<div
					className={`${styles.Box} ${interest.find((item) => item === "fitness") === "fitness" && styles.active}`}
					onClick={() => choseInterest("fitness")}>
					<div className={styles.InnerBox}>
						<img className={styles.Check} src={I0} alt="I0" />
						<img src={I3} alt="I3" />
						<h2>Fitness</h2>
					</div>
				</div>

				<div
					className={`${styles.Box} ${interest.find((item) => item === "dance") === "dance" && styles.active}`}
					onClick={() => choseInterest("dance")}>
					<div className={styles.InnerBox}>
						<img className={styles.Check} src={I0} alt="I0" />
						<img src={I4} alt="I4" />
						<h2>Dance</h2>
					</div>
				</div>

				<div
					className={`${styles.Box} ${interest.find((item) => item === "food") === "food" && styles.active}`}
					onClick={() => choseInterest("food")}>
					<div className={styles.InnerBox}>
						<img className={styles.Check} src={I0} alt="I0" />
						<img src={I5} alt="I5" />
						<h2>Food</h2>
					</div>
				</div>

				<div
					className={`${styles.Box} ${interest.find((item) => item === "cars") === "cars" && styles.active}`}
					onClick={() => choseInterest("cars")}>
					<div className={styles.InnerBox}>
						<img className={styles.Check} src={I0} alt="I0" />
						<img src={I6} alt="I6" />
						<h2>Cars</h2>
					</div>
				</div>

				<div
					className={`${styles.Box} ${interest.find((item) => item === "clothing") === "clothing" && styles.active}`}
					onClick={() => choseInterest("clothing")}>
					<div className={styles.InnerBox}>
						<img className={styles.Check} src={I0} alt="I0" />
						<img src={I7} alt="I7" />
						<h2>Clothing</h2>
					</div>
				</div>

				<div
					className={`${styles.Box} ${interest.find((item) => item === "fashion") === "fashion" && styles.active}`}
					onClick={() => choseInterest("fashion")}>
					<div className={styles.InnerBox}>
						<img className={styles.Check} src={I0} alt="I0" />
						<img src={I8} alt="I8" />
						<h2>Fashion</h2>
					</div>
				</div>
			</div>

			<div className={styles.Container4}>
				<button onClick={handelNext}>Next</button>
			</div>
		</div>
	);
};

export default Interest;
