import { useEffect } from "react";
import classes from "./SecurityItem2.module.scss";

const SecurityItem2 = ({ heading, desc, content, setUpdateShow, action, setActiveUpdateData, keyItem }) => {
	useEffect(() => {}, []);

	return (
		<div className={classes["info-container"]}>
			<div className={classes.subject}>
				<p className={classes.heading}>{heading}</p>

				<p className={classes.content}>{content?.data ==="" ? "Please add" :content?.data}</p>
			</div>

			<div className={classes.Right}>
				<button
					className={classes.action}
					style={{ cursor: "pointer" }}
					onClick={() => {
						setActiveUpdateData({ heading, desc, content,keyItem });
						setUpdateShow(true);
					}}>
					Update
				</button>

				<div className={classes.switch} style={{ cursor: "pointer" }}>
					{action}
				</div>
			</div>
		</div>
	);
};

export default SecurityItem2;
