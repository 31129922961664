import React, { useRef, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { RiSearchLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { toast } from "react-toastify";
import arrow from "../../assets/svg/arrowRightNew.svg";
import axios from "../../components/Hooks/axios";
import { setCustomPollKey, setCustomPollShow, setPollEnable, setPostReload } from "../../redux/slices/tempSlice";
import styles from "./PostWrapper.module.scss";

const UploadSection = ({
	setPostPopup,
	setUploadSection,
	setSelectedImageShow,
	imagesArray,
	setImagesArray,
	text,
	setTextBox,
	setCropContShow,
}) => {
	const dispatch = useDispatch();
	const { userId, user } = useSelector((s) => s.auth);
	const { pollObj } = useSelector((s) => s.temp);
	const [btnDisable, setBtnDisable] = useState(false);
	const [uploadPer, setUploadPer] = useState(0);
	const [slidePosition, setSlidePosition] = useState(0);

	// const [enablePoll, setEnablePoll] = useState(false);
	const [customPoll, setCustomPoll] = useState(false);
	const [showPollResult, setShowPollResult] = useState(false);
	const [setTimer, setSetTimer] = useState("1");
	const currentDate = new Date();
	const [setTimerValue, setSetTimerValue] = useState(new Date(currentDate.getTime() + 24 * 60 * 60 * 1000));
	const [caption, setCaption] = useState("");
	const [tag, setTag] = useState([]);
	const textRef = useRef();
	const [likeViewShow, setLikeViewShow] = useState(false);
	const [likeView, setLikeView] = useState({ everyone: true, followers: false, no_one: false });
	const [allowCommentShow, setAllowCommentShow] = useState(false);
	const [allowComment, setAllowComment] = useState({ everyone: true, followers: false, no_one: false });
	const [postSharingShow, setPostSharingShow] = useState(false);
	const [postSharing, setPostSharing] = useState({ everyone: true, followers: false, no_one: false });
	const [hideLikeViewShow, setHideLikeViewShow] = useState(false);
	const containerRef = useRef(null);

	const scrollDivToBottom = () => {
		const div = containerRef.current;
		div.scrollTop = div.scrollHeight;
	};

	const handelUploadImage = () => {
		if (imagesArray.length === 0) return toast.warn("Please add a image/video !!");
		setBtnDisable(true);
		const id = toast.loading("Post Uploading...");

		const formData = new FormData();

		formData.append("userId", userId);
		imagesArray.forEach((element) => {
			const mediaType = formData.has("mediaType");
			if (!mediaType) {
				if (element.type === "video/mp4") formData.append("mediaType", "video");
				else formData.append("mediaType", "image");
			}
			formData.append("mediaUrl", element);
		});
		formData.append("Enabledpoll", pollObj.enablePoll);
		formData.append("customPollEnabled", pollObj.enableCustomPoll);
		formData.append("customPollData", JSON.stringify(pollObj.pollKeys));
		formData.append("ShowPollResults", showPollResult);
		formData.append("pollDuration", setTimerValue);
		formData.append("caption", caption);
		formData.append("privacy", JSON.stringify(likeView));
		formData.append("tag_privacy", JSON.stringify(allowComment));
		formData.append("tags", JSON.stringify(tag));
		formData.append("post_hide", "[]"); //NOTE:
		formData.append("tagPeople", "[]"); //NOTE:

		axios
			.post("/post/", formData, {
				onUploadProgress: (progressEvent) => {
					const progress = (progressEvent.loaded / progressEvent.total) * 100;
					setUploadPer(progress);
				},
			})
			.then(({ data }) => {
				toast.update(id, { render: data.message, type: "success", isLoading: false, autoClose: 2000 });
				setPostPopup(false);
				setImagesArray([]);
				setBtnDisable(false);
				setUploadPer(0);
				dispatch(setPollEnable(false));
				dispatch(setCustomPollKey([]));
				dispatch(setCustomPollShow({ enableCustomPoll: false, customPollPopupShow: false }));
				dispatch(setPostReload(true));
			})
			.catch((e) => {
				console.log(e);
				setBtnDisable(false);
				setUploadPer(0);
			});
	};

	return (
		<div className={styles.UploadSectionOuter}>
			<div className={styles.UploadSectionInner} ref={containerRef}>
				<div className={styles.ContentWrapper}>
					<img
						src={arrow}
						alt=""
						className={styles.PopupCloseBtn}
						onClick={() => {
							if (text) {
								setTextBox(true);
							}
							if (imagesArray[0]?.type === "video/mp4") {
								setUploadSection(false);
								setSelectedImageShow(false);
							} else if (imagesArray?.length > 1) {
								setSelectedImageShow(true);
							}
							setUploadSection(false);
						}}
					/>

					<div className={styles.ImageWrapper}>
						<button
							disabled={slidePosition <= 0}
							className={`${styles.ArrowBtn} ${styles.PreBtn}`}
							onClick={() => {
								setSlidePosition(slidePosition - 30);
							}}>
							<FaArrowLeft />
						</button>

						<button
							disabled={slidePosition >= imagesArray.length * 30 - 30}
							className={`${styles.ArrowBtn} ${styles.NextBtn}`}
							onClick={() => {
								setSlidePosition(slidePosition + 30);
							}}>
							<FaArrowRight />
						</button>

						<div className={styles.ImagesContainerOuter}>
							<div
								className={styles.ImagesContainer}
								style={{
									width: `${imagesArray.length > 0 ? imagesArray.length * 30 : 30}rem`,
									transform: `translateX(-${slidePosition}rem)`,
								}}>
								{imagesArray.map((data, index) => {
									if (data?.type === "video/mp4")
										return <video key={index} controls src={URL.createObjectURL(data)}></video>;
									return <img key={index} src={URL.createObjectURL(data)} alt="" />;
								})}
							</div>
						</div>

						<button
							className={styles.EditBtn}
							onClick={() => {
								if (text) setTextBox(true);
								else setCropContShow(true);
								setUploadSection(false);
							}}>
							Edit Post
						</button>
					</div>

					{pollObj.enablePoll && (
						<>
							<div className={styles.RowType1}>
								<div className={styles.Row1}>
									<div className={styles.Left}>
										<div className={styles.BtnWrapper}>
											<button>A. Yes</button>
											<button>B. No</button>
										</div>
									</div>
									{/* <div className={styles.Right}>
								<p>Enable Poll</p>
								<Switch
									onChange={(e) => setEnablePoll(e)}
									checked={enablePoll}
									offColor={"#c4c4c4"}
									onColor={"#909AFF"}
									offHandleColor={"#434343"}
									onHandleColor={"#2E5EE2"}
									uncheckedIcon={false}
									checkedIcon={false}
									activeBoxShadow={"0 0 0px 0px #909AFF"}
									height={30}
									width={64}
								/>
							</div> */}
								</div>

								<div className={styles.Row1}>
									<div className={styles.Left}>
										<p>Custom Poll</p>
									</div>
									<div className={styles.Right}>
										<Switch
											onChange={(e) => {
												if (e)
													dispatch(setCustomPollShow({ enableCustomPoll: e, customPollPopupShow: e }));
												else {
													dispatch(setCustomPollKey([]));
													dispatch(setCustomPollShow({ enableCustomPoll: e, customPollPopupShow: e }));
												}
												setCustomPoll(e);
											}}
											checked={pollObj.enableCustomPoll}
											offColor={"#c4c4c4"}
											onColor={"#909AFF"}
											offHandleColor={"#434343"}
											onHandleColor={"#2E5EE2"}
											uncheckedIcon={false}
											checkedIcon={false}
											activeBoxShadow={"0 0 0px 0px #909AFF"}
											height={30}
											width={64}
										/>
									</div>
								</div>

								<div className={styles.Row1}>
									<div className={styles.Left}>
										<p>Show results to audience</p>
									</div>

									<div className={styles.Right}>
										<Switch
											onChange={(e) => setShowPollResult(e)}
											checked={showPollResult}
											offColor={"#c4c4c4"}
											onColor={"#909AFF"}
											offHandleColor={"#434343"}
											onHandleColor={"#2E5EE2"}
											uncheckedIcon={false}
											checkedIcon={false}
											activeBoxShadow={"0 0 0px 0px #909AFF"}
											height={30}
											width={64}
										/>
									</div>
								</div>
							</div>

							<div className={styles.RowType2}>
								<p>Set Timer</p>
								<div className={styles.Container}>
									<div className={styles.Left}>
										<button
											className={setTimer === "1" ? styles.active : ""}
											onClick={() => {
												setSetTimerValue(new Date(currentDate.getTime() + 24 * 60 * 60 * 1000));
												setSetTimer("1");
											}}>
											1D
										</button>
										<button
											className={setTimer === "2" ? styles.active : ""}
											onClick={() => {
												setSetTimerValue(new Date(currentDate.getTime() + 2 * 24 * 60 * 60 * 1000));
												setSetTimer("2");
											}}>
											2D
										</button>
										<button
											className={setTimer === "7" ? styles.active : ""}
											onClick={() => {
												setSetTimerValue(new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000));
												setSetTimer("7");
											}}>
											7D
										</button>
									</div>

									<div className={styles.Right}>
										<p>Set Custom</p>
										<input
											type="date"
											onChange={(e) => {
												const specificDate = new Date(e.target.value);
												const timeDifference = specificDate - currentDate;
												const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
												if (daysDifference <= 0) {
													setSetTimerValue(new Date(currentDate.getTime() + 24 * 60 * 60 * 1000));
													setSetTimer("1");
												} else {
													setSetTimerValue(e.target.value);
													setSetTimer(daysDifference.toString());
												}
											}}
										/>
									</div>
								</div>
							</div>
						</>
					)}

					<div className={styles.RowType3}>
						<p>Describe your feeling</p>

						<div className={styles.TextAreaContainer}>
							<textarea
								ref={textRef}
								defaultValue={caption}
								onChange={(e) => {
									if (e.target.value.split(" ").length <= 32) setCaption(e.target.value);
									else {
										toast.info("Limit Excited!!");
										textRef.current.blur();
									}
								}}
								className={styles.textarea1}></textarea>

							<div className={styles.row2}>
								<textarea
									defaultValue={tag}
									onChange={(e) => {
										// let value = e.target.value?.split("#").slice(1);
										let value = e.target.value
											?.split(/\s+#/)
											.filter(Boolean)
											.map((item) => item.replace("#", ""));
										setTag(value);
									}}
									className={styles.textarea2}></textarea>
								<span>
									{caption?.split(" ").length < 10
										? `0${caption?.split(" ").length}`
										: caption?.split(" ").length}
									/32
								</span>
							</div>
						</div>
					</div>

					<div className={styles.RowType4}>
						<p>Post Setting</p>

						<div className={styles.Container1}>
							<h1>Post</h1>

							<div className={styles.Row}>
								<div className={styles.Left}>
									<p>Likes & View</p>
								</div>

								<div
									className={styles.Right}
									onClick={() => {
										setLikeViewShow(true);
										setAllowCommentShow(false);
										setPostSharingShow(false);
										setHideLikeViewShow(false);
										scrollDivToBottom();
									}}>
									<span>
										{likeView.everyone && "Everyone"}
										{likeView.followers && "Followers"}
										{likeView.no_one && "No One"}
									</span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="29"
										height="29"
										viewBox="0 0 29 29"
										fill="none">
										<g clipPath="url(#clip0_1770_7816)">
											<path
												d="M15.7978 14.3808L10.1055 20.0732L11.7315 21.6992L19.0499 14.3808L11.7315 7.06243L10.1055 8.68848L15.7978 14.3808Z"
												fill="#959595"
											/>
										</g>
										<defs>
											<clipPath id="clip0_1770_7816">
												<rect
													width="27.5992"
													height="27.5992"
													fill="white"
													transform="matrix(1 0 0 -1 0.652344 28.1797)"
												/>
											</clipPath>
										</defs>
									</svg>
								</div>
							</div>

							<div className={styles.Row}>
								<div className={styles.Left}>
									<p>Hide like & views control</p>
									<span>Manage your likes and view on your post</span>
								</div>

								<div
									className={styles.Right}
									onClick={() => {
										setHideLikeViewShow(true);
										setPostSharingShow(false);
										setAllowCommentShow(false);
										setLikeViewShow(false);
										scrollDivToBottom();
									}}>
									<span>0 People</span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="29"
										height="29"
										viewBox="0 0 29 29"
										fill="none">
										<g clipPath="url(#clip0_1770_7816)">
											<path
												d="M15.7978 14.3808L10.1055 20.0732L11.7315 21.6992L19.0499 14.3808L11.7315 7.06243L10.1055 8.68848L15.7978 14.3808Z"
												fill="#959595"
											/>
										</g>
										<defs>
											<clipPath id="clip0_1770_7816">
												<rect
													width="27.5992"
													height="27.5992"
													fill="white"
													transform="matrix(1 0 0 -1 0.652344 28.1797)"
												/>
											</clipPath>
										</defs>
									</svg>
								</div>
							</div>

							<div className={styles.Row}>
								<div className={styles.Left}>
									<p>Allow comments from</p>
								</div>

								<div
									className={styles.Right}
									onClick={() => {
										setAllowCommentShow(true);
										setPostSharingShow(false);
										setLikeViewShow(false);
										setHideLikeViewShow(false);
										scrollDivToBottom();
									}}>
									<span>
										{allowComment.everyone && "Everyone"}
										{allowComment.followers && "Followers"}
										{allowComment.no_one && "No One"}
									</span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="29"
										height="29"
										viewBox="0 0 29 29"
										fill="none">
										<g clipPath="url(#clip0_1770_7816)">
											<path
												d="M15.7978 14.3808L10.1055 20.0732L11.7315 21.6992L19.0499 14.3808L11.7315 7.06243L10.1055 8.68848L15.7978 14.3808Z"
												fill="#959595"
											/>
										</g>
										<defs>
											<clipPath id="clip0_1770_7816">
												<rect
													width="27.5992"
													height="27.5992"
													fill="white"
													transform="matrix(1 0 0 -1 0.652344 28.1797)"
												/>
											</clipPath>
										</defs>
									</svg>
								</div>
							</div>

							<div className={styles.Row}>
								<div className={styles.Left}>
									<p>Post Sharing</p>
								</div>

								<div
									className={styles.Right}
									onClick={() => {
										setPostSharingShow(true);
										setAllowCommentShow(false);
										setLikeViewShow(false);
										setHideLikeViewShow(false);
										scrollDivToBottom();
									}}>
									<span>
										{postSharing.everyone && "Everyone"}
										{postSharing.followers && "Followers"}
										{postSharing.no_one && "No One"}
									</span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="29"
										height="29"
										viewBox="0 0 29 29"
										fill="none">
										<g clipPath="url(#clip0_1770_7816)">
											<path
												d="M15.7978 14.3808L10.1055 20.0732L11.7315 21.6992L19.0499 14.3808L11.7315 7.06243L10.1055 8.68848L15.7978 14.3808Z"
												fill="#959595"
											/>
										</g>
										<defs>
											<clipPath id="clip0_1770_7816">
												<rect
													width="27.5992"
													height="27.5992"
													fill="white"
													transform="matrix(1 0 0 -1 0.652344 28.1797)"
												/>
											</clipPath>
										</defs>
									</svg>
								</div>
							</div>
						</div>

						{likeViewShow && (
							<div className={styles.Container2}>
								<h6>Allow Like And View from</h6>

								<div className={styles.Row}>
									<div>
										<p>Everyone</p>
									</div>
									<Switch
										onChange={(e) => setLikeView({ followers: false, no_one: false, everyone: e })}
										checked={likeView.everyone}
										offColor={"#eeeeee"}
										onColor={"#eeeeee"}
										offHandleColor={"#959595"}
										onHandleColor={"#3a0dbb"}
										uncheckedIcon={false}
										checkedIcon={false}
										activeBoxShadow={"0 0 0px 0px #eeeeee"}
										height={20}
										width={50}
									/>
								</div>

								<div className={styles.Row}>
									<div>
										<p>People you follow</p>
										<span>{user.followingData.length} people</span>
									</div>
									<Switch
										onChange={(e) => setLikeView({ everyone: false, no_one: false, followers: e })}
										checked={likeView.followers}
										offColor={"#eeeeee"}
										onColor={"#eeeeee"}
										offHandleColor={"#959595"}
										onHandleColor={"#3a0dbb"}
										uncheckedIcon={false}
										checkedIcon={false}
										activeBoxShadow={"0 0 0px 0px #eeeeee"}
										height={20}
										width={50}
									/>
								</div>

								<div className={styles.Row}>
									<div>
										<p>No one</p>
									</div>
									<Switch
										onChange={(e) => setLikeView({ everyone: false, followers: false, no_one: e })}
										checked={likeView.no_one}
										offColor={"#eeeeee"}
										onColor={"#eeeeee"}
										offHandleColor={"#959595"}
										onHandleColor={"#3a0dbb"}
										uncheckedIcon={false}
										checkedIcon={false}
										activeBoxShadow={"0 0 0px 0px #eeeeee"}
										height={20}
										width={50}
									/>
								</div>
							</div>
						)}

						{allowCommentShow && (
							<div className={styles.Container2}>
								<h6>Allow comments from</h6>

								<div className={styles.Row}>
									<div>
										<p>Everyone</p>
									</div>
									<Switch
										onChange={(e) => {
											if (e) setAllowComment({ followers: false, no_one: false, everyone: e });
										}}
										checked={allowComment.everyone}
										offColor={"#eeeeee"}
										onColor={"#eeeeee"}
										offHandleColor={"#959595"}
										onHandleColor={"#3a0dbb"}
										uncheckedIcon={false}
										checkedIcon={false}
										activeBoxShadow={"0 0 0px 0px #eeeeee"}
										height={20}
										width={50}
									/>
								</div>

								<div className={styles.Row}>
									<div>
										<p>People you follow</p>
										<span>{user.followingData.length} people</span>
									</div>
									<Switch
										onChange={(e) => {
											if (e) setAllowComment({ everyone: false, no_one: false, followers: e });
										}}
										checked={allowComment.followers}
										offColor={"#eeeeee"}
										onColor={"#eeeeee"}
										offHandleColor={"#959595"}
										onHandleColor={"#3a0dbb"}
										uncheckedIcon={false}
										checkedIcon={false}
										activeBoxShadow={"0 0 0px 0px #eeeeee"}
										height={20}
										width={50}
									/>
								</div>

								<div className={styles.Row}>
									<div>
										<p>No one</p>
									</div>
									<Switch
										onChange={(e) => {
											if (e) setAllowComment({ everyone: false, followers: false, no_one: e });
										}}
										checked={allowComment.no_one}
										offColor={"#eeeeee"}
										onColor={"#eeeeee"}
										offHandleColor={"#959595"}
										onHandleColor={"#3a0dbb"}
										uncheckedIcon={false}
										checkedIcon={false}
										activeBoxShadow={"0 0 0px 0px #eeeeee"}
										height={20}
										width={50}
									/>
								</div>
							</div>
						)}

						{postSharingShow && (
							<div className={styles.Container2}>
								<h6>Post Sharing</h6>

								<div className={styles.Row}>
									<div>
										<p>Everyone</p>
									</div>
									<Switch
										onChange={(e) => {
											if (e) setPostSharing({ followers: false, no_one: false, everyone: e });
										}}
										checked={postSharing.everyone}
										offColor={"#eeeeee"}
										onColor={"#eeeeee"}
										offHandleColor={"#959595"}
										onHandleColor={"#3a0dbb"}
										uncheckedIcon={false}
										checkedIcon={false}
										activeBoxShadow={"0 0 0px 0px #eeeeee"}
										height={20}
										width={50}
									/>
								</div>

								<div className={styles.Row}>
									<div>
										<p>People you follow</p>
										<span>{user.followingData.length} people</span>
									</div>
									<Switch
										onChange={(e) => {
											if (e) setPostSharing({ everyone: false, no_one: false, followers: e });
										}}
										checked={postSharing.followers}
										offColor={"#eeeeee"}
										onColor={"#eeeeee"}
										offHandleColor={"#959595"}
										onHandleColor={"#3a0dbb"}
										uncheckedIcon={false}
										checkedIcon={false}
										activeBoxShadow={"0 0 0px 0px #eeeeee"}
										height={20}
										width={50}
									/>
								</div>

								<div className={styles.Row}>
									<div>
										<p>No one</p>
									</div>
									<Switch
										onChange={(e) => {
											if (e) setPostSharing({ everyone: false, followers: false, no_one: e });
										}}
										checked={postSharing.no_one}
										offColor={"#eeeeee"}
										onColor={"#eeeeee"}
										offHandleColor={"#959595"}
										onHandleColor={"#3a0dbb"}
										uncheckedIcon={false}
										checkedIcon={false}
										activeBoxShadow={"0 0 0px 0px #eeeeee"}
										height={20}
										width={50}
									/>
								</div>
							</div>
						)}

						{hideLikeViewShow && (
							<div className={styles.Container3}>
								<span>Hide from</span>
								<div className={styles.SearchBox}>
									<RiSearchLine size={"2.5rem"} />
									<input type="search" />
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<button className={styles.UploadBtn} disabled={btnDisable} onClick={handelUploadImage}>
				Upload
				<span style={{ width: `${uploadPer}%` }}></span>
			</button>
		</div>
	);
};

export default UploadSection;
