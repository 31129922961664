import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../../../../components/Hooks/axios";
import Modal from "../../../../components/Modal/Modal";
import classes from "./Deactivate.module.css";
import { setUserObject } from "../../../../redux/slices/authSlice";

const Deactivate = ({ onClose }) => {
	const { userId } = useSelector((state) => state.auth);
	const dispatch = useDispatch()
	const [duration, setDuration] = useState("");
	const [reason, setReason] = useState("");
	const [message, setMessage] = useState("");
	const [btnDisable, setBtnDisable] = useState(false);

	const handelDeactivate = () => {
		if (duration === "") return toast.warn("Please select duration !");
		if (reason === "") return toast.warn("Please provide a reason !");
		setBtnDisable(true);
		
		const data = JSON.stringify({
			duration,
			reason,
			message,
		});
		
		axios
			.put(`/auth/deactivate-Account?userId=${userId}`, data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(({ data }) => {
				dispatch(setUserObject(data.user));
				toast.info(data.message)
				onClose(false);
				setBtnDisable(false);
			})
			.catch((e) => {
				setBtnDisable(false);
				console.log(e);
			});

	};

	return (
		<Modal onClose={onClose}>
			<div className={classes.overlay} onClick={(e) => e.stopPropagation()}>
				<div className={classes.form}>
					<div className={classes.text}>
						<h2>Deactivate Account</h2>
						<p>I like to deactivate account </p>
					</div>
					<div className={classes.duration}>
						<div
							onClick={() => setDuration("3_months")}
							className={duration === "3_months" ? classes["input-active"] : classes.input}>
							3 Months
						</div>
						<div
							onClick={() => setDuration("6_months")}
							className={duration === "6_months" ? classes["input-active"] : classes.input}>
							6 Months
						</div>
						<div
							onClick={() => setDuration("permanent")}
							className={duration === "permanent" ? classes["input-active"] : classes.input}>
							Permanent
						</div>
					</div>
					<div className={classes.info}>
						<label htmlFor="city">Reason to deactivate</label>
						<input
							type="option"
							placeholder="Select your reason"
							value={reason}
							onChange={(e) => setReason(e.target.value)}
						/>
					</div>
					<div className={classes.info}>
						<label htmlFor="state">
							Message<span> ( Optional )</span>
						</label>
						<input
							type="option"
							placeholder="Select your message"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
					</div>
					<div className={classes.actions}>
						<button onClick={handelDeactivate} className={classes.btn} disabled={btnDisable}>
							Deactivate account
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default Deactivate;
