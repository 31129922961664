import React, { useState } from "react";
import { useSelector } from "react-redux";
import { socket } from "../../App";
import axios from "../Hooks/axios";
import styles from "./Suggestion.module.scss";

const UserBox = ({ data }) => {
	const { user, userId } = useSelector((s) => s.auth);
	const [follow, setFollow] = useState("Follow");

	const handelFollow = () => {
		let sd = {
			notification_from: userId,
			notification_to: data._id,
			notification: {},
			title: "Follow",
			message: `${user.username} started following you`,
		};

		const raw = { following: userId };

		axios
			.post(`/auth/follow`, raw)
			.then(({ data }) => {
				console.log(data);
				if (data.message === "follow") {
					setFollow("Following");
					socket.emit("notification", sd);
				}
				if (data.message === "unfollow") setFollow("Follow");
			})
			.catch((e) => {
				console.log(e);
			});
	};
	return (
		<div className={styles.BoxWrapperMain} onDragStart={(e) => e.preventDefault()}>
			<div className={styles.BoxWrapper}>
				<div className={styles.Top}>
					<img src={data?.personaThumbnail} alt="" />
				</div>

				<div className={styles.Bottom}>
					<div className={styles.TopSection}>
						<div className={styles.ImageWrapper}>
							<img src={data?.ProfilePic} alt="" />
						</div>

						<div className={styles.TestWrapper}>
							<h2>{data?.username?.length > 10 ? data?.username?.substring(0, 10) + "..." : data?.username}</h2>
							<p>
								@
								{data?.GetstatedName?.length > 16
									? data?.GetstatedName?.substring(0, 16) + "..."
									: data?.GetstatedName}
							</p>
						</div>
					</div>

					<button className={styles.Button} onClick={handelFollow}>
						{follow}
					</button>
				</div>
			</div>
		</div>
	);
};

export default UserBox;
